import { useEffect, useState } from 'react';
import { Typography, Stack } from '@mui/material';
import Loader from 'src/components/Loader';

const ALERT_MESSAGES = [
  'Chargement des alertes en cours...',
  "Saviez-vous ? Notre système d'alerte utilise des technologies de pointe pour vous informer en temps réel.",
  'Merci de patienter, nous récupérons les informations les plus récentes pour vous.',
  'Un instant, nous peaufinons les détails pour vous apporter les alertes les plus précises.',
];

const AlertLoading = () => {
  const randomIndex = Math.floor(Math.random() * ALERT_MESSAGES.length);
  const [messageIndex, setMessageIndex] = useState(randomIndex);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % ALERT_MESSAGES.length);
    }, 1250);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Stack justifyContent="center" alignItems="center">
      <Loader loading />
      <Typography variant="body2" textAlign="center">
        {ALERT_MESSAGES[messageIndex]}
      </Typography>
    </Stack>
  );
};

export default AlertLoading;
