import {
    Card,
    CardHeader,
    CardContent,
    Divider,
} from "@mui/material";
import React from "react";

/**
 * A tab-like container similar to Mui's <Card> element
 * 
 * @param {Object} props - Component props
 * @param {string} [props.header=""] - Header for card (Optional, but may look weird if not used)
 * @param {React.ReactNode} props.children - Child JSX Element 
 * @returns {React.ReactNode} The rendered component
 * 
 * @example
 * <TabContainer header="Select Product Type">
 *     <Product1 />
 *     <Product2 />
 * </TabContainer>
 */
export default function TabContainer({ header="", children }) {
    return (
        <Card fullWidth sx={{ padding: 0, position: "relative" }}>
            {/* Header Section */}
            <CardHeader
                title={header}
                sx={{ px: 3, py: 2, bgcolor: (theme) => theme.palette.grey[200] }} // Optional background color for the "tab" effect
            />

            {/* Divider to separate the header from the content */}
            <Divider />

            {/* Content Section */}
            <CardContent sx={{ padding: 3 }}>
                {children}
            </CardContent>
        </Card>
    );
}