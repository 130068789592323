import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import TagsForm from './TagsForm';

const Tags = ({ alertId, storeId, tags }) => (
  <Card sx={{ p: 2 }}>
    <Stack mb={2}>
      <Typography>Tags</Typography>
      <Typography variant="caption">Pensez à ajouter des tags pour créer des albums.</Typography>
    </Stack>
    <TagsForm alertId={alertId} storeId={storeId} tags={tags} />
  </Card>
);

export default Tags;
