import { useState, useEffect } from "react";
import { useTheme, useThemeProps } from '@mui/material/styles';
import {
    Box,
    Container,
    Tabs,
    Tab,
    TextField,
    InputAdornment,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Stack,
    Tooltip,
} from "@mui/material";
import Page from "src/components/Page";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Iconify from "src/components/Iconify";
import { useNavigate } from 'react-router-dom';

// Steps
import { ConnectionType, Details, License, Machine } from "./steps";

// App
export default function NewCamera() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({});

    useEffect(() => {
        console.log("Form data changed, new value =", formData);
    }, [formData]);

    return (
        <Page title="New Camera">
            <Container maxWidth={false}>

                <HeaderBreadcrumbs heading="Connect a Camera" links={[{ name: `Create & connect a new camera` }]} />

                {/* Form Steps */}
                <form>
                    <Stack spacing={3}>
                        <Details form={{ data: formData, setData: setFormData }} />

                        <Divider />

                        <ConnectionType form={{ data: formData, setData: setFormData }} />

                        <Divider />

                        <Machine form={{ data: formData, setData: setFormData }} />

                        <Divider />

                        <License form={{ data: formData, setData: setFormData }} />

                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                            <Divider />
                        </Box>

                        {/* Row 2 - Test Connection */}
                        <Stack display={"flex"} flexDirection="row" gap={1} justifyContent={{ xs: "center", sm: "end" }}>
                            <Button onClick={() => navigate(-1)}>
                                <Iconify icon="tabler:trash" color="red" width={20} height={20} />
                            </Button>

                            <Button
                                variant="contained"
                                startIcon={<Iconify icon="fluent:tab-add-20-filled" color="#fff" width={20} height={20} />}
                                sx={{ width: { xs: "100%", sm: "fit-content" } }}>
                                Add Camera
                            </Button>
                        </Stack>
                    </Stack>
                </form>

            </Container>
        </Page>
    );
}

function Divider() {
    const theme = useTheme();

    return (
        <Box display={"flex"} justifyContent={"center"} fullWidth>
            <Iconify icon="bxs:down-arrow" color={theme.palette.grey[300]} width={22} height={22} />
        </Box>
    );
}