import {
  Box,
  Card,
  Chip,
  ClickAwayListener,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  Button,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import useQueryParams from 'src/hooks/useQueryParams';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import Albums from './Albums';

const AlertSearchBar = ({ storeId, onSearchSubmit, searchFilterValue }) => {
  const [searchValue, setSearchValue] = useState(searchFilterValue);
  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
    if (!event.target.value) {
      onSearchSubmit('');
    }
  };

  const { filterLabel, filterName, changeParameters } = useQueryParams();
  const filterLabelIcon = {
    storeId: 'mdi:storefront',
    tagsTypeId: 'mdi:auto-awesome-motion',
    tagId: 'mdi:local-offer',
  }[filterName];

  const { isReady } = useAuth();

  const [albumOpen, setAlbumOpen] = useState(false);
  const handleClose = () => setAlbumOpen(false);
  const handleLeaveAlbum = () =>
    changeParameters(
      {
        filterName: undefined,
        filterValue: undefined,
        filterLabel: undefined,
      },
      { replace: true }
    );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        sx={{
          width: '100%',
          zIndex: 100,
          position: 'relative',
          overflow: 'visible',
          transition: (theme) => theme.transitions.create('transform'),
          ...(albumOpen && { boxShadow: (th) => th.shadows[6] }),
        }}
      >
        <Stack spacing={2}>
          <Card>
            <InputBase
              autoFocus
              sx={{ p: 1 }}
              fullWidth
              disabled={!isReady}
              value={searchValue}
              onChange={onSearchChange}
              placeholder="Rechercher par références, date"
              startAdornment={
                <InputAdornment position="start">
                  <Stack direction="row" spacing={0.5}>
                    <IconButton disabled={!isReady} size="small" onClick={() => setAlbumOpen(!albumOpen)}>
                      <Iconify icon="mdi:collections" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </IconButton>
                    <Divider flexItem orientation="vertical" />
                  </Stack>
                </InputAdornment>
              }
              endAdornment={(() => {
                if (filterLabel && filterName)
                  return (
                    <InputAdornment position="end">
                      <Chip
                        size="small"
                        deleteIcon="mdi:close"
                        onDelete={handleLeaveAlbum}
                        icon={<Iconify icon={filterLabelIcon} />}
                        label={filterLabel}
                      />
                    </InputAdornment>
                  );

                return (
                  <Button onClick={() => onSearchSubmit(searchValue)} variant="contained">
                    <Iconify icon="eva:search-fill" sx={{ width: 20, height: 20 }} />
                  </Button>
                );
              })()}
            />
          </Card>
        </Stack>
        {albumOpen && (
          <Card
            sx={{
              position: 'absolute',
              width: '100%',
              bottom: 'calc(0 - 100%)',
              left: 0,
              background: (th) => th.palette.background.paper,
              transition: (theme) => theme.transitions.create('transform'),
              ...(albumOpen && { boxShadow: (th) => th.shadows[6] }),
            }}
          >
            <Albums onClose={handleClose} storeId={storeId} />
          </Card>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default AlertSearchBar;
