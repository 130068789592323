// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '8px',
          textTransform: 'uppercase',
          color: theme.palette.common.white,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            opacity: 0.67,
          },
        },
        
        sizeLarge: {
          height: 48,
        },
        
        // contained
        containedInherit: {
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },

        // outlined
        outlined: {
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
        },

        text: {
          color: theme.palette.primary.main,
        },

        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          // border: `1px solid ${theme.palette.grey[500_32]}`,
          backgroundColor: theme.palette.chart.violet[0],
          
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },

        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
