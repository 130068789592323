import { gql } from '@apollo/client';

const CREATE_STORE = gql`
  mutation createStore($input: CreateStoreInput!) {
    createStore(input: $input) {
      id
      code
      name
      inactive
      hasValidation
      createdAt
    }
  }
`;

const UPDATE_STORE = gql`
  mutation updateStore($input: UpdateStoreInput!) {
    updateStore(input: $input) {
      id
      code
      name
      inactive
      hasValidation
      createdAt
    }
  }
`;

const DELETE_STORE = gql`
  mutation deleteStore($id: ID!) {
    deleteStore(id: $id)
  }
`;

const GENERATE_INVITATION_LINK = gql`
  mutation generateInvitationCode($storeId: ID!) {
    generateInvitationCode(storeId: $storeId) {
      id
      code
      invitationUrl
      name
      inactive
      createdAt
    }
  }
`;

export { CREATE_STORE, UPDATE_STORE, GENERATE_INVITATION_LINK, DELETE_STORE };
