import { CircularProgress, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import USER_ROLES from 'src/constants/userRoles';
import React from 'react';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import expectedErrors from 'src/graphql/common/expectedErrors';
import { useSafeQuery } from 'src/services/apollo-client/wrappers';
import { GET_ALERT } from 'src/graphql/alerts/queries';
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import Player from './Player';
import EvaluationButtons from './EvaluationButtons';
import Tags from './Tags';
import AlertInformation from './AlertInformation';
import ValidationAlertActions from './ValidationAlertActions';
import Recipients from './Recipients';

const DrawerContent = ({ alertId, onClose, refetchList }) => {
  const { data, loading } = useSafeQuery(
    GET_ALERT,
    {
      onCompleted: () => refetchList(),
      onError: onClose,
      fetchPolicy: 'network-only',
      variables: {
        alertId,
      },
    },
    {
      expectedErrors: [expectedErrors.ALERT_UNAVAILABLE],
      silentError: false,
    }
  );

  const alert = data?.getAlert;

  if (!alert || loading)
    return (
      <Stack p={4} alignItems="center">
        <CircularProgress />
      </Stack>
    );

  const { title, body, videoUrl, occurredAt, storeId, tags, id, falsePositive, externalId, validated, metadata } =
    alert;

  return (
    <>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack />
        <Stack direction="row" alignItems="center" spacing={1}>
          {!validated && <Label color="warning">À valider</Label>}
          <IconButton onClick={onClose}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
      </Toolbar>
      <Stack spacing={2} p={4}>
        <Player videoUrl={videoUrl} />
        {validated && (
          <Stack spacing={4}>
            <>
              <AlertInformation id={id} externalId={externalId} title={title} body={body} occurredAt={occurredAt} />
              <EvaluationButtons alertId={alertId} falsePositive={falsePositive} onClose={onClose} />
            </>
            <Tags alertId={alertId} storeId={storeId} tags={tags} />
            <RoleBasedGuard roles={[USER_ROLES.ADMIN]}>
              <Recipients metadata={metadata} />
            </RoleBasedGuard>
          </Stack>
        )}
        {!validated && (
          <Stack alignItems="center" spacing={3}>
            <Typography align="center" color="error.dark">
              Cette alerte n'a pas été envoyée, et attend une validation de votre part.
            </Typography>
            <ValidationAlertActions alertId={alertId} />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default DrawerContent;
