import { format, getTime } from 'date-fns';
import moment from 'moment';
import 'moment/locale/fr';

// ----------------------------------------------------------------------

export function fDate(date) {
  const formattedDate = moment(date).format('DD MMMM yyyy');
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

export function fMonthYear(date) {
  const formattedDate = moment(date).format('MMMM yyyy');
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTimeToUtc(date) {
  return moment(date).utc().format('lll');
}

export function fToNow(date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').fromNow();
}

export function fAlertDateTime(date) {
  const formattedDate = moment(date).format('ddd DD MMM • HH:mm:ss').replace(/\./g, '');
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

export function fAlertDate(date, withTime = true) {
  const format = withTime ? 'DD MMM YYYY, HH:mm' : 'dddd DD MMM YYYY';
  const formattedDate = moment(date).utc().format(format);
  return formattedDate.replace(':', 'h').replace(/\./g, '');
}

export function fAlertOnlyDate(date) {
  const formattedDate = moment(date).format('ddd DD MMM').replace(/\./g, '');
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

export function fAlertOnlyTime(date) {
  const formattedDate = moment(date).utc().format('HH:mm').replace(/\./g, '');
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}
