import {
    Box,
    Typography,
    ToggleButtonGroup,
    ToggleButton,
    FormControl,
    TextField,
    Stack,
    InputLabel,
    Button
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Iconify from "src/components/Iconify";

import CardToggleButton from "src/components/ftp/CardToggleButton";
import TabContainer from "../../../../../components/ftp/TabContainer";
import { formConfig } from "../formConfig";

// App
export default function ConnectionType({ form }) {
    const theme = useTheme();

    return (
        <TabContainer header="Connection Type">

            <ToggleButtonGroup
                sx={{ display: "flex", gap: 3, bgcolor: "transparent", border: "none" }}
                value={form.data?.[formConfig.connectionType.id]}
                exclusive
                onChange={(e) => form.setData((prev) => ({ ...prev, [formConfig.connectionType.id]: e.currentTarget.value }))}
                fullWidth
            >
                {formConfig.connectionType.list.map((type) => (
                    <CardToggleButton
                        key={type.id}
                        value={type.id}
                        icon={(
                            <Iconify icon={type.icon} color={form.data?.connectionType === type.id ? theme.palette.primary.main : theme.palette.grey[500]} width={26} height={26} />
                        )}
                        label={type.label}
                    />
                ))}
            </ToggleButtonGroup>

            {/* IP Address input field */}
            {form.data?.connectionType === "ip" && (
                <Stack direction="column" spacing={{ xs: 2, sm: 3 }} mt={3}>
                    {/* Row 1 - IP and Password */}
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 3 }}>
                        <FormControl fullWidth>
                            <TextField
                                label={formConfig.ip.label}
                                type="text"
                                placeholder={formConfig.ip.placeholder}
                                value={form.data?.[formConfig.ip.id]}
                                onChange={(e) => form.setData((prev) => ({ ...prev, [formConfig.ip.id]: e.target.value }))}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                label={formConfig.password.label}
                                type="password"
                                placeholder={formConfig.password.placeholder}
                                value={form.data?.[formConfig.password.id]}
                                onChange={(e) => form.setData((prev) => ({ ...prev, [formConfig.password.id]: e.target.value }))}
                            />
                        </FormControl>
                    </Stack>

                    {/* Row 2 - Test Connection */}
                    <Stack display={"flex"} alignItems={"end"}>
                        <Button
                            startIcon={<Iconify icon={"mdi:plug"} color={theme.palette.primary.main} width={20} height={20} />}
                            sx={{ width: "fit-content" }}>
                            Test Connection
                        </Button>
                    </Stack>
                </Stack>
            )}

        </TabContainer>
    );
}