import React, { useState } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  createFilterOptions,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Chip,
  Box,
} from '@mui/material';
import { ATTACH_TAGS } from '../../../../graphql/alerts/mutations';
import Loader from '../../../../components/Loader';
import { LIST_TAGS } from '../../../../graphql/tags/queries';
import { useSafeMutation, useSafeQuery } from '../../../../services/apollo-client/wrappers';
import Iconify from '../../../../components/Iconify';
import stopPropagation from '../../../../utils/stopPropagation';
import { DELETE_TAG } from '../../../../graphql/tags/mutations';
import useDialogs from '../../../../hooks/useDialogs';

const filter = createFilterOptions();

const ensureTagsFormat = (tags) => tags.map((tag) => (typeof tag === 'string' ? { label: tag } : tag));

const TagForm = ({ type, alertId, storeId, alertTags }) => {
  const { name, id } = type;
  const [value, setValue] = useState(alertTags);
  const { confirm } = useDialogs();

  const {
    data,
    loading,
    refetch: refetchTags,
  } = useSafeQuery(LIST_TAGS, {
    variables: {
      pagination: {
        page: 1,
        rowsPerPage: 1000,
      },
      storeId,
      tagsTypeId: id,
    },
  });

  const [attachTags] = useSafeMutation(
    ATTACH_TAGS,
    {
      onCompleted: ({ attachTags }) => {
        refetchTags();
        const newSelectedTags = attachTags.tags.filter(({ tagsTypeId }) => tagsTypeId === type.id);
        setValue(newSelectedTags);
      },
    },
    {
      silentError: false,
    }
  );

  const [deleteTag] = useSafeMutation(
    DELETE_TAG,
    {
      refetchQueries: ['listTags'],
    },
    {
      silentError: false,
    }
  );

  const tags = data?.listTags?.tags || [];

  const handleChange = async (e, tags) => {
    try {
      await attachTags({
        variables: {
          alertId,
          tagsTypeId: type.id,
          tags: ensureTagsFormat(tags),
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const handleDeleteTag = async (tagId) => {
    try {
      if (
        await confirm({
          message:
            'Si vous supprimez ce tag, il sera retiré de toutes les vidéos sur lesquelles il est actuellement. Continuer ?',
        })
      ) {
        await deleteTag({
          variables: {
            tagId,
          },
        });
      }
    } catch {
      // Nothing to do
    }
  };

  return (
    <>
      <Autocomplete
        size="small"
        multiple
        freeSolo
        limitTags={1}
        value={value}
        disableCloseOnSelect
        selectOnFocus
        isOptionEqualToValue={({ id: optionId }, { id: valueId }) => optionId === valueId}
        ListboxProps={{
          sx: {
            maxHeight: 200,
          },
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              label: inputValue,
            });
          }

          return filtered;
        }}
        loading={loading}
        loadingText="Chargement..."
        handleHomeEndKeys
        defaultValue={alertTags}
        options={tags}
        onChange={handleChange}
        fullWidth
        noOptionsText="Aucun résultat"
        renderTags={() => null}
        renderInput={(params) => (
          <Stack spacing={1}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Loader loading={loading} />
                  </InputAdornment>
                ),
              }}
              placeholder={name}
              {...params}
            />
          </Stack>
        )}
        renderOption={(props, tag, { selected, inputValue }) => {
          const isExisting = tags.some((option) => inputValue === option.label);
          const { id } = tag;
          const isExistingTag = Boolean(id);

          if (isExistingTag) {
            return (
              <li {...props}>
                <Stack spacing={2} width="100%" direction="row" alignItems="center" justifyContent="space-between">
                  <Typography noWrap>
                    {<Checkbox checked={selected} />}
                    {tag.label}
                  </Typography>
                  {!selected && (
                    <IconButton
                      onClick={stopPropagation(() => handleDeleteTag(tag.id))}
                      size="small"
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  )}
                </Stack>
              </li>
            );
          }

          if (!isExisting)
            return (
              <li {...props}>
                <Stack spacing={2} width="100%" direction="row" alignItems="center" justifyContent="space-between">
                  <Typography
                    noWrap
                    sx={{
                      color: 'red',
                    }}
                  >
                    {tag.label}
                  </Typography>
                  <Button variant="outlined" size="small">
                    Ajouter
                  </Button>
                </Stack>
              </li>
            );

          return null;
        }}
      />
      {value.length > 0 && (
        <Box
          mt={3}
          sx={{
            '& > :not(:last-child)': { marginRight: 1 },
            '& > *': { marginBottom: 1 },
          }}
        >
          {value.map((_value) => (
            <Chip key={_value.label} label={_value.label} />
          ))}
        </Box>
      )}
    </>
  );
};

export default TagForm;
