const TAG_TYPES = {
  LOCATION: 'LOCATION',
  THIEF_PROFILE: 'THIEF_PROFILE',
  ESTIMATED_VALUE: 'ESTIMATED_VALUE',
  OTHER: 'OTHER',
  PRODUCT: 'PRODUCT',
};

const TAG_TYPES_LABEL = {
  [TAG_TYPES.PRODUCT]: 'Produit',
  [TAG_TYPES.LOCATION]: 'Lieu',
  [TAG_TYPES.THIEF_PROFILE]: 'Profil',
  [TAG_TYPES.ESTIMATED_VALUE]: 'Valeur estimée',
  [TAG_TYPES.OTHER]: 'Autre',
};

export { TAG_TYPES, TAG_TYPES_LABEL };
