import PropTypes from 'prop-types';
import { useState } from 'react';
import { TableRow, TableCell, MenuItem, Typography } from '@mui/material';
import { fDateTime } from '../../../../utils/formatTime';
import USER_ROLES from '../../../../constants/userRoles';
import Label from '../../../../components/Label';
import useAuth from '../../../../hooks/useAuth';
import { TableMoreMenu } from '../../../../components/table';
import useDialogs from '../../../../hooks/useDialogs';
import UpdatePasswordFormDialog from '../form/UpdatePasswordFormDialog';

CollaboratorTableRow.propTypes = {
  collaborator: PropTypes.object,
};

export default function CollaboratorTableRow({ collaborator, onSaveUser, onDeleteUser }) {
  const { confirm } = useDialogs();
  const { displayName, email, createdAt, inactive, role, id } = collaborator;
  const { user } = useAuth();
  const [passwordFormOpen, setPasswordFormOpen] = useState(false);

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const toggleInactive = async () => {
    const inactiveValue = !inactive;
    const state = inactiveValue ? 'inactif' : 'actif';

    const message = (
      <span>
        Vous voulez passer l'utilisateur à l'état <strong>{state}</strong>. Continuer ?
      </span>
    );

    if (!(await confirm({ message }))) return;

    try {
      await onSaveUser({
        id: collaborator.id,
        inactive: inactiveValue,
      });
    } catch {
      // Nothing to do
    }
  };

  return (
    <TableRow hover>
      <TableCell align="left">{displayName}</TableCell>

      <TableCell align="left">{email}</TableCell>

      <TableCell align="center">
        {role === USER_ROLES.STORE && (
          <Label variant="ghost" color="success">
            {id === user.id ? 'Vous' : 'Référent'}
          </Label>
        )}
        {role === USER_ROLES.EMPLOYEE && (
          <Label variant="ghost" color="warning">
            Collaborateur
          </Label>
        )}
      </TableCell>

      <TableCell align="left">{fDateTime(createdAt)}</TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  toggleInactive();
                  handleCloseMenu();
                }}
              >
                {inactive ? 'Activer' : 'Désactiver'}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPasswordFormOpen(true);
                  handleCloseMenu();
                }}
              >
                <Typography variant="inherit" noWrap>
                  Modifier le mot de passe
                </Typography>
              </MenuItem>
              <MenuItem
                sx={{ color: 'error.dark' }}
                onClick={() => {
                  onDeleteUser();
                  handleCloseMenu();
                }}
              >
                Supprimer
              </MenuItem>
            </>
          }
        />
      </TableCell>
      <UpdatePasswordFormDialog
        user={collaborator}
        open={passwordFormOpen}
        onClose={() => setPasswordFormOpen(false)}
      />
    </TableRow>
  );
}
