import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, IconButton, InputAdornment } from '@mui/material';
import Iconify from '../../../../components/Iconify';
import { RHFTextField } from '../../../../components/hook-form';
import { useSafeMutation } from '../../../../services/apollo-client/wrappers';
import { SAVE_USER } from '../../../../graphql/users/mutations';
import expectedErrors from '../../../../graphql/users/expectedErrors';
import FormDialog from '../../../../components/form/FormDialog';

export default function UpdatePasswordFormDialog({ open, onClose, user }) {
  const [saveUser, { error, loading }] = useSafeMutation(SAVE_USER);
  const [showPassword, setShowPassword] = useState(true);

  const UpdatePasswordSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdatePasswordSchema),
    defaultValues,
  });

  const onSubmit = async ({ password }) => {
    try {
      await saveUser({
        variables: {
          input: {
            id: user.id,
            password,
          },
        },
      });
    } catch (error) {
      //
    }
  };

  return (
    <FormDialog
      error={error}
      expectedErrors={[expectedErrors.INVALID_CREDENTIALS]}
      methods={methods}
      open={open}
      loading={loading}
      onSubmit={onSubmit}
      onClose={onClose}
      edit
    >
      <Stack spacing={3}>
        <RHFTextField
          name="password"
          placeholder="Mot de passe"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </FormDialog>
  );
}
