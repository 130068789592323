import { Box, Collapse, ListItemText, MenuList } from '@mui/material';
import React, { useEffect } from 'react';
import TagsTypeAlbums from './TagsTypeAlbums';
import { useSafeLazyQuery } from '../../../../services/apollo-client/wrappers';
import { LIST_STORES } from '../../../../graphql/stores/queries';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import AlbumItem from './AlbumItem';

const StoreAlbum = ({ name, storeId, onClose }) => {
  const configFilterName = 'storeId';
  const configFilterValue = storeId;

  const [open, setOpen] = useLocalStorage(`${configFilterName}-${storeId}`, false);
  const handleClickItem = () => setOpen(!open);

  return (
    <>
      <AlbumItem
        open={open}
        icon="mdi:storefront"
        onClick={handleClickItem}
        onClose={onClose}
        configFilterName={configFilterName}
        configFilterValue={configFilterValue}
        configFilterLabel={name}
      >
        <ListItemText>{name}</ListItemText>
      </AlbumItem>
      <Collapse in={open}>
        {open && (
          <Box ml={3} sx={{ borderLeft: (th) => `1px solid ${th.palette.divider}` }}>
            <TagsTypeAlbums onClose={onClose} storeId={storeId} />
          </Box>
        )}
      </Collapse>
    </>
  );
};

const StoreAlbums = ({ stores, onClose }) => {
  const [listStores, { data }] = useSafeLazyQuery(LIST_STORES);

  useEffect(() => {
    if (!stores.length)
      listStores({
        variables: {
          filters: {
            search: '',
          },
          pagination: {
            page: 1,
            rowsPerPage: 1000,
          },
        },
      });
  }, [listStores, stores]);

  const finalStores = data?.listStores.stores || stores;

  return (
    <MenuList>
      {finalStores.map(({ name, id }) => (
        <StoreAlbum onClose={onClose} name={name} storeId={id} key={id} />
      ))}
    </MenuList>
  );
};

export default StoreAlbums;
