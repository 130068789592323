// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import frLocale from 'date-fns/locale/fr';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/JWTContext';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import apolloClient from './services/apollo-client';
import { DialogsProvider } from './contexts/DialogsContext';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import NotistackProvider from './components/NotistackProvider';
import { QueryParamsProvider } from './contexts/QueryParamsContext';
import { PathHistoryProvider } from './contexts/PathHistoryContext';
import { WebviewProvider } from './contexts/WebviewContext';
import { DeviceProvider } from './contexts/DeviceContext';
import { AlertsFiltersContextProvider } from './contexts/AlertsFiltersContext';

ReactDOM.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterMoment} locale={frLocale}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <ApolloProvider client={apolloClient}>
                <BrowserRouter>
                  <QueryParamsProvider>
                    <PathHistoryProvider>
                      <ThemeProvider>
                        <ThemeSettings>
                          <WebviewProvider>
                            <NotistackProvider>
                              <DialogsProvider>
                                <AuthProvider>
                                  <DeviceProvider>
                                    <AlertsFiltersContextProvider>
                                      <App />
                                    </AlertsFiltersContextProvider>
                                  </DeviceProvider>
                                </AuthProvider>
                              </DialogsProvider>
                            </NotistackProvider>
                          </WebviewProvider>
                        </ThemeSettings>
                      </ThemeProvider>
                    </PathHistoryProvider>
                  </QueryParamsProvider>
                </BrowserRouter>
              </ApolloProvider>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </PersistGate>
    </ReduxProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const registrationWaiting = registration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.href = `${window.location.pathname}${window.location.search}`;
        }
      });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
