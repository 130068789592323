// ----------------------------------------------------------------------

export default function TextField(theme) {
    return {
        MuiTextField: {
            styleOverrides: {
                root: { /* Nothing here yet! */ }
            }
        }
    }
}