import { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Container,
    Typography,
    Stack,
    Tooltip,
    ToggleButtonGroup,
    Grid2,
    IconButton,
    Chip,
    Link
} from "@mui/material";
import Page from "src/components/Page";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Iconify from "src/components/Iconify";
import CardToggleButton from "src/components/ftp/CardToggleButton";

// API Service
import ApiService from "src/services/api";

// App
export default function MyStores() {
    const theme = useTheme();

    return (
        <Page title="Mes Magasins">
            <Container maxWidth={false}>
                <HeaderBreadcrumbs heading="Mes Magasins" links={[{ name: `Gérer vos magasins` }]} />

                {/* Stores List */}
                <ToggleButtonGroup
                    sx={{ border: "none" }}
                    exclusive
                    fullWidth
                >
                    <Grid2 container spacing={2} sx={{ width: "100%" }}>

                        {/* Show a list of stores */}
                        <StoreList />

                        {/* Add New Store icon */}
                        <Grid2 size={{ xs: 6, md: 4, lg: 3 }}>
                            <Box sx={{ borderRadius: "8px", border: "1px solid rgba(145, 158, 171, 0.12)", height: "122px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Tooltip title="Ajouter un nouveau magasin">
                                    <IconButton>
                                        <Iconify icon="mingcute:add-fill" color={theme.palette.grey[500]} width={26} height={26} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid2>

                    </Grid2>
                </ToggleButtonGroup>
            </Container>
        </Page>
    );
}

/**
 * A list of stores as Mui Cards
 * @returns {React.ReactNode} Rendered component
 */
const StoreList = () => {
    const theme = useTheme();
    const [stores, setStores] = useState([]);

    // Fetch API data
    useEffect(() => {
        const getStores = async () => {
            try {
                const storeData = await ApiService.fetchStores();
                setStores(storeData);
            } catch {
                console.error("Failed to fetch stores!");
            }
        }
        getStores();
    }, []);

    return (
        <>
            {stores.map(({ name, cameras, id }) => (
                <Grid2 sx={{ position: "relative" }} size={{ xs: 6, md: 4, lg: 3 }}>
                    <Link underline="none" href={`/stores/details/${id}`}>
                        <CardToggleButton
                            sx={{ width: "100%" }}
                            key={name}
                            icon={(
                                <Iconify icon="mdi:shopping" color={theme.palette.grey[500]} width={26} height={26} />
                            )}
                            label={name}
                        />
                    </Link>

                    <Chip
                        sx={{
                            position: "absolute",
                            right: (theme) => theme.spacing(2),
                            top: (theme) => theme.spacing(2),
                            bgcolor: "#fff",
                            display: { xs: "none", sm: "flex" },    // Don't display on mobile screens
                            pointerEvents: "none"                   // Make clicks passthrough
                        }}
                        label={(
                            <Stack direction="row">
                                <Typography variant="caption" sx={{ mt: "1px", fontWeight: "bold", color: "#7e868e" }}>{cameras}</Typography>
                                <Iconify sx={{ ml: "3px" }} icon="mdi:video" color={theme.palette.grey[500]} width={20} height={20} />
                            </Stack>
                        )}
                        variant="filled"
                    />
                </Grid2>
            ))}
        </>
    );
}
