import { Box, Stack, Tab, Tabs } from '@mui/material';
import React, { useMemo, useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import USER_ROLES, { checkRole } from '../../../../constants/userRoles';
import AlertsList from '../../alerts/list';
import CollaboratorsList from '../../collaborators/list/CollaboratorsList';

const StoreViewTabs = ({ storeId }) => {
  const { user } = useAuth();
  const TABS = useMemo(() =>[
    {
      value: 'alerts',
      label: 'Alertes',
      content: () => <AlertsList storeId={storeId} />,
    },
    {
      value: 'collaborators',
      label: 'Collaborateurs',
      roles: [USER_ROLES.STORE, USER_ROLES.ADMIN],
      content: () => <CollaboratorsList storeId={storeId} />,
    },
  ], [storeId]);

  const [tab, setTab] = useState('alerts');
  const TabContent = useMemo(() => TABS.find(({ value }) => value === tab)?.content, [tab, TABS]);

  return (
    <Stack spacing={2}>
      <Tabs onChange={(e, v) => setTab(v)} value={tab}>
        {TABS.filter(
          ({ roles }) => !roles || (roles && checkRole({ currentRole: user?.role, rolesToCheck: roles }))
        ).map(({ value, label }) => (
          <Tab sx={{ px: 2 }} key={value} value={value} label={label} />
        ))}
      </Tabs>
      <Box>
        <TabContent />
      </Box>
    </Stack>
  );
};

export default StoreViewTabs;
