import { useNavigate, useParams } from "react-router";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
    Box,
    Container,
    Tabs,
    Tab,
    TextField,
    InputAdornment,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Card,
    Grid2,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Skeleton,
    Stack,
    Link,
    useTheme,
    Tooltip,
    IconButton,
    ToggleButtonGroup,
    Chip,
    ToggleButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    ListSubheader,
} from "@mui/material";

// API Service
import ApiService from "src/services/api";

// Table
import configs from "src/config/index";
import Scrollbar from "src/components/Scrollbar";
import { TableHeadCustom } from "src/components/table";
import { StoreTableRow } from "src/sections/@dashboard/store/list";
import Label from 'src/components/Label';
import Iconify from "src/components/Iconify";
import EmptyStateSkeleton from "../../../../components/ftp/EmptyStateSkeleton";


const TABLE_HEAD = [
    { id: 'id', label: "Name", align: 'left' },
    { id: 'code', label: "Status", align: 'left' },
    { id: 'name', label: "IP", align: 'left' },
    { id: 'hasValidation', label: "Port", align: 'left' },
    { id: 'createdAt', label: "Preview", align: 'left' },
];

export default function Cameras() {
    const navigate = useNavigate();
    const [cameras, setCameras] = useState([]);

    // Fetch API data
    useEffect(() => {
        const getCameras = async () => {
            try {
                const cameraData = await ApiService.fetchCameras();
                setCameras(cameraData);
            } catch {
                console.error("Failed to fetch cameras!");
            }
        }
        getCameras();
    }, []);

    return (
        <Box sx={{ mt: 2 }}>
            {cameras.length === 0 ? (
                <EmptyStateSkeleton
                    title="Pas de caméras"
                    text="Ajoutez des caméras pour compléter votre magasin !"
                    buttonText="Ajouter une caméra"
                    buttonOnClick={() => navigate("/new-camera")}
                    disabled
                />
            ) : (
                <Card sx={{ mt: 2, p: 4 }}>
                    {/* Header */}
                    <Box>
                        <Typography variant="h4">Cameras</Typography>
                        <Typography sx={{ color: "text.disabled" }}>Cameras associated with this shop</Typography>
                    </Box>

                    {/* Latest Alerts */}
                    <Box sx={{ mt: 2 }}>
                        {/* SearchBar */}
                        <Stack direction="row" spacing={2}>
                            <TextField
                                autoFocus
                                fullWidth
                                placeholder="Search"
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                            </InputAdornment>
                                        )
                                    }
                                }}
                            />
                            <Button
                                sx={{ width: 200 }}
                                variant="contained"
                                color="primary"
                                onClick={() => navigate("/new-camera")}
                                startIcon={<Iconify icon="eva:plus-fill" />}
                                size="small"
                            >
                                Add Camera
                            </Button>
                        </Stack>

                        {/* List of cameras */}
                        <Box sx={{ mt: 2 }}>
                            <CameraList cameras={cameras} />
                        </Box>
                    </Box>
                </Card>
            )}
        </Box>
    );
}

// {row.status === "Running" && <Label variant="ghost" color="success">{row.status}</Label>}
// {row.status === "Paused" && <Label variant="ghost" color="warning">{row.status}</Label>}
// {row.status === "Stopped" && <Label variant="ghost" color="error">{row.status}</Label>}

function CameraList({ cameras }) {
    return (
        <Grid2 container spacing={2}>
            {cameras.map((camera) => {
                const notifications = randomNotificationAmount();

                return (
                <Grid2 key={camera.id} size={{ sx: 12, sm: 6, md: 4 }} sx={{ borderRadius: 4, overflow: "hidden" }}>
                    <ImageListItem key={camera.preview}>
                        <img
                            src={camera.preview}
                            alt={camera.name}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={camera.name}
                            subtitle={`${camera.ip}:${camera.port}`}
                            actionIcon={
                                <IconButton
                                    sx={{ position: "relative", color: "rgba(255, 255, 255, 0.54)", mr: 2 }}
                                    aria-label={`info about ${camera.name}`}
                                >
                                    {notifications !== 0 && (
                                        <Stack justifyContent="center" alignItems="center" sx={{
                                            position: "absolute",
                                            top: 4,
                                            right: 4,
                                            height: (theme) => theme.spacing(2),
                                            px: 0.6,
                                            width: "auto",
                                            bgcolor: "tomato",
                                            borderRadius: "20px"
                                        }}>
                                            <span style={{ fontSize: 12, fontWeight: 600 }}>{notifications}</span>
                                        </Stack>
                                    )}

                                    <Iconify icon="mdi:bell" width={22} height={22} />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                </Grid2>
            )})}
        </Grid2>
    );
}

function randomNotificationAmount() {
    // 50% chance to return 0
    if (Math.random() < 0.5) {
      return 0;
    }
    
    // Generate a random number between 1 and 40 if not 0
    return Math.floor(Math.random() * 15) + 1;
  }