import { LoadingButton } from '@mui/lab';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import Iconify from '../../../../components/Iconify';
import USER_ROLES from '../../../../constants/userRoles';
import { EVALUATE_ALERT } from '../../../../graphql/alerts/mutations';
import RoleBasedGuard from '../../../../guards/RoleBasedGuard';
import useDialogs from '../../../../hooks/useDialogs';
import { useSafeMutation } from '../../../../services/apollo-client/wrappers';

const EvaluationButtons = ({ falsePositive, alertId, onClose }) => {
  const { confirm } = useDialogs();
  const { enqueueSnackbar } = useSnackbar();

  const [evaluateAlert, { loading: evaluating }] = useSafeMutation(
    EVALUATE_ALERT,
    {
      refetchQueries: ['getAlert', 'listAlerts'],
      onCompleted: () => {
        enqueueSnackbar("L'alerte à été évaluée avec succès !");
        onClose();
      },
    },
    {
      silentError: false,
    }
  );

  const handleEvaluation = async (falsePositive) => {
    const state = falsePositive ? 'FAUX-POSITIF' : 'VOL';
    const message = (
      <span>
        Vous voulez qualifier cette vidéo comme étant un <strong>{state}</strong>. Continuer ?
      </span>
    );

    if (!(await confirm({ message }))) return;
    try {
      await evaluateAlert({
        variables: {
          alertId,
          falsePositive,
        },
      });
    } catch {
      //
    }
  };

  const evaluated = falsePositive !== null;

  if (!evaluated)
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title="C'est une fausse alerte">
          <LoadingButton
            size="large"
            onClick={() => handleEvaluation(true)}
            loading={evaluating}
            variant="outlined"
            fullWidth
          >
            <Iconify icon="mdi:thumb-down" />
          </LoadingButton>
        </Tooltip>
        <Tooltip title="C'est un vol">
          <LoadingButton
            size="large"
            onClick={() => handleEvaluation(false)}
            loading={evaluating}
            variant="outlined"
            fullWidth
          >
            <Iconify icon="mdi:thumb-up" />
          </LoadingButton>
        </Tooltip>
      </Stack>
    );

  return (
    <>
      <Typography align="center">
        Cette vidéo à été qualifiée de <strong>{falsePositive ? 'FAUX-POSITIF' : 'VOL'}</strong>
      </Typography>
      <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.STORE]}>
        <Stack direction="row" spacing={2}>
          <Tooltip title="C'est une fausse alerte">
            <LoadingButton
              size="large"
              onClick={() => handleEvaluation(true)}
              loading={evaluating}
              variant="outlined"
              fullWidth
            >
              <Iconify icon="mdi:thumb-down" />
            </LoadingButton>
          </Tooltip>
          <Tooltip title="C'est un vol">
            <LoadingButton
              size="large"
              onClick={() => handleEvaluation(false)}
              loading={evaluating}
              variant="outlined"
              fullWidth
            >
              <Iconify icon="mdi:thumb-up" />
            </LoadingButton>
          </Tooltip>
        </Stack>
      </RoleBasedGuard>
    </>
  );
};

export default EvaluationButtons;
