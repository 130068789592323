import TagFilter from './TagFilter';

const { TAG_TYPES } = require('src/constants/tagTypes');
const { default: useAlertsFilters } = require('src/hooks/useAlertsFilters');

const TagsFilters = () => {
  const { ACTIONS_HANDLERS, filtersState } = useAlertsFilters();

  const ALERT_TAGS_SELECTS = [
    {
      title: 'Lieu',
      tagType: TAG_TYPES.LOCATION,
      onChange: ACTIONS_HANDLERS.setLocationTagId,
      value: filtersState.advancedFilters.tags.locationId,
    },
    {
      title: 'Profil',
      tagType: TAG_TYPES.THIEF_PROFILE,
      onChange: ACTIONS_HANDLERS.setThiefProfileTagId,
      value: filtersState.advancedFilters.tags.thiefProfileId,
    },
    {
      title: 'Produit',
      tagType: TAG_TYPES.PRODUCT,
      onChange: ACTIONS_HANDLERS.setTheftTypeTagId,
      value: filtersState.advancedFilters.tags.theftTypeId,
    },
  ];

  return (
    <>
      {ALERT_TAGS_SELECTS.map((alertTagSelect) => (
        <TagFilter key={alertTagSelect.title} {...alertTagSelect} />
      ))}
    </>
  );
};

export default TagsFilters;
