import React from 'react';
import { ListItemText, MenuList, Typography } from '@mui/material';
import { LIST_TAGS } from '../../../../graphql/tags/queries';
import { useSafeQuery } from '../../../../services/apollo-client/wrappers';
import Loader from '../../../../components/Loader';
import AlbumItem from './AlbumItem';

const TagsAlbum = ({ label, tagId, onClose }) => {
  const configFilterName = 'tagId';
  const configFilterValue = tagId;

  return (
    <AlbumItem
      icon="mdi:local-offer"
      onClose={onClose}
      configFilterName={configFilterName}
      configFilterValue={configFilterValue}
      configFilterLabel={label}
    >
      <ListItemText>{label}</ListItemText>
    </AlbumItem>
  );
};

const TagsAlbums = ({ tagsTypeId, storeId, onClose }) => {
  const { data, loading } = useSafeQuery(LIST_TAGS, {
    variables: {
      pagination: {
        page: 1,
        rowsPerPage: 1000,
      },
      tagsTypeId,
      storeId,
    },
  });

  const tags = data?.listTags?.tags;

  if (!tags || loading) return <Loader loading debounce={300} />;

  if (!tags.length)
    return (
      <Typography sx={{ ml: 2 }} variant="caption">
        Cet album est vide
      </Typography>
    );

  return (
    <MenuList>
      {tags.map(({ label, id }) => (
        <TagsAlbum key={id} onClose={onClose} tagId={id} label={label} />
      ))}
    </MenuList>
  );
};

export default TagsAlbums;
