import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Stack, CircularProgress, Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { isBoolean } from 'lodash';

import useSafeQuery from 'src/services/apollo-client/wrappers/useSafeQuery';
import { GET_ALERT } from 'src/graphql/alerts/queries';
import expectedErrors from 'src/graphql/common/expectedErrors';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Player from 'src/sections/@dashboard/alerts/detail/Player';
import AlertActionHistory from 'src/sections/@dashboard/alertV2/AlertActionHistory';
import AlertTags from 'src/sections/@dashboard/alertV2/AlertTags';
import AlertActionsToolbar from 'src/sections/@dashboard/alertV2/AlertActionsToolbar';
import AlertHeader from 'src/sections/@dashboard/alertV2/AlertHeader';
import AlertDrawer from 'src/sections/@dashboard/alertV2/drawer/AlertDrawer';
import TagsDrawer from 'src/sections/@dashboard/alertV2/drawer/TagsDrawer';
import AlertStatus from 'src/sections/shared/alerts/AlertStatus';
import useResponsive from 'src/hooks/useResponsive';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import USER_ROLES from 'src/constants/userRoles';
import Recipients from 'src/sections/@dashboard/alerts/detail/Recipients';

const AlertDetails = ({ alertId, onClose }) => {
  const { alertId: queryParamAlertId } = useParams();
  const safeAlertId = alertId || queryParamAlertId;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedAlertType, setSelectedAlertType] = useState();
  const isDesktop = useResponsive('up', 'md');

  const [isTagsDrawerOpen, setIsTagsDrawerOpen] = useState(false);

  const handleSelectAlertType = (alertType) => {
    setSelectedAlertType(alertType);
  };

  const handleTagsDrawerOpen = () => {
    setIsTagsDrawerOpen(true);
  };

  const handleTagsDrawerClose = () => {
    setIsTagsDrawerOpen(false);
  };

  const handleDrawerClose = () => {
    setSelectedAlertType(null);
    setIsDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const { data, loading } = useSafeQuery(
    GET_ALERT,
    {
      onError: () => {
        navigate(PATH_DASHBOARD.alerts.list);
        enqueueSnackbar("Cette alerte n'est plus disponible.", { variant: 'error' });
      },
      fetchPolicy: 'network-only',
      variables: { alertId: safeAlertId },
    },
    {
      expectedErrors: [expectedErrors.ALERT_UNAVAILABLE],
      silentError: false,
    }
  );

  const alert = data?.getAlert;

  if (!alert || loading)
    return (
      <Stack p={4} alignItems="center">
        <CircularProgress />
      </Stack>
    );

  const isAlertQualified = isBoolean(alert.falsePositive);

  return (
    <>
      <AlertActionsToolbar alert={alert} onClose={onClose} />
      <Grid container spacing={2} mt={isDesktop ? 2 : 0}>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            pt: isDesktop && '0px !important',
          }}
        >
          <Player alert={alert} />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
          container
          spacing={2}
          sx={{
            pt: isDesktop && '0px !important',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              '& > *': {
                height: '100%',
              },
            }}
          >
            <AlertHeader alert={alert} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              '& > *': {
                height: '100%',
              },
            }}
          >
            <AlertStatus
              alert={alert}
              onQualificationDrawerOpen={handleDrawerOpen}
              onSelectAlertType={handleSelectAlertType}
            />
          </Grid>
          {isAlertQualified && (
            <Grid
              item
              xs={12}
              sx={{
                '& > *': {
                  height: '100%',
                },
              }}
            >
              <AlertTags alert={alert} onOpenTagsDrawer={handleTagsDrawerOpen} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Container maxWidth={false} sx={{ mt: 2 }}>
        <AlertActionHistory alert={alert} />
        <RoleBasedGuard roles={[USER_ROLES.ADMIN]}>
          <Recipients metadata={alert.metadata} />
        </RoleBasedGuard>
        {isDrawerOpen && (
          <AlertDrawer alert={alert} onClose={handleDrawerClose} selectedAlertType={selectedAlertType} />
        )}
        {isTagsDrawerOpen && <TagsDrawer alert={alert} onTagsDrawerClose={handleTagsDrawerClose} />}
      </Container>
    </>
  );
};

export default AlertDetails;
