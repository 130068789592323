import PropTypes from 'prop-types';
import { useState } from 'react';
import { TableRow, TableCell, MenuItem } from '@mui/material';
import { fDateTime } from '../../../../utils/formatTime';
import { TableMoreMenu } from '../../../../components/table';
import useDialogs from '../../../../hooks/useDialogs';
import RoleBasedGuard from '../../../../guards/RoleBasedGuard';
import USER_ROLES from '../../../../constants/userRoles';

ContactTableRow.propTypes = {
  contact: PropTypes.object,
  onEditContact: PropTypes.func,
  onDeleteUser: PropTypes.func,
};

export default function ContactTableRow({ contact, onEditContact, onSaveUser, onDeleteUser }) {
  const { displayName, email, createdAt, inactive } = contact;
  const { confirm } = useDialogs();

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const toggleInactive = async () => {
    const inactiveValue = !inactive;
    const state = inactiveValue ? 'inactif' : 'actif';

    const message = (
      <span>
        Vous voulez passer l'utilisateur à l'état <strong>{state}</strong>. Continuer ?
      </span>
    );

    if (!(await confirm({ message }))) return;

    try {
      await onSaveUser({
        id: contact.id,
        inactive: inactiveValue,
      });
    } catch {
      // Nothing to do
    }
  };

  return (
    <TableRow hover>
      <TableCell align="left">{displayName}</TableCell>

      <TableCell align="left">{email}</TableCell>

      <TableCell align="left">{fDateTime(createdAt)}</TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onEditContact();
                  handleCloseMenu();
                }}
              >
                Modifier
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toggleInactive();
                  handleCloseMenu();
                }}
              >
                {inactive ? 'Activer' : 'Désactiver'}
              </MenuItem>
              <RoleBasedGuard roles={[USER_ROLES.ADMIN]}>
                <MenuItem
                  sx={{ color: 'error.dark' }}
                  onClick={() => {
                    onDeleteUser();
                    handleCloseMenu();
                  }}
                >
                  Supprimer
                </MenuItem>
              </RoleBasedGuard>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
