import configs from 'src/config/index';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { TableRow, TableCell, MenuItem, Stack } from '@mui/material';
import { fDateTimeToUtc } from '../../../../utils/formatTime';
import { TableMoreMenu } from '../../../../components/table';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import stopPropagation from '../../../../utils/stopPropagation';
import useDialogs from '../../../../hooks/useDialogs';
import USER_ROLES from '../../../../constants/userRoles';
import RoleBasedGuard from '../../../../guards/RoleBasedGuard';
import Label from '../../../../components/Label';

StoreTableRow.propTypes = {
  store: PropTypes.object,
  onEditStore: PropTypes.func,
  onSaveStore: PropTypes.func,
  onDeleteStore: PropTypes.func,
};

export default function StoreTableRow({ store, onEditStore, onSaveStore, onDeleteStore }) {
  const { code, createdAt, name, inactive, hasValidation, id } = store;
  const { confirm } = useDialogs();
  const [openMenu, setOpenMenuActions] = useState(null);
  const pathToAlertsList = (configs.features.onboarding ? `/stores/details/${id}` : `/alerts?storeId=${id}`);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const toggleInactive = async () => {
    const inactiveValue = !inactive;
    const state = inactiveValue ? 'inactif' : 'actif';

    const message = (
      <span>
        Vous voulez passer le magasin à l'état <strong>{state}</strong>. Continuer ?
      </span>
    );

    if (!(await confirm({ message }))) return;

    onSaveStore({
      id,
      inactive: !inactive,
    });
  };

  const navigate = useNavigate();

  return (
    <TableRow sx={{ cursor: 'pointer' }} hover onClick={() => navigate(pathToAlertsList)}>
      <TableCell align="left" sx={{ maxWidth: 150 }}>
        <Stack direction="row" spacing={1}>
          <CopyToClipboard variant="body2" text={id} />
        </Stack>
      </TableCell>
      <TableCell align="left">{code}</TableCell>

      <TableCell align="left">{name}</TableCell>

      <TableCell align="left">
        {hasValidation && (
          <Label variant="ghost" color="warning">
            Soumis à validation
          </Label>
        )}
        {!hasValidation && (
          <Label variant="ghost" color="success">
            Automatique
          </Label>
        )}
      </TableCell>

      <TableCell align="left">{fDateTimeToUtc(createdAt)}</TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={stopPropagation(handleOpenMenu)}
          onClose={stopPropagation(handleCloseMenu)}
          actions={
            <>
              <MenuItem
                onClick={stopPropagation(() => {
                  onEditStore();
                  handleCloseMenu();
                })}
              >
                Modifier
              </MenuItem>
              <MenuItem
                onClick={stopPropagation(() => {
                  toggleInactive();
                  handleCloseMenu();
                })}
              >
                {inactive ? 'Activer' : 'Désactiver'}
              </MenuItem>
              <RoleBasedGuard roles={[USER_ROLES.ADMIN]}>
                <MenuItem
                  sx={{ color: 'error.dark' }}
                  onClick={stopPropagation(() => {
                    onDeleteStore();
                    handleCloseMenu();
                  })}
                >
                  Supprimer
                </MenuItem>
              </RoleBasedGuard>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
