const CONFIRMED_ALERT_TYPES = {
  THIEF_ARRESTED: 'THIEF_ARRESTED',
  THIEF_ON_THE_RUN: 'THIEF_ON_THE_RUN',
  THIEF_UNCONFIRMED: 'THIEF_UNCONFIRMED',
  OTHER: 'OTHER',
};

const CONFIRMED_ALERT_TYPES_LABELS = {
  [CONFIRMED_ALERT_TYPES.THIEF_ARRESTED]: 'Voleur arrêté',
  [CONFIRMED_ALERT_TYPES.THIEF_ON_THE_RUN]: 'Voleur en fuite',
  [CONFIRMED_ALERT_TYPES.THIEF_UNCONFIRMED]: 'Voleur non avéré',
  [CONFIRMED_ALERT_TYPES.OTHER]: 'Autre',
};

const NON_CONFIRMED_ALERT_TYPES = {
  SUSPICIOUS_GESTURE: 'SUSPICIOUS_GESTURE',
  FALSE_DETECTION: 'FALSE_DETECTION',
  OTHER: 'OTHER',
};

const NON_CONFIRMED_ALERT_TYPES_LABELS = {
  [NON_CONFIRMED_ALERT_TYPES.SUSPICIOUS_GESTURE]: 'Geste suspect',
  [NON_CONFIRMED_ALERT_TYPES.FALSE_DETECTION]: 'Mauvaise détection',
  [NON_CONFIRMED_ALERT_TYPES.OTHER]: 'Autre',
};

const ALL_ALERT_TYPES_LABELS = {
  ...CONFIRMED_ALERT_TYPES_LABELS,
  ...NON_CONFIRMED_ALERT_TYPES_LABELS,
};

const ALERT_GLOBAL_TYPES = {
  CONFIRMED: 'CONFIRMED',
  FALSE_POSITIVE: 'FALSE_POSITIVE',
};

const ALERT_GLOBAL_TYPES_LABELS = {
  [ALERT_GLOBAL_TYPES.CONFIRMED]: 'Vol confirmé',
  [ALERT_GLOBAL_TYPES.FALSE_POSITIVE]: 'Fausse alerte',
};

const ALERT_GLOBAL_TYPES_VALUES = {
  [ALERT_GLOBAL_TYPES.CONFIRMED]: false,
  [ALERT_GLOBAL_TYPES.FALSE_POSITIVE]: true,
};

export {
  CONFIRMED_ALERT_TYPES,
  CONFIRMED_ALERT_TYPES_LABELS,
  NON_CONFIRMED_ALERT_TYPES,
  NON_CONFIRMED_ALERT_TYPES_LABELS,
  ALL_ALERT_TYPES_LABELS,
  ALERT_GLOBAL_TYPES,
  ALERT_GLOBAL_TYPES_LABELS,
  ALERT_GLOBAL_TYPES_VALUES,
};
