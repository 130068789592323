import { IconButton, ListItemIcon, MenuItem, Stack } from '@mui/material';
import { alpha, styled } from '@mui/system';
import React from 'react';
import useQueryParams from '../../../../hooks/useQueryParams';
import Iconify from '../../../../components/Iconify';
import stopPropagation from '../../../../utils/stopPropagation';

const DynamicMenuItem = styled(MenuItem)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    '& .actions': {
      display: 'none',
    },
    '&:hover .actions': {
      display: 'block',
    },
  },
}));

const AlbumItem = ({
  open,
  icon,
  onClick,
  onClose,
  configFilterName,
  configFilterValue,
  children,
  configFilterLabel,
}) => {
  const { filterName, filterValue, changeParameters } = useQueryParams();

  const isSelected = filterName === configFilterName && filterValue === configFilterValue;

  const handleGoToAlbum = () => {
    changeParameters(
      {
        filterName: configFilterName,
        filterValue: configFilterValue,
        filterLabel: configFilterLabel,
      },
      { replace: true }
    );
    onClose();
  };

  return (
    <DynamicMenuItem
      sx={{
        height: 43,
        ...(isSelected && {
          borderLeft: (theme) => `3px solid ${theme.palette.primary.main}`,
          background: (theme) => alpha(theme.palette.primary.main, 0.05),
        }),
      }}
      onClick={onClick}
    >
      <ListItemIcon>
        <Stack direction="row" spacing={1}>
          <Iconify sx={{ color: 'text.disabled' }} icon={icon} />
          {open !== undefined && (
            <Iconify
              icon="mdi:keyboard-arrow-right"
              sx={{
                transition: (theme) => theme.transitions.create('transform'),
                ...(open && { rotate: '90deg' }),
              }}
            />
          )}
        </Stack>
      </ListItemIcon>
      {children}
      <div className="actions">
        <ListItemIcon>
          <IconButton size="small" onClick={stopPropagation(handleGoToAlbum)}>
            <Iconify sx={{ color: 'text.disabled' }} icon="eva:eye-fill" />
          </IconButton>
        </ListItemIcon>
      </div>
    </DynamicMenuItem>
  );
};

export default AlbumItem;
