import configs from "src/config/index";


/**
 * A SINGULAR class to handle API requests for all app services.
 */
class ApiServiceClass {
    static instance = null;

    /**
     * Creates an instance of ApiServiceClass.
     * If an instance already exists, the same instance is returned.
     * 
     * @param {string} baseURL - The base URL for all API requests.
     * @returns {ApiServiceClass} The instance of the ApiServiceClass class.
     */
    constructor(baseURL) {
        // Check if this instance already exists
        if (!ApiServiceClass.instance) {
            this.baseURL = baseURL;
            ApiServiceClass.instance = this;
        }
    }

    // Utility methods
    async fetchData(endpoint) {
        const response = await fetch(`${this.baseURL}/${endpoint}`);
        return response.json();
    }

    async updateData(endpoint, data) {
        const response = await fetch(`${this.baseURL}/${endpoint}`, {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });
        return response.json();
    }

    async deleteData(endpoint) {
        const response = await fetch(`${this.baseURL}/${endpoint}`, {
            method: "DELETE",
        });
        return response.json();
    }

    /**
     * Fetch a list of stores
     * 
     * @returns {Promise<Object[]>} A promise of a list of stores
     */
    async fetchStores() {
        return this.fetchData("store/list");
    }

    /**
     * Fetch a singular store
     * 
     * @param {string} storeId - ID of store
     * @returns {Promise<Object[]>} A promise of store information
     */
    async fetchStore(storeId) {
        return this.fetchData(`store/${storeId}`);
    }

    /**
     * Fetch a list of cameras associated with a store
     * 
     * @param {string} storeId - ID of store
     * @returns {Promise<Object[]>} A promise of a list of cameras
     */
    async fetchCameras(storeId) {
        return this.fetchData(`store/${storeId}/cameras`);
    }

    /**
     * Fetch a list of machines associated with a store
     * 
     * @param {string} storeId - ID of store
     * @returns {Promise<Object[]>} A promise of a list of machines
     */
    async fetchMachines(storeId) {
        return this.fetchData(`store/${storeId}/machines`);
    }
}

// Create the API service with the AlfredEyes base API URL
const ApiService = new ApiServiceClass(configs.alfredAPI.base);

// Export the service class
export default ApiService;