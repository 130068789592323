import { Autocomplete, Checkbox, TextField } from '@mui/material';
import React from 'react';
import { LIST_STORES } from '../../../../graphql/stores/queries';
import { useSafeQuery } from '../../../../services/apollo-client/wrappers';

const StoresSelect = ({ onChange, value }) => {
  const { data, loading } = useSafeQuery(LIST_STORES, {
    fetchPolicy: 'network-only',
    variables: {
      pagination: {
        page: 1,
        rowsPerPage: 1000,
      },
      filters: {
        search: '',
        inactive: false,
      },
    },
  });

  const stores = data?.listStores?.stores || [];

  if (!stores.length || loading) return null;

  const storesNames = stores.reduce((acc, store) => {
    acc[store.id] = store.name;
    return acc;
  }, {});

  return (
    <Autocomplete
      multiple
      limitTags={2}
      disableCloseOnSelect
      value={value}
      options={stores.map(({ id }) => id)}
      onChange={(e, v) => onChange(v || undefined)}
      fullWidth
      getOptionLabel={(storeId) => storesNames[storeId]}
      noOptionsText="Aucun résultat"
      renderInput={(params) => <TextField {...params} placeholder="Magasins" />}
      renderOption={(props, storeId, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {storesNames[storeId]}
        </li>
      )}
    />
  );
};

export default StoresSelect;
