import { Stack, Typography, Chip, Card } from '@mui/material';
import { TAG_TYPES, TAG_TYPES_LABEL } from 'src/constants/tagTypes';
import useDialogs from 'src/hooks/useDialogs';
import useSafeMutation from 'src/services/apollo-client/wrappers/useSafeMutation';
import { DELETE_ALERT_TAG } from 'src/graphql/tags/mutations';
import stopPropagation from 'src/utils/stopPropagation';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import Iconify from 'src/components/Iconify';
import useResponsive from 'src/hooks/useResponsive';

const AlertTags = ({ alert, onOpenTagsDrawer }) => {
  const { confirm } = useDialogs();
  const isDesktop = useResponsive('up', 'md');

  const [deleteAlertTag] = useSafeMutation(
    DELETE_ALERT_TAG,
    {
      refetchQueries: ['getAlert', 'listUserAlertActions'],
    },
    {
      silentError: false,
    }
  );

  const handleDeleteTag = async (tag) => {
    const tagTypeLabel = `${TAG_TYPES_LABEL[tag.tagType.name]} | ${tag.label}`;

    try {
      if (
        await confirm({
          message: `Voulez-vous vraiment supprimer le tag ${tagTypeLabel} ?`,
        })
      ) {
        await deleteAlertTag({
          variables: {
            tagId: tag.id,
            alertId: alert.id,
          },
        });
      }
    } catch {
      // Nothing to do
    }
  };

  const { tags } = alert;

  const sortedTagsOnTagTypeLabel = tags.sort((a, b) => {
    const previousName = TAG_TYPES_LABEL[a.tagType.name];
    const nextName = TAG_TYPES_LABEL[b.tagType.name];
    return previousName.localeCompare(nextName);
  });

  return (
    <Card
      sx={{
        border: 'none',
        borderRadius: 0,
        borderBottom: isDesktop ? 'none' : '1px solid #E5E5E5',
      }}
    >
      <Stack
        sx={{
          height: '100%',
        }}
      >
        <Typography variant="h6">Tags</Typography>
        <Typography variant="caption">Retrouvez ici toutes les informations du vol</Typography>
        <Stack spacing={1} direction="row" sx={{ mt: 3, flex: 1, flexWrap: 'wrap', rowGap: 1 }}>
          {sortedTagsOnTagTypeLabel.map((tag) => {
            const isMoneyTag = tag.tagType.name === TAG_TYPES.ESTIMATED_VALUE;
            const tagValue = isMoneyTag ? `${tag.label} €` : `${tag.label}`;
            const tagTypeNormalized = TAG_TYPES_LABEL[tag.tagType.name];
            const tagLabel = `${tagTypeNormalized} | ${tagValue}`;

            return (
              <Chip
                key={tag.id}
                onDelete={() => {
                  handleDeleteTag(tag);
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '50px',
                  borderColor: '#E8E8E8',
                  color: '#000000',
                  backgroundColor: '#F3F3F3',
                  '&:hover, &:focus, &:active': {
                    backgroundColor: 'rgba(248, 132, 47, 0.04)',
                  },
                  '& .MuiChip-outlined': {
                    border: '2px solid',
                  },
                  '& .MuiChip-label': {
                    flexGrow: 1,
                    textAlign: 'center',
                  },
                  '& .MuiChip-deleteIcon': {
                    margin: '0px 4px 0px auto',
                  },
                }}
                variant="outlined"
                label={tagLabel}
                deleteIcon={<CloseIcon />}
              />
            );
          })}
          <Chip
            deleteIcon={
              <Iconify
                icon="eva:plus-fill"
                sx={{
                  color: `#071AC8 !important`,
                  width: 16,
                  height: 16,
                }}
              />
            }
            onClick={stopPropagation(onOpenTagsDrawer)}
            onDelete={stopPropagation(onOpenTagsDrawer)}
            sx={{
              p: 1,
              maxWidth: 'fit-content',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50px',
              borderColor: '#071AC8',
              color: '#071AC8',
              backgroundColor: 'rgba(7, 26, 200, 0.05)',
              outline: '1px solid',
              cursor: 'pointer',
              '&:hover, &:focus, &:active': {
                backgroundColor: `rgba(7, 26, 200, 0.05) !important`,
                color: `#071AC8 !important`,
              },
              '& .MuiChip-outlined': {
                border: '2px solid',
              },
            }}
            label="Ajouter"
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default AlertTags;
