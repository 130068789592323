const ALERT_ACTION_TYPES = {
  WATCHED: 'WATCHED',
  CONFIRMED: 'CONFIRMED',
  FALSE_ALERT: 'FALSE_ALERT',
  TAGGED: 'TAGGED',
  TYPED: 'TYPED',
  UNTAGGED: 'UNTAGGED',
};

export default ALERT_ACTION_TYPES;
