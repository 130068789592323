import { useNavigate, useParams } from "react-router";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
    Box,
    Container,
    Tabs,
    Tab,
    TextField,
    InputAdornment,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Card,
    Grid2,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Skeleton,
    Stack,
    Link,
    useTheme,
    Tooltip,
    IconButton,
    ToggleButtonGroup,
    Chip,
    ToggleButton
} from "@mui/material";

// Required GraphQL Queries
import { GET_STORE } from "src/graphql/stores/queries";

// Custom
import CardToggleButton from "src/components/ftp/CardToggleButton";

import Page from "src/components/Page";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Iconify from "src/components/Iconify";
import Label from 'src/components/Label';

// Views
import Machines from "./Machines";
import Cameras from "./Cameras";


export default function Overview() {
    // Get the store ID from the URL parameters
    const { id } = useParams();
    const navigate = useNavigate();
    const [store, setStore] = useState([]);
    const theme = useTheme();

    // Alerts page
    const alertsPage = `/alerts?storeId=${id}`;

    // Use Apollo's useQuery hook to fetch store details
    const { loading, error, data } = useQuery(GET_STORE, {
        variables: { id },
    });

    // Fetch the stores data from a GraphQL query
    useEffect(() => {
        // Data changed! Update the stores state!
        if (data)
            setStore(data.getStore);
    }, [data]);

    return (
        <>
            {/* List of Machines */}
            <Machines />

            {/* List of Cameras */}
            <Cameras />
        </>
    );
}

function Alerts() {
    const alertList = [
        {
            "thumbnail": "https://i.ytimg.com/vi/pvSXQcbBEh0/maxresdefault.jpg",
            "message": "Suspicious Gesture",
            "status": "warning",
            bgcolor: "#fff5d7"
        },
        {
            "thumbnail": "https://i.shgcdn.com/4ea79375-c5a1-4466-9a5f-9a701fc9ca65/-/format/auto/-/preview/3000x3000/-/quality/lighter/",
            "message": "Theft Detected",
            "status": "error",
            bgcolor: "#fee2e2"
        },
        {
            "thumbnail": "https://blog.camerasecuritynow.com/wp-content/uploads/2016/06/Cam.jpg",
            "message": "Suspicious Gesture",
            "status": "warning",
            bgcolor: "#fff5d7"
        }
    ]

    return (
        <>
            {alertList.map((item, index) => (
                <Grid2 size={4} sx={{ position: "relative" }}>
                    {/* Image */}
                    <Box
                        src={item.thumbnail}
                        component="img"
                        sx={{ borderRadius: 4, height: 200, width: "100%", objectFit: "cover" }}
                    />

                    <Box sx={{ position: "absolute", right: (theme) => theme.spacing(1), bottom: (theme) => theme.spacing(1) }}>
                        <Label variant="ghost" color={item.status} sx={{ bgcolor: item.bgcolor }}>
                            {item.message}
                        </Label>
                    </Box>
                </Grid2>
            ))}
        </>
    );
}
