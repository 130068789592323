import { Stack } from '@mui/material';
import React, { useMemo } from 'react';
import Loader from '../../../../components/Loader';
import { LIST_TAGS_TYPES } from '../../../../graphql/tags/queries';
import { useSafeQuery } from '../../../../services/apollo-client/wrappers';
import TagForm from './TagForm';

const TagsForm = ({ storeId, alertId, tags }) => {
  const { data, loading } = useSafeQuery(LIST_TAGS_TYPES);

  const tagsByTypeId = useMemo(
    () =>
      tags.reduce(
        (acc, tag) =>
          acc[tag.tagsTypeId]
            ? { ...acc, [tag.tagsTypeId]: [...acc[tag.tagsTypeId], tag] }
            : { ...acc, [tag.tagsTypeId]: [tag] },
        {}
      ),
    [tags]
  );

  const types = data?.listTagsTypes || [];

  return (
    <Stack spacing={2}>
      <Loader loading={loading} />
      {types.map((type) => {
        const alertTags = tagsByTypeId?.[type.id] || [];

        return <TagForm alertId={alertId} key={type.id} type={type} storeId={storeId} alertTags={alertTags} />;
      })}
    </Stack>
  );
};

export default TagsForm;
