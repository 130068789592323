import { Box, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import Iconify from 'src/components/Iconify';
import { ALERT_GLOBAL_TYPES_LABELS, ALERT_GLOBAL_TYPES_VALUES } from 'src/constants/alertTypes';

const QualificationDrawerContent = ({ alert, onSelectedAlertType }) => {
  const initialSelectedAlertType = Object.keys(ALERT_GLOBAL_TYPES_VALUES).find(
    (key) => ALERT_GLOBAL_TYPES_VALUES[key] === alert.falsePositive
  );

  const [selectedAlertType, setSelectedAlertType] = useState(initialSelectedAlertType);
  const [isUserAction, setIsUserAction] = useState(false);

  const textFieldRefs = useRef({});

  const handleSelectAlertType = (alertType) => {
    setIsUserAction(true);
    setSelectedAlertType(alertType);
  };

  useEffect(() => {
    if (isUserAction) {
      onSelectedAlertType(selectedAlertType);
      setIsUserAction(false);
    }

    if (textFieldRefs.current[selectedAlertType]) {
      textFieldRefs.current[selectedAlertType].focus();
      textFieldRefs.current[selectedAlertType].blur();
    }
  }, [selectedAlertType, isUserAction, onSelectedAlertType]);

  const setTextFieldRef = (key, el) => {
    textFieldRefs.current[key] = el;
  };

  const getInputStyles = (isCurrentAlertType) => ({
    mb: 2,
    '& .MuiInputBase-input': {
      color: isCurrentAlertType ? 'primary.main' : 'inherit',
      cursor: 'pointer',
      transition: 'color 0.3s',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: isCurrentAlertType ? 'primary.main' : 'inherit',
        transition: 'border-color 0.3s',
      },
      '&:hover fieldset': {
        borderColor: isCurrentAlertType ? 'primary.main' : 'inherit',
      },
      '&.Mui-focused fieldset': {
        borderColor: isCurrentAlertType ? 'primary.main' : 'inherit',
      },
    },
  });

  return (
    <>
      <Stack alignItems="center" mb={3}>
        <Typography variant="h3">Type de vol</Typography>
        <Typography textAlign="center" sx={{ width: '300px' }}>
          Vous êtes sur le point de vérifier une alerte ?
        </Typography>
      </Stack>
      <Box width="100%">
        {Object.keys(ALERT_GLOBAL_TYPES_LABELS).map((key) => {
          const isCurrentAlertType = selectedAlertType === key;

          return (
            <TextField
              key={key}
              onClick={() => handleSelectAlertType(key)}
              value={ALERT_GLOBAL_TYPES_LABELS[key]}
              variant="outlined"
              fullWidth
              sx={getInputStyles(isCurrentAlertType)}
              inputRef={(el) => setTextFieldRef(key, el)}
              InputProps={{
                readOnly: true,
                endAdornment: isCurrentAlertType && (
                  <InputAdornment position="end">
                    <Iconify icon="eva:checkmark-circle-2-fill" color="primary.main" />
                  </InputAdornment>
                ),
              }}
            />
          );
        })}
      </Box>
    </>
  );
};

export default QualificationDrawerContent;
