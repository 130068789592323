import { Stack, Button, Typography, IconButton, Dialog, Toolbar, InputAdornment, Divider } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import sendActionToApp from 'src/utils/sendActionToApp';
import WEB_APP_ACTIONS from 'src/utils/webAppActions';
import RHFTextField from 'src/components/hook-form/RHFTextField';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import stopPropagation from 'src/utils/stopPropagation';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import { SUSPEND_USER } from 'src/graphql/users/mutations';
import { ConfirmDialog } from 'src/components/dialogs';
import USER_ROLES from 'src/constants/userRoles';
import ProfileDrawerAction from './ProfileDrawerActions';

const ProfileDialog = ({ onClose, isDesktop }) => {
  const { user, logout, expireUserAfterSuspension } = useAuth();
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [userIdToSuspend, setUserIdToSuspend] = useState(null);

  console.log('user', user);

  const [suspendUser] = useSafeMutation(SUSPEND_USER, {
    refetchQueries: ['listUsers'],
    onCompleted: () => {
      expireUserAfterSuspension();
      sendActionToApp({ action: WEB_APP_ACTIONS.LOGOUT });
    },
  });

  const handleProfileDrawerOpen = () => {
    setIsProfileDrawerOpen(true);
  };

  const handleProfileDrawerClose = () => {
    setIsProfileDrawerOpen(false);
  };

  const handleLogout = () => {
    logout();
    sendActionToApp({ action: WEB_APP_ACTIONS.LOGOUT });
  };

  const handleSuspendUser = (id) => {
    setUserIdToSuspend(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirm = async () => {
    if (user.role === USER_ROLES.STORE) {
      setOpenConfirmDialog(false);
      return;
    }
    setOpenConfirmDialog(false);
    try {
      await suspendUser({
        variables: {
          id: userIdToSuspend,
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const handleDiscard = () => {
    setOpenConfirmDialog(false);
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: Math.random().toString(36).slice(-8),
    },
  });

  return (
    <Dialog
      fullScreen={!isDesktop}
      fullWidth={isDesktop}
      onClose={stopPropagation(onClose)}
      open
      sx={{
        '.MuiDialog-paper': {
          padding: 0,
          margin: 0,
          p: (theme) => theme.spacing(6, 4),
          backgroundColor: 'white',
        },
      }}
    >
      <Toolbar
        sx={{
          mb: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          sx={{
            position: 'absolute',
            left: 0,
          }}
          onClick={stopPropagation(onClose)}
        >
          <Iconify
            icon="eva:arrow-back-outline"
            sx={{
              height: 26,
              width: 26,
            }}
          />
        </IconButton>
        <Typography variant="h6">Mon profil</Typography>
      </Toolbar>

      <Stack spacing={3} flex={1}>
        <FormProvider {...methods}>
          <RHFTextField name="firstName" label="Prénom" type="text" placeholder="Prénom" disabled />
          <RHFTextField name="lastName" label="Nom" placeholder="Nom" disabled />
          <RHFTextField name="email" label="Email" placeholder="Email" type="text" disabled />
          <RHFTextField
            name="password"
            label="Mot de passe"
            type="password"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="text"
                    onClick={() => handleProfileDrawerOpen()}
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'normal',
                      fontSize: '0.75rem',
                      textTransform: 'none',
                      minWidth: 'fit-content',
                      px: 0,
                    }}
                  >
                    Changer
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </FormProvider>
      </Stack>
      {isProfileDrawerOpen && <ProfileDrawerAction onClose={handleProfileDrawerClose} />}
      <Divider sx={{ background: '#ECECEC' }} />
      <Stack mt={4}>
        <Button size="large" type="submit" variant="contained" fullWidth onClick={handleLogout}>
          Déconnexion
        </Button>
      </Stack>
      <Stack mt={4}>
        <Button
          size="large"
          type="submit"
          variant="text"
          sx={{ color: '#071ac8' }}
          onClick={() => handleSuspendUser(user.id)}
        >
          Supprimer mon compte
        </Button>
      </Stack>
      {user.role !== USER_ROLES.ADMIN && (
        <ConfirmDialog
          open={openConfirmDialog}
          onConfirm={handleConfirm}
          onDiscard={handleDiscard}
          title="Suppression de votre compte"
          message={
            user.role === USER_ROLES.STORE
              ? 'Vous êtes dirigeant de magasin. Veuillez contacter tech@alfred-eyes.ai pour supprimer votre compte.'
              : 'Cette action est irréversible. Souhaitez-vous continuer ?'
          }
        />
      )}
    </Dialog>
  );
};

export default ProfileDialog;
