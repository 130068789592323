import { styled, useTheme } from '@mui/material/styles';
import { Drawer, Stack, Typography } from '@mui/material';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import cssStyles from 'src/utils/cssStyles';
import { APP_VERSION, NAVBAR } from 'src/config';
import Logo from 'src/components/Logo';
import Scrollbar from 'src/components/Scrollbar';
import { NavSectionVertical } from 'src/components/nav-section';
import useNavConfig from './NavConfig';
import NavbarAccount from './NavbarAccount';
import NavbarSubscription from './subscription/NavbarSubscription';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const NavbarVertical = () => {
  const theme = useTheme();
  const { isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const navConfig = useNavConfig();

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack sx={{ height: 1, flex: 1 }} spacing={2}>
        <NavbarAccount />
        <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
        <NavbarSubscription />
      </Stack>
      <Stack
        alignItems="flex-start"
        spacing={1}
        sx={{
          padding: theme.spacing(2, 2.5),
        }}
      >
        <Logo />
        <Typography variant="caption" sx={{ color: '#979797' }}>
          Version {APP_VERSION}
        </Typography>
      </Stack>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <Drawer
        open
        variant="persistent"
        onMouseEnter={onHoverEnter}
        onMouseLeave={onHoverLeave}
        PaperProps={{
          sx: {
            width: NAVBAR.DASHBOARD_WIDTH,
            background: '#F8FAFB',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.standard,
              }),
            ...(isCollapse && {
              width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
            }),
            ...(collapseHover && {
              ...cssStyles(theme).bgBlur(),
              boxShadow: (theme) => theme.customShadows.z24,
            }),
          },
        }}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
};

export default NavbarVertical;
