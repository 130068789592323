import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import useDialogs from '../../../../hooks/useDialogs';
import { DISPATCH_ALERT, DELETE_ALERT } from '../../../../graphql/alerts/mutations';
import { useSafeMutation } from '../../../../services/apollo-client/wrappers';
import { PATH_DASHBOARD } from '../../../../routes/paths';

const ValidationAlertActions = ({ alertId }) => {
  const navigate = useNavigate();

  const { confirm } = useDialogs();
  const [dispatchAlert, { loadingDispatch }] = useSafeMutation(DISPATCH_ALERT, {
    refetchQueries: ['getAlert'],
  });

  const [deleteAlert, { loadingDelete }] = useSafeMutation(DELETE_ALERT, {
    refetchQueries: ['listAlerts'],
  });

  const handleDispatchAlert = async () => {
    try {
      if (
        await confirm({
          message:
            'Si vous poursuivez, une notification sera envoyée sur tous les appareils rattachés à ce magasin. Souhaitez-vous poursuivre ?',
        })
      )
        await dispatchAlert({
          variables: { id: alertId },
        });
    } catch {
      // Nothing todo
    }
  };

  const handleDeleteAlert = async () => {
    try {
      if (
        await confirm({
          message:
            'Si vous poursuivez, cette alerte sera supprimée, et aucune notification ne sera envoyée. Souhaitez-vous poursuivre ?',
        })
      )
        await deleteAlert({
          variables: { id: alertId },
        });

      navigate(PATH_DASHBOARD.alerts.list);
    } catch {
      // Nothing todo
    }
  };

  return (
    <Stack spacing={1}>
      <LoadingButton loading={loadingDispatch} onClick={handleDispatchAlert} variant="contained">
        Envoyer
      </LoadingButton>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Divider sx={{ flex: 1 }} />
        <Typography>OU</Typography>
        <Divider sx={{ flex: 1 }} />
      </Stack>
      <LoadingButton loading={loadingDelete} onClick={handleDeleteAlert} color="error" variant="outlined">
        Supprimer
      </LoadingButton>
    </Stack>
  );
};

export default ValidationAlertActions;
