import { Box, Collapse, ListItemText, MenuList, Typography } from '@mui/material';
import React from 'react';
import Loader from '../../../../components/Loader';
import { LIST_TAGS_TYPES } from '../../../../graphql/tags/queries';
import { useSafeQuery } from '../../../../services/apollo-client/wrappers';
import TagsAlbums from './TagsAlbums';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import AlbumItem from './AlbumItem';

const TagsTypesAlbum = ({ storeId, name, tagsTypeId, onClose }) => {
  const configFilterName = 'tagsTypeId';
  const configFilterValue = tagsTypeId;

  const [open, setOpen] = useLocalStorage(`${configFilterName}-${storeId}`, false);
  const handleClickItem = () => setOpen(!open);

  return (
    <>
      <AlbumItem
        open={open}
        icon="mdi:auto-awesome-motion"
        onClick={handleClickItem}
        onClose={onClose}
        configFilterName={configFilterName}
        configFilterValue={configFilterValue}
        configFilterLabel={name}
      >
        <ListItemText>{name}</ListItemText>
      </AlbumItem>
      <Collapse in={open}>
        {open && (
          <Box ml={3} sx={{ borderLeft: (th) => `1px solid ${th.palette.divider}` }}>
            <TagsAlbums tagsTypeId={tagsTypeId} storeId={storeId} onClose={onClose} />
          </Box>
        )}
      </Collapse>
    </>
  );
};

const TagsTypeAlbums = ({ storeId, onClose }) => {
  const { data, loading } = useSafeQuery(LIST_TAGS_TYPES, {
    variables: {
      pagination: {
        page: 1,
        rowsPerPage: 100,
      },
    },
  });

  const tagsTypes = data?.listTagsTypes;

  if (!tagsTypes || loading) return <Loader loading />;

  if (!tagsTypes.length)
    return (
      <Typography sx={{ ml: 2 }} variant="caption">
        Cet album est vide
      </Typography>
    );

  return (
    <MenuList>
      {tagsTypes.map(({ name, id }) => (
        <TagsTypesAlbum key={id} name={name} onClose={onClose} tagsTypeId={id} storeId={storeId} />
      ))}
    </MenuList>
  );
};

export default TagsTypeAlbums;
