import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Loader = ({ loading, debounce = 0 }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (loading) setTimeout(() => setVisible(true), debounce);
    else setVisible(false);
  }, [loading, debounce]);

  return visible && loading ? (
    <Box p={4}>
      <CircularProgress size={16} />
    </Box>
  ) : null;
};

export default Loader;
