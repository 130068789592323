import { useState, useEffect } from "react";
import { useTheme, useThemeProps } from '@mui/material/styles';
import {
    Box,
    Container,
    Tabs,
    Tab,
    TextField,
    InputAdornment,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Stack,
    Tooltip,
    IconButton
} from "@mui/material";
import useDialogs from "src/hooks/useDialogs";
import Page from "src/components/Page";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Iconify from "src/components/Iconify";
import { useNavigate } from 'react-router-dom';

// App
export default function NewMachine() {
    const theme = useTheme();
    const navigate = useNavigate()
    const [formData, setFormData] = useState({});
    const { alert } = useDialogs();

    useEffect(() => {
        console.log("Form data changed, new value =", formData);
    }, [formData]);

    return (
        <Page title="Enregistrer une machine">
            <Container maxWidth={false}>

                <HeaderBreadcrumbs
                    heading="Enregistrer une machine"
                    links={[{ name: `Enregistrer une machine au magasin` }]}
                />

                {/* Form Steps */}
                <form>
                    <Stack spacing={3}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                label="Identifier"
                                type="text"
                                placeholder={"xxxx-xxxx-xxxx"}
                                value={formData?.machineIdentifier}
                                onChange={(e) => setFormData((prev) => ({ ...prev, machineIdentifier: e.target.value }))}
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <IconButton
                                                onClick={() => alert({
                                                    title: "Trouver l'identifiant de la machine",
                                                    message: "Cet identifiant unique est une petite clé située sur le serveur physique, généralement sur un autocollant ou une étiquette attachée à l'arrière ou au bas de l'unité. Il est inclus dans la livraison à votre client et sert de référence pour configurer correctement le système en vue de son utilisation.",
                                                })}
                                                aria-label="Need help finding machine identifier?"
                                            >
                                                <Iconify color={theme.palette.grey[800]} style={{ margin: 0, padding: 0 }} icon="material-symbols:help" width={22} height={22} />
                                            </IconButton>
                                        )
                                    }
                                }}
                            />
                        </FormControl>

                        {/* Submit Button */}
                        <Stack display={"flex"} flexDirection="row" gap={1} justifyContent={{ xs: "center", sm: "end" }}>
                            <Button onClick={() => navigate(-1)}>
                                <Iconify icon="tabler:trash" color="red" width={20} height={20} />
                            </Button>

                            <Button
                                variant="contained"
                                startIcon={<Iconify icon="fluent:tab-add-20-filled" color="#fff" width={20} height={20} />}
                                sx={{ width: { xs: "100%", sm: "fit-content" } }}>
                                Enregistrer
                            </Button>
                        </Stack>
                    </Stack>
                </form>

            </Container>
        </Page>
    );
}

function Divider() {
    const theme = useTheme();

    return (
        <Box display={"flex"} justifyContent={"center"} fullWidth>
            <Iconify icon="bxs:down-arrow" color={theme.palette.grey[300]} width={22} height={22} />
        </Box>
    );
}