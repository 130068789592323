import { gql } from '@apollo/client';

const GET_CAMERAS_OF_STORE = gql`
  query camerasOfStore($storeId: String!) {
    camerasOfStore(storeId: $storeId) {
      id
      storeId
      name
      createdAt
      updatedAt
    }
  }
`


export { GET_CAMERAS_OF_STORE };
