import {
    FormControl,
    Chip,
    Stack
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formConfig } from "../formConfig";

// Local imports
import TabContainer from "../../../../../components/ftp/TabContainer";

export default function License({ form }) {
    return (
        <TabContainer header="Choose a License" sx={{ position: "relative" }}>

            <Chip sx={{ position: "absolute", right: (theme) => theme.spacing(3), top: 14 }} label="3 Remaining" />

            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 3 }}>

                <FormControl fullWidth>
                    <DatePicker label={formConfig.license.start.label} onChange={(e) => form.setData((prev) => ({ ...prev, [formConfig.license.start.id]: e?.format() }))} disablePast />
                </FormControl>

                <FormControl sx={{ mt: 2 }} fullWidth>
                    <DatePicker label={formConfig.license.end.label} onChange={(e) => form.setData((prev) => ({ ...prev, [formConfig.license.end.id]: e?.format() }))} disablePast />
                </FormControl>

            </Stack>


        </TabContainer>
    );
}