import { Stack, Typography, Box, Card } from '@mui/material';
import { fAlertDate } from 'src/utils/formatTime';

const AlertHeader = ({ alert }) => (
  <Card
    sx={{
      border: 'none',
      borderRadius: 0,
      borderBottom: '1px solid #E5E5E5',
    }}
  >
    <Typography variant="h6">Caméra {alert.cameraId}</Typography>
    <Typography variant="caption">Retrouvez ici tous les détails du magasin</Typography>
    <Stack direction="row" spacing={1} alignItems="center" mt={3}>
      <Box component="img" src="/assets/alerts/desktop-list/store.svg" sx={{ width: 20, height: 20 }} />
      <Typography sx={{ mt: 2 }} variant="body2">{`${alert.metadata.store.name}`}</Typography>
    </Stack>
    <Stack direction="row" spacing={1} alignItems="center" mt={1}>
      <Box component="img" src="/assets/alert/calendar.svg" sx={{ width: 20, height: 20 }} />
      <Typography variant="body2" sx={{ color: '##2E2E2E', display: 'flex', alignItems: 'center' }}>
        {fAlertDate(alert.occurredAt)}
      </Typography>
    </Stack>
  </Card>
);

export default AlertHeader;
