import { Box, SwipeableDrawer, styled, Dialog, Slide } from '@mui/material';

import React from 'react';
import useWebview from '../../../../hooks/useWebview';
import useResponsive from '../../../../hooks/useResponsive';
import DrawerContent from './DrawerContent';

const CustomDrawer = styled(Dialog)(({ isMobile }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-end',
  },
  '& .MuiPaper-root': isMobile && {
    margin: 0,
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AlertDetail = ({ alertId, onClose, refetchList }) => {
  const isMobile = useResponsive('down', 'md');
  const { statusBarHeight } = useWebview();

  const renderContent = <DrawerContent refetchList={refetchList} alertId={alertId} onClose={onClose} open />;

  if (isMobile)
    return (
      <SwipeableDrawer
        open
        disableSwipeToOpen
        anchor="bottom"
        onClose={onClose}
        PaperProps={{ sx: { borderTopLeftRadius: 32, borderTopRightRadius: 32 } }}
      >
        <Box sx={{ height: `calc(95vh - ${statusBarHeight}px)` }}>{renderContent}</Box>
      </SwipeableDrawer>
    );

  return (
    <CustomDrawer maxWidth="sm" fullWidth TransitionComponent={Transition} open>
      <Box>{renderContent}</Box>
    </CustomDrawer>
  );
};

export default AlertDetail;
