import { Box, Typography } from '@mui/material';
import React from 'react';
import useWebview from '../../../../hooks/useWebview';
import useAuth from '../../../../hooks/useAuth';
import DeviceSubscription from './DeviceSubscription';
import DownloadAppButton from './DownloadAppButton';

const NavbarSubscription = () => {
  const { isEmbedded } = useWebview();
  const notificationsUnsupported = !window.Notification || isEmbedded;
  const { stores } = useAuth();
  const hasStore = Boolean(stores.length);

  if (isEmbedded) return null;

  return (
    <Box p={2}>
      {!hasStore && (
        <Typography variant="caption" align="center">
          Vous n'avez accès à aucun magasin
        </Typography>
      )}
      {!notificationsUnsupported && <DeviceSubscription />}
      {notificationsUnsupported && <DownloadAppButton />}
    </Box>
  );
};

export default NavbarSubscription;
