import { MenuItem, Select, Stack, Typography } from '@mui/material';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import useAuth from 'src/hooks/useAuth';
import useQueryParams from 'src/hooks/useQueryParams';
import { isNull } from 'lodash';

const StoreFilter = () => {
  const { stores } = useAuth();
  const { ACTIONS_HANDLERS, filtersState } = useAlertsFilters();
  const { changeParameterValue, removeQueryParameters } = useQueryParams();
  const currentStoreId = filtersState.advancedFilters.storeId;

  const DEFAULT_STORE = {
    name: 'Tous les magasins',
    id: 'all',
  };

  const formattedStores = stores.map((store) => ({
    name: store.name,
    id: store.id,
  }));

  const STORES_LIST_OPTIONS = [DEFAULT_STORE, ...formattedStores];

  const handleStoreSelection = (selectedStoreId) => {
    const existingSelectedStoreId = !!currentStoreId;
    const isDifferentStoreId = selectedStoreId !== existingSelectedStoreId;

    if (selectedStoreId && isDifferentStoreId) {
      changeParameterValue('storeId', selectedStoreId);
    }
    if (!selectedStoreId && isDifferentStoreId) {
      removeQueryParameters('storeId');
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Magasin</Typography>
      <Select
        fullWidth
        onChange={(event) => {
          const selectedStoreId = event.target.value === DEFAULT_STORE.id ? null : event.target.value;
          handleStoreSelection(selectedStoreId);
          ACTIONS_HANDLERS.setStoreId(selectedStoreId);
        }}
        value={isNull(currentStoreId) ? DEFAULT_STORE.id : currentStoreId}
        select={{ native: true }}
        sx={{
          zIndex: 1000,
        }}
      >
        {STORES_LIST_OPTIONS.map((store) => (
          <MenuItem
            key={store.id}
            value={store.id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                flex: 1,
              }}
            >
              {store.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default StoreFilter;
