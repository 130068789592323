import { useState, useEffect, useRef, useCallback } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import { isBoolean } from 'lodash';
import { useNavigate } from 'react-router';
import { fAlertDate } from 'src/utils/formatTime';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { LIST_ALERTS } from 'src/graphql/alerts/queries';
import InfiniteScroll from 'react-infinite-scroll-component';
import useSafeQuery from 'src/services/apollo-client/wrappers/useSafeQuery';
import EmptyContent from 'src/components/EmptyContent';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import useResponsive from 'src/hooks/useResponsive';
import useQueryParams from 'src/hooks/useQueryParams';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import { CREATE_ALERT_SEARCH_HISTORY } from 'src/graphql/alertSearchHistories/mutations';
import AlertLoading from './AlertLoading';

const InfiniteScrollWrapper = ({ withDaySeparator, children, alerts, hasMore, fetchMoreAlerts }) => {
  if (!withDaySeparator) return children;
  return (
    <InfiniteScroll
      dataLength={alerts.length}
      next={fetchMoreAlerts}
      hasMore={hasMore}
      loader={<AlertLoading />}
      scrollThreshold={0.8}
    >
      {children}
    </InfiniteScroll>
  );
};

const AlertsList = ({
  AlertItemComponent,
  pagination,
  storeId,
  emptyContent,
  favorite,
  resetSearch,
  withDaySeparator,
}) => {
  const { alertId, removeQueryParameters } = useQueryParams();
  const { filtersState, ACTIONS_HANDLERS } = useAlertsFilters();
  const [lastAlertIdSeen, setLastAlertIdSeen] = useState();
  const shouldFilterFavorite = favorite || filtersState.favorite;
  const isMobile = useResponsive('down', 'sm');
  const navigate = useNavigate();
  const INITIAL_PAGE = 1;
  const [page, setPage] = useState(INITIAL_PAGE);
  const alertRefs = useRef({});

  const [createAlertSearchHistory] = useSafeMutation(CREATE_ALERT_SEARCH_HISTORY, {
    variables: { input: { search: filtersState.search } },
    refetchQueries: ['listAlertSearchHistories'],
  });

  const handleSelectAlert = (alertId) => {
    navigate(PATH_DASHBOARD.alerts.view(alertId), {
      state: { storeId },
    });
    if (filtersState.search.length > 0) createAlertSearchHistory();
  };

  const {
    data: alertsList,
    loading,
    fetchMore,
  } = useSafeQuery(
    LIST_ALERTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        pagination: {
          ...pagination,
          page: INITIAL_PAGE,
        },
        storeId,
        filters: {
          ...(isBoolean(filtersState.falsePositive) && { falsePositive: filtersState.falsePositive }),
          ...(isBoolean(filtersState.qualified) && { qualified: filtersState.qualified }),
          ...(filtersState.search.length > 0 && { search: filtersState.search }),
          ...(shouldFilterFavorite && { favorite: shouldFilterFavorite }),
          dates: {
            ...(filtersState.advancedFilters.dates.start && { start: filtersState.advancedFilters.dates.start }),
            ...(filtersState.advancedFilters.dates.end && { end: filtersState.advancedFilters.dates.end }),
          },
          estimatedValues: {
            ...(filtersState.advancedFilters.estimatedValues.minimum && {
              minimum: filtersState.advancedFilters.estimatedValues.minimum,
            }),
            ...(filtersState.advancedFilters.estimatedValues.maximum && {
              maximum: filtersState.advancedFilters.estimatedValues.maximum,
            }),
          },
          cameraIds: filtersState.advancedFilters.cameraIds,
          tags: {
            ...(filtersState.advancedFilters.tags.locationId && {
              locationId: filtersState.advancedFilters.tags.locationId,
            }),
            ...(filtersState.advancedFilters.tags.thiefProfileId && {
              thiefProfileId: filtersState.advancedFilters.tags.thiefProfileId,
            }),
            ...(filtersState.advancedFilters.tags.theftTypeId && {
              theftTypeId: filtersState.advancedFilters.tags.theftTypeId,
            }),
          },
        },
      },
    },
    {
      silentError: true,
    }
  );

  const fetchMoreAlerts = useCallback(() => {
    const nextPage = page + 1;

    fetchMore({
      variables: {
        pagination: {
          ...pagination,
          page: nextPage,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        const newAlerts = fetchMoreResult.listAlerts.alerts;
        const mergedAlerts = [...prev.listAlerts.alerts, ...newAlerts];

        return {
          listAlerts: {
            ...prev.listAlerts,
            alerts: mergedAlerts,
            hasMore: fetchMoreResult.listAlerts.hasMore,
          },
        };
      },
    }).then(() => {
      setPage(nextPage);
    });
  }, [fetchMore, page, pagination]);

  const scrollToAlert = (alertId) => {
    const offset = 40;
    if (alertRefs.current[alertId]) {
      const topPos = alertRefs.current[alertId].getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: topPos, behavior: 'smooth' });
      removeQueryParameters('alertId');
    }
  };

  useEffect(() => {
    if (alertId) {
      const checkAndScrollToAlert = () => {
        const alertFound = alertsList?.listAlerts.alerts.some((alert) => alert.id === alertId);
        if (alertFound) {
          setLastAlertIdSeen(alertId);
          scrollToAlert(alertId);
        } else if (alertsList?.listAlerts.hasMore) {
          fetchMoreAlerts();
        }
      };

      checkAndScrollToAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertId, alertsList, fetchMoreAlerts]);

  if (loading) {
    return <AlertLoading />;
  }

  const { hasMore, alerts } = alertsList?.listAlerts;
  const hasAlerts = alerts.length > 0;

  if (filtersState.isSearchLayoutActive && !filtersState.hasTyped) {
    return null;
  }

  if (!hasAlerts) {
    const { isSearchLayoutActive } = filtersState;
    const buttonLabel = isSearchLayoutActive ? 'Réinitialiser la recherche' : 'Réinitialiser les filtres';
    const displayResetAction = emptyContent.withResetAction;

    return (
      <EmptyContent title={emptyContent.title} description={emptyContent.description}>
        {displayResetAction && (
          <Button
            variant="text"
            sx={{
              color: 'primary.main',
              fontWeight: 'normal',
              fontSize: '0.75rem',
              textTransform: 'none',
              minWidth: 'fit-content',
              px: 0,
            }}
            onClick={() => {
              if (isSearchLayoutActive) resetSearch();
              else {
                ACTIONS_HANDLERS.resetAllFilters();
              }
            }}
          >
            {buttonLabel}
          </Button>
        )}
      </EmptyContent>
    );
  }

  const preparedAlerts = alerts.reduce((accumulator, currentAlert, index) => {
    const currentAlertDayDate = fAlertDate(currentAlert.occurredAt, false);
    const previousAlert = alerts[index - 1];
    const previousAlertDayDate = fAlertDate(previousAlert?.occurredAt, false);
    const isCurrentAlertDifferentDay = currentAlertDayDate !== previousAlertDayDate;
    const isFirstAlert = index === 0;

    const shouldPushDateTitle = (isFirstAlert || isCurrentAlertDifferentDay) && withDaySeparator;

    if (shouldPushDateTitle) {
      accumulator.push(
        <Typography
          key={`date-${currentAlertDayDate}-${index}`}
          variant="subtitle2"
          sx={{
            mt: 2,
            py: 1,
            mr: 'auto',
            borderRadius: 16,
            width: 'fit-content',
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {currentAlertDayDate}
        </Typography>
      );
    }

    accumulator.push(
      <div
        ref={(el) => {
          alertRefs.current[currentAlert.id] = el;
        }}
        key={`alert-${currentAlert.id}`}
      >
        <AlertItemComponent
          withDaySeparator={withDaySeparator}
          alert={currentAlert}
          onSelectAlert={() => handleSelectAlert(currentAlert.id)}
          isLastViewed={lastAlertIdSeen === currentAlert.id}
        />
      </div>
    );

    return accumulator;
  }, []);

  return (
    <InfiniteScrollWrapper
      withDaySeparator={withDaySeparator}
      alerts={alerts}
      hasMore={hasMore}
      fetchMoreAlerts={fetchMoreAlerts}
    >
      <Grid
        container
        spacing={2}
        rowGap={2}
        sx={{
          width: '100% !important',
          marginLeft: '0 !important',
        }}
      >
        {preparedAlerts.map((alert, index) => {
          const isFirstAlertRow = index % 3 === 0;
          return (
            <Grid
              item
              xs={12}
              sm={4}
              key={`alert-${index}`}
              sx={{
                ...(isMobile && { padding: '0 !important' }),
                ...(isFirstAlertRow && {
                  paddingLeft: '0 !important',
                }),
              }}
            >
              {alert}
            </Grid>
          );
        })}
      </Grid>
    </InfiniteScrollWrapper>
  );
};

export default AlertsList;
