import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Stack, styled, Typography, Alert as MUIAlert } from '@mui/material';
import { useSafeLazyQuery } from '../../../../services/apollo-client/wrappers';
import { LIST_ALERTS } from '../../../../graphql/alerts/queries';
import { fDate } from '../../../../utils/formatTime';
import AlertDetail from '../detail';
import useTable from '../../../../hooks/useTable';
import useQueryParams from '../../../../hooks/useQueryParams';
import AlertSearchBar from './AlertSearchBar';
import EmptyContent from '../../../../components/EmptyContent';
import useAuth from '../../../../hooks/useAuth';
import Alert from '../Alert';
import useWebview from '../../../../hooks/useWebview';

export default function AlertsList({ storeId }) {
  const { isReady } = useAuth();
  const { isEmbedded, statusBarHeight } = useWebview();

  const [alerts, setAlerts] = useState([]);
  const { page, setPage } = useTable();
  const { alert, filterName, filterValue, search: searchParam, changeParameterValue } = useQueryParams();
  const [searchFilterValue, setSearchFilterValue] = useState(searchParam);
  const onSearchSubmit = (searchValue) => {
    setSearchFilterValue(() => {
      changeParameterValue('search', searchValue);
      return searchValue;
    });
  };
  const [listAlerts, { data, refetch: refetchList }] = useSafeLazyQuery(LIST_ALERTS);

  const StickyDate = styled(Box)(({ theme }) => ({
    top: `calc(64px + 64px + ${Number(statusBarHeight)}px)`,
    position: 'sticky',
    zIndex: 99,
    alignSelf: 'center',
    width: 150,
    padding: theme.spacing(0.5, 1),
    background: 'rgb(46, 46, 46)',
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 3,
  }));

  const StickySearchbarContainer = styled(Box)({
    top: 64 + Number(statusBarHeight),
    zIndex: 100,
    position: 'sticky',
  });

  useEffect(() => {
    listAlerts({
      onCompleted: ({ listAlerts }) => {
        setAlerts(listAlerts.alerts);
        setPage(2);
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      variables: {
        pagination: {
          rowsPerPage: 10,
          page: 1,
        },
        filters: {
          search: searchFilterValue,
        },
        storeId,
        [filterName]: filterValue,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAlerts, storeId, filterName, filterValue, searchFilterValue]);

  const alertsByDate = alerts.reduce((acc, alert) => {
    if (acc[fDate(alert.occurredAt)]) acc[fDate(alert.occurredAt)] = [...acc[fDate(alert.occurredAt)], alert];
    else acc[fDate(alert.occurredAt)] = [alert];
    return acc;
  }, {});

  const hasMore = data?.listAlerts?.hasMore;
  const handleSelectAlert = (alertId) => changeParameterValue('alert', alertId, { replace: true });

  const handleLoadMore = async () => {
    try {
      await listAlerts({
        onCompleted: ({ listAlerts }) => {
          setAlerts((a) => [...a, ...listAlerts.alerts]);
          setPage((p) => p + 1);
        },
        variables: {
          pagination: {
            rowsPerPage: 10,
            page,
          },
          filters: {
            search: searchFilterValue,
          },
          storeId,
          [filterName]: filterValue,
        },
      });
    } catch {
      // Nothing to do
    }
  };

  return (
    <Container maxWidth="xs" disableGutters>
      <AlertDetail
        alertId={alert}
        refetchList={refetchList}
        onClose={() => changeParameterValue('alert', null, { replace: true })}
      />
      <Stack spacing={2}>
        <StickySearchbarContainer>
          <AlertSearchBar storeId={storeId} onSearchSubmit={onSearchSubmit} searchFilterValue={searchFilterValue} />
        </StickySearchbarContainer>
        {!isReady && !isEmbedded && (
          <>
            <MUIAlert severity="warning">
              Il semblerait que vous ne soyez pas tout à fait prêt à recevoir les notifications. Autoriser les
              notifications sur l'appareil.
            </MUIAlert>
          </>
        )}
        {
          <>
            {!Object.keys(alertsByDate).length && <EmptyContent />}
            {Object.keys(alertsByDate).map((date) => {
              const dateAlerts = alertsByDate[date];
              return (
                <>
                  <StickyDate>
                    <Typography noWrap align="center" sx={{ fontSize: '10px' }}>
                      {date}
                    </Typography>
                  </StickyDate>
                  {dateAlerts.map((alert) => (
                    <Alert key={alert.id} onSelectAlert={() => handleSelectAlert(alert.id)} alert={alert} />
                  ))}
                </>
              );
            })}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              py={2}
              sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}` }}
            >
              {hasMore && (
                <Button variant="contained" size="small" onClick={handleLoadMore}>
                  Voir plus de résultats ({data?.listAlerts?.total - alerts.length})
                </Button>
              )}
              {!hasMore && <Typography variant="caption">Tous les résultats sont affichés</Typography>}
            </Stack>
          </>
        }
      </Stack>
    </Container>
  );
}
