import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { LIST_USER_ALERT_ACTIONS } from 'src/graphql/userAlertActions/queries';
import useSafeQuery from 'src/services/apollo-client/wrappers/useSafeQuery';
import MyAvatar from 'src/components/MyAvatar';
import { fToNow } from 'src/utils/formatTime';
import ALERT_ACTION_TYPES from 'src/constants/alertActionTypes';
import { ALL_ALERT_TYPES_LABELS } from 'src/constants/alertTypes';
import { TAG_TYPES, TAG_TYPES_LABEL } from 'src/constants/tagTypes';

const getAssociatedActionDescription = ({ alertAction }) => {
  const { actionType, user, metadata } = alertAction;
  const { firstName, lastName } = user;
  const lastNameFirstLetter = lastName.charAt(0).toUpperCase();
  const userName = <strong>{`${firstName}.${lastNameFirstLetter}`}</strong>;

  switch (actionType) {
    case ALERT_ACTION_TYPES.WATCHED:
      return (
        <Typography variant="body">
          {userName} a regardé la <strong>vidéo</strong>
        </Typography>
      );
    case ALERT_ACTION_TYPES.CONFIRMED:
      return (
        <Typography variant="body">
          {userName} a qualifié l'alerte comme étant un <strong>Vol confirmé</strong>
        </Typography>
      );
    case ALERT_ACTION_TYPES.FALSE_ALERT:
      return (
        <Typography variant="body">
          {userName} a qualifié l'alerte comme étant une <strong>Fausse alerte</strong>
        </Typography>
      );
    case ALERT_ACTION_TYPES.TAGGED: {
      const loweredTagType = TAG_TYPES_LABEL[metadata.tagTypeName].toLowerCase();
      const isMoneyTag = metadata.tagTypeName === TAG_TYPES.ESTIMATED_VALUE;
      return (
        <Typography variant="body">
          {userName} a ajouté le tag <strong>{_.capitalize(loweredTagType)}</strong> avec la valeur{' '}
          <strong>{isMoneyTag ? `${metadata.value} €` : `${_.capitalize(metadata.value)}`}</strong>
        </Typography>
      );
    }
    case ALERT_ACTION_TYPES.UNTAGGED: {
      const loweredTagType = TAG_TYPES_LABEL[metadata.tagTypeName].toLowerCase();
      const isMoneyTag = metadata.tagTypeName === TAG_TYPES.ESTIMATED_VALUE;
      return (
        <Typography variant="body">
          {userName} a retiré le tag <strong>{loweredTagType}</strong> avec la valeur{' '}
          <strong>{isMoneyTag ? `${metadata.value} €` : `${metadata.value}`}</strong>
        </Typography>
      );
    }
    case ALERT_ACTION_TYPES.TYPED:
      return (
        <Typography variant="body">
          {userName} a identifié le type de l'alerte avec{' '}
          <strong>{_.capitalize(ALL_ALERT_TYPES_LABELS[metadata.value])}</strong>
        </Typography>
      );
    default:
      return <Typography variant="body">Action non reconnue</Typography>;
  }
};

const AlertActionHistory = ({ alert }) => {
  const { data } = useSafeQuery(LIST_USER_ALERT_ACTIONS, {
    variables: {
      alertId: alert.id,
    },
  });
  const alertActionsHistory = data?.listUserAlertActions;
  const hasAlertActionsHistory = alertActionsHistory && alertActionsHistory.length > 0;
  if (!hasAlertActionsHistory) return null;

  return (
    <Stack
      spacing={2}
      mt={2}
      sx={{
        py: 4,
        borderTop: '1px solid #F8F8F8',
      }}
    >
      <Typography variant="h6">Historique</Typography>
      {alertActionsHistory.map((alertAction) => (
        <Stack spacing={2} direction="row" alignItems="center" key={alertAction.id}>
          <MyAvatar
            sx={{
              width: 50,
              height: 50,
            }}
            user={{
              firstName: alertAction.user.firstName,
              lastName: alertAction.user.lastName,
            }}
          />
          <Stack
            sx={{
              flex: 1,
            }}
          >
            {getAssociatedActionDescription({ alertAction })}
            <Typography variant="body">{fToNow(alertAction.createdAt)}</Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default AlertActionHistory;
