import { gql } from '@apollo/client';

const LIST_TAGS_TYPES = gql`
  query listTagsTypes {
    listTagsTypes {
      id
      name
    }
  }
`;

const LIST_TAGS = gql`
  query listTags($storeIds: [ID!], $tagsTypeId: ID!, $pagination: Pagination!) {
    listTags(storeIds: $storeIds, tagsTypeId: $tagsTypeId, pagination: $pagination) {
      total
      hasMore
      tags {
        id
        label
      }
    }
  }
`;

export { LIST_TAGS_TYPES, LIST_TAGS };
