import React from 'react';
import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import { useSafeQuery } from 'src/services/apollo-client/wrappers';
import { LIST_ALERTS } from 'src/graphql/alerts/queries';
import useQueryParams from 'src/hooks/useQueryParams';
import useAlertsFilters from 'src/hooks/useAlertsFilters';

const CameraFilter = () => {
  const { storeId } = useQueryParams();
  const { ACTIONS_HANDLERS, filtersState } = useAlertsFilters();
  const hasSelectedOption = filtersState.advancedFilters.cameraIds.length > 0;

  const { data } = useSafeQuery(LIST_ALERTS, {
    fetchPolicy: 'network-only',
    variables: {
      ...(storeId && { storeId }),
      pagination: {
        rowsPerPage: 5000,
        page: 1,
      },
    },
  });

  const alerts = data?.listAlerts?.alerts || [];

  const cameras = alerts.reduce((acc, alert) => {
    const currentCamera = alert.cameraId;
    const isCameraAlreadyAdded = acc.includes(currentCamera);
    const shouldAddCamera = !isCameraAlreadyAdded;
    if (shouldAddCamera) {
      acc.push(alert.cameraId);
    }
    return acc;
  }, []);

  const handleSelectAll = () => {
    ACTIONS_HANDLERS.setCameraIds(cameras);
  };

  const handleDeselectAll = () => {
    ACTIONS_HANDLERS.setCameraIds([]);
  };

  const canDisplayCamerasOptions = cameras.length > 0;

  if (!canDisplayCamerasOptions) return null;
  const selectedCamerasCount = filtersState.advancedFilters.cameraIds.length;
  const hasSelectedCameras = selectedCamerasCount > 0;

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{`Caméras ${hasSelectedCameras ? ` (${selectedCamerasCount})` : ''}`}</Typography>
        <Button
          variant="text"
          onClick={hasSelectedOption ? handleDeselectAll : handleSelectAll}
          sx={{
            color: 'primary.main',
            fontWeight: 'normal',
            fontSize: '0.75rem',
            textTransform: 'none',
            minWidth: 'fit-content',
            px: 0,
          }}
        >
          {hasSelectedOption ? 'Tout désélectionner' : 'Tout sélectionner'}
        </Button>
      </Stack>
      <Autocomplete
        multiple
        noOptionsText="Pas de caméras"
        options={cameras}
        getOptionLabel={(option) => option.toUpperCase()}
        value={filtersState.advancedFilters.cameraIds}
        onChange={(event, value) => {
          ACTIONS_HANDLERS.setCameraIds(value);
        }}
        renderInput={(params) => (
          <TextField
            placeholder={hasSelectedCameras ? '' : 'Toutes'}
            {...params}
            sx={{
              '& .MuiChip-root': {
                borderRadius: '4px !important',
                '& > svg': {
                  color: '#3E3E3E',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'text.primary',
                opacity: 1,
                fontWeight: 'normal',
              },
            }}
          />
        )}
      />
    </Stack>
  );
};

export default CameraFilter;
