import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import usePathHistory from 'src/hooks/usePathHistory';
import { IconButton, Box, Toolbar, Stack, Tooltip } from '@mui/material';
import { SET_FAVORITE } from 'src/graphql/alerts/mutations';
import useSafeMutation from 'src/services/apollo-client/wrappers/useSafeMutation';
import Iconify from 'src/components/Iconify';
import { PATH_DASHBOARD } from 'src/routes/paths';

const AlertActionsToolbar = ({ alert, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { goBack, prevPath } = usePathHistory();

  const [isFavorite, setIsFavorite] = useState(alert.isFavorite);

  const handleFavoriteChange = (value) => {
    setIsFavorite(value);
  };

  const handleCloseAlert = () => {
    if (prevPath === PATH_DASHBOARD.home) goBack();
    else goBack(1, { alertId: alert.id });
  };

  const handleShareAlert = () => {
    navigator.clipboard.writeText(alert.videoUrl);
    enqueueSnackbar('Lien copié dans le presse-papier');
  };

  const favoriteIcon = isFavorite ? '/assets/profile/favorite-filled.svg' : '/assets/profile/favorite-outlined.svg';
  const shareIcon = '/assets/alert/share.svg';

  const [setFavorite] = useSafeMutation(SET_FAVORITE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ setFavorite }) => {
      const { isFavorite } = setFavorite;
      const snackbarMessage = isFavorite ? 'Alerte ajoutée aux favoris' : 'Alerte retirée des favoris';
      enqueueSnackbar(snackbarMessage);
      handleFavoriteChange(isFavorite);
    },
  });

  const handleSetFavorite = () => {
    setFavorite({
      variables: {
        alertId: alert.id,
      },
    });
  };

  return (
    <Toolbar
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <IconButton
        onClick={handleCloseAlert}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          padding: 1,
          maxWidth: 'fit-content',
        }}
      >
        {!onClose && (
          <Iconify
            icon="eva:arrow-back-outline"
            sx={{
              color: '#292929',
              height: 26,
              width: 26,
            }}
          />
        )}
      </IconButton>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Tooltip title={alert.isFavorite ? 'Retirer des favoris' : 'Ajouter aux favoris'} arrow>
          <IconButton onClick={handleSetFavorite}>
            <Box
              component="img"
              src={favoriteIcon}
              sx={{
                height: 24,
                width: 24,
              }}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title="Partager l'alerte" arrow>
          <IconButton
            onClick={() => {
              handleShareAlert();
            }}
          >
            <Box
              component="img"
              src={shareIcon}
              sx={{
                height: 24,
                width: 24,
              }}
            />
          </IconButton>
        </Tooltip>

        {onClose && (
          <IconButton
            onClick={onClose}
            sx={{
              color: '#2a2a2a',
            }}
          >
            <Iconify
              icon="eva:close-fill"
              sx={{
                height: 24,
                width: 24,
              }}
            />
          </IconButton>
        )}
      </Stack>
    </Toolbar>
  );
};

export default AlertActionsToolbar;
