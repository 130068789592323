import { useEffect } from 'react';
import * as Yup from 'yup';
import {
  Card,
  Drawer,
  IconButton,
  Stack,
  Typography,
  MenuItem,
  TextField,
  Autocomplete,
  Button,
  Dialog,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import stopPropagation from 'src/utils/stopPropagation';
import Iconify from 'src/components/Iconify';
import useSafeQuery from 'src/services/apollo-client/wrappers/useSafeQuery';
import { LIST_TAGS, LIST_TAGS_TYPES } from 'src/graphql/tags/queries';
import RHFSelect from 'src/components/hook-form/RHFSelect';
import { TAG_TYPES, TAG_TYPES_LABEL } from 'src/constants/tagTypes';
import useSafeLazyQuery from 'src/services/apollo-client/wrappers/useSafeLazyQuery';
import useSafeMutation from 'src/services/apollo-client/wrappers/useSafeMutation';
import { ATTACH_TAGS } from 'src/graphql/alerts/mutations';
import useResponsive from 'src/hooks/useResponsive';
import { useSwipeable } from 'react-swipeable';

const TagsDrawer = ({ alert, onTagsDrawerClose }) => {
  const isDesktop = useResponsive('up', 'sm');
  const WrapperDependingOnScreenSize = isDesktop ? Dialog : Drawer;

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => onTagsDrawerClose(),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  const { data } = useSafeQuery(LIST_TAGS_TYPES, {
    variables: {
      pagination: {
        page: 1,
        rowsPerPage: 100,
      },
    },
  });

  const [listTags, { data: tagsData }] = useSafeLazyQuery(LIST_TAGS, {
    variables: {
      storeIds: [alert.metadata.store.id],
    },
  });

  const [attachTags] = useSafeMutation(ATTACH_TAGS, {
    refetchQueries: ['getAlert', 'listTags', 'listUserAlertActions'],
    networkPolicy: 'network-only',
    onCompleted: () => {
      onTagsDrawerClose();
    },
  });

  const handleAttachTags = async (formValues) => {
    try {
      await attachTags({
        variables: {
          alertId: alert.id,
          tagsTypeId: formValues.tagType,
          tags: [{ label: formValues.tagValue }],
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        tagType: Yup.string().required('Tag type is required'),
        tagValue: Yup.string().required('Tag value is required'),
      })
    ),
    defaultValues: {
      tagType: '',
      tagValue: '',
    },
  });
  const { watch, setValue } = methods;
  const formValues = watch();

  const hasSelectedTagType = formValues.tagType;
  const canSubmit = Object.values(formValues).every((value) => !!value);
  const tagsFilteredOnTagType = tagsData?.listTags?.tags;
  const hasTagsData = tagsFilteredOnTagType && tagsFilteredOnTagType.length >= 0;

  const currentAlertTags = alert.tags;

  useEffect(() => {
    if (formValues.tagType) {
      listTags({
        variables: {
          storeId: alert.storeId,
          tagsTypeId: formValues.tagType,
          pagination: {
            page: 1,
            rowsPerPage: 1000,
          },
        },
      });
    }
  }, [alert.storeId, formValues.tagType, listTags]);

  const tagsTypes = data?.listTagsTypes;
  if (!tagsTypes) return null;
  const currentTagType = tagsTypes.find((tag) => tag.id === formValues.tagType)?.name;

  return (
    <WrapperDependingOnScreenSize
      anchor="bottom"
      maxWidth="md"
      fullWidth
      ModalProps={{ keepMounted: true }}
      open
      onClose={stopPropagation(onTagsDrawerClose)}
      sx={{
        '.MuiDrawer-paper': {
          borderRadius: (theme) => theme.spacing(2, 2, 0, 0),
        },
      }}
    >
      <Card
        sx={{
          p: (theme) => theme.spacing(4, 2, 20, 2),
        }}
        {...swipeHandlers}
      >
        <IconButton
          edge="end"
          color="inherit"
          aria-label="close"
          sx={{
            position: 'absolute',
            left: 16,
          }}
          onClick={stopPropagation(onTagsDrawerClose)}
        >
          <Iconify
            icon="eva:close-fill"
            sx={{
              height: 26,
              width: 26,
            }}
          />
        </IconButton>
        <Stack alignItems="center" mb={4}>
          <Typography variant="h3">Ajouter un tag</Typography>
        </Stack>

        <FormProvider {...methods}>
          <Stack spacing={2}>
            <RHFSelect name="tagType" label="Type" select={{ native: false }}>
              {tagsTypes.map((option) => {
                const hasCurrentTagType = currentAlertTags.some((tag) => tag.tagType.id === option.id);

                return (
                  <MenuItem key={option.id} value={option.id}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          flex: 1,
                        }}
                      >
                        {TAG_TYPES_LABEL[option.name]}
                      </Typography>
                      {hasCurrentTagType && (
                        <Iconify
                          icon={'eva:checkmark-fill'}
                          sx={{
                            fontSize: 20,
                            color: 'primary.main',
                          }}
                        />
                      )}
                    </Stack>
                  </MenuItem>
                );
              })}
            </RHFSelect>

            {hasSelectedTagType && hasTagsData && (
              <Autocomplete
                freeSolo
                options={tagsFilteredOnTagType.map((tag) => tag.label)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Valeur"
                    variant="outlined"
                    type={currentTagType === TAG_TYPES.ESTIMATED_VALUE ? 'tel' : 'text'}
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  setValue('tagValue', newInputValue);
                }}
                onChange={(event, newValue) => {
                  setValue('tagValue', newValue);
                }}
              />
            )}
            <Button
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              disabled={!canSubmit}
              onClick={() => {
                handleAttachTags(formValues);
              }}
            >
              Ajouter
            </Button>
          </Stack>
        </FormProvider>
      </Card>
    </WrapperDependingOnScreenSize>
  );
};

export default TagsDrawer;
