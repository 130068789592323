import { forwardRef } from 'react';
import { useSnackbar } from 'notistack';
import { Badge, Box, Chip, OutlinedInput, Stack, Grid, Tooltip } from '@mui/material';
import Iconify from 'src/components/Iconify';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import useAuth from 'src/hooks/useAuth';
import useResponsive from 'src/hooks/useResponsive';

const AlertChip = ({ chip, index }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { filtersState } = useAlertsFilters();
  const advancedFiltersCounter = filtersState.advancedFilters.counter;
  const isAdvancedFilter = index === 0;
  const hasAdvancedFilters = advancedFiltersCounter > 0;
  const shouldDisplayCounter = isAdvancedFilter && hasAdvancedFilters;

  const ChipContent = (
    <Chip
      onClick={() => {
        chip.onClick();
        if (chip.snackbarMessage) enqueueSnackbar(chip.snackbarMessage);
      }}
      sx={{
        ...(chip.icon && {
          minWidth: 40,
        }),
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        ...(shouldDisplayCounter && {
          border: '1px solid #000000',
        }),
        ...(chip.isActive && {
          backgroundColor: `#292929 !important`,
          color: 'white',
        }),
      }}
      key={chip.label || chip.icon}
      variant="outlined"
      label={chip.label || <Box component="img" src={chip.icon} />}
    />
  );

  if (shouldDisplayCounter) {
    return (
      <Badge
        badgeContent={advancedFiltersCounter}
        sx={{
          display: 'block',
          '& .MuiBadge-standard': {
            background: '#000000',
            color: '#FFFFFF',
          },
        }}
      >
        {ChipContent}
      </Badge>
    );
  }

  return ChipContent;
};

const AlertsFilters = forwardRef(({ resetSearch }, ref) => {
  const { user } = useAuth();
  const { ALERTS_FILTERS, ACTIONS_HANDLERS, filtersState } = useAlertsFilters();
  const isDesktop = useResponsive('up', 'sm');

  return (
    <Stack mb={4} spacing={1.5}>
      <OutlinedInput
        inputRef={ref}
        type="text"
        placeholder={filtersState.isSearchLayoutActive ? 'Rechercher (magasin, lieu, montant ...) ' : 'Rechercher'}
        value={filtersState.search}
        onChange={(event) => {
          const firstCharacter = event.target.value.charAt(0);
          const isFirstCharacterSpace = firstCharacter === ' ';
          if (isFirstCharacterSpace) return;
          ACTIONS_HANDLERS.setSearch(event.target.value);
        }}
        onFocus={() => {
          ACTIONS_HANDLERS.setIsSearchFocused(true);
        }}
        sx={{
          width: '100%',
          background: 'red',
          borderRadius: '50px !important',
          backgroundColor: '#F5F5F5',
          '& .MuiOutlinedInput-input': {
            py: 1.5,
          },
        }}
        startAdornment={
          <Iconify
            icon={filtersState.isSearchLayoutActive ? 'eva:arrow-back-outline' : 'eva:search-fill'}
            sx={{ width: 24, height: 24, mx: (theme) => theme.spacing(1), cursor: 'pointer' }}
            onClick={ACTIONS_HANDLERS.quitSearch}
          />
        }
        endAdornment={
          filtersState.search.length > 0 && (
            <Iconify
              icon={'eva:close-fill'}
              sx={{ width: 24, height: 24, mx: (theme) => theme.spacing(1), cursor: 'pointer' }}
              onClick={() => {
                resetSearch();
              }}
            />
          )
        }
      />
      {!filtersState.isSearchLayoutActive && (
        <Grid container spacing={1}>
          {ALERTS_FILTERS.map((chip, index) => {
            const currentUserAllowed = chip.roles.includes(user.role);
            const shouldDisplay = chip.desktopOnly ? isDesktop : true;

            if (!currentUserAllowed || !shouldDisplay) return null;

            return (
              <Grid item md={4} lg={2} key={chip.label || chip.icon}>
                <Tooltip title={chip.tooltipMessage} arrow>
                  <Box>
                    <AlertChip chip={chip} index={index} />
                  </Box>
                </Tooltip>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Stack>
  );
});

export default AlertsFilters;
