import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useAuth from 'src/hooks/useAuth';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router';
import USER_ROLES from 'src/constants/userRoles';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import Stack from '@mui/material/Stack';
import useNavConfig from '../navbar/NavConfig';

const ActionItem = styled(Box)(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  ...(selected && {
    color: theme.palette.primary.main,
  }),
}));

const MenuAppBar = () => {
  const { user } = useAuth();
  const homeItem = user.role === USER_ROLES.ADMIN ? 'admin' : 'home';
  const [navConfig] = useNavConfig();
  const { ACTIONS_HANDLERS } = useAlertsFilters();
  const baseItem = navConfig.items.find((item) => item.value === homeItem).value;
  const { pathname } = useLocation();
  const currentRoute = pathname ? pathname.split('/')[1] : baseItem;
  const [selectedItem, setSelectedItem] = useState(currentRoute);
  const navigate = useNavigate();

  const handleMenuItemChange = ({ value, path }) => {
    setSelectedItem(value);
    navigate(path);
    ACTIONS_HANDLERS.resetAllFilters();
  };

  useEffect(() => {
    setSelectedItem(currentRoute);
  }, [currentRoute]);

  return (
    <Stack
      direction="row"
      spacing={0}
      p={1}
      alignItems="space-around"
      sx={{
        boxShadow: (theme) => theme.shadows[6],
      }}
    >
      {navConfig.items.map((item) => {
        if (item.roles && !item.roles.includes(user.role)) return null;
        const active = selectedItem === item.value;
        return (
          <ActionItem
            sx={{ flex: 1, color: active ? 'primary.main' : 'text.secondary' }}
            key={item.value}
            selected={active}
            onClick={() => {
              handleMenuItemChange(item);
            }}
          >
            {active ? <item.Icon /> : <item.IconOutlined />}
            <Typography variant="caption">{item.title}</Typography>
          </ActionItem>
        );
      })}
    </Stack>
  );
};
export default MenuAppBar;
