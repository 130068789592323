import React, { useEffect } from 'react';
import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { LIST_TAGS, LIST_TAGS_TYPES } from 'src/graphql/tags/queries';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import useAuth from 'src/hooks/useAuth';
import { useLazyQuery, useQuery } from '@apollo/client';
import { isNull } from 'lodash';

const TagFilter = ({ title, onChange, value, tagType }) => {
  const { stores } = useAuth();
  const { filtersState } = useAlertsFilters();
  const { storeId: currentStoreId } = filtersState.advancedFilters;

  const [getListTags, { data: tagsData }] = useLazyQuery(LIST_TAGS);

  const { data: tagsTypesData } = useQuery(LIST_TAGS_TYPES, {
    variables: { pagination: { page: 1, rowsPerPage: 100 } },
  });

  useEffect(() => {
    const currentTagType = tagsTypesData?.listTagsTypes?.find((type) => type.name === tagType);
    if (currentTagType) {
      const storeIds = isNull(currentStoreId) ? stores.map((store) => store.id) : [currentStoreId];
      getListTags({
        variables: {
          storeIds,
          tagsTypeId: currentTagType.id,
          pagination: { page: 1, rowsPerPage: 5000 },
        },
      });
    }
  }, [currentStoreId, getListTags, stores, tagType, tagsTypesData]);

  const tags = tagsData?.listTags?.tags || [];
  const canDisplayTagsOptions = tags.length > 0;

  if (!canDisplayTagsOptions) return null;

  return (
    <Stack spacing={1}>
      <Typography variant="h6">{title}</Typography>
      <Select
        fullWidth
        placeholder="Sélectionner un tag"
        displayEmpty
        onChange={(event) => {
          onChange(event.target.value);
        }}
        value={value}
        renderValue={(selected) => {
          if (!selected) {
            return 'Tous';
          }
          const selectedTag = tags.find((tag) => tag.id === selected);
          return selectedTag ? selectedTag.label : selected;
        }}
        select={{ native: true }}
        sx={{
          zIndex: 1000,
        }}
      >
        {canDisplayTagsOptions &&
          tags.map((tag) => (
            <MenuItem
              key={tag.id}
              value={tag.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  flex: 1,
                }}
              >
                {tag.label}
              </Typography>
            </MenuItem>
          ))}
      </Select>
    </Stack>
  );
};

export default TagFilter;
