import { gql } from '@apollo/client';

const LIST_USER_ALERT_ACTIONS = gql`
  query listUserAlertActions($alertId: ID!) {
    listUserAlertActions(alertId: $alertId) {
      id
      user {
        id
        firstName
        lastName
      }
      alert {
        id
      }
      actionType
      metadata
      createdAt
      updatedAt
    }
  }
`;

export { LIST_USER_ALERT_ACTIONS };
