import { useEffect } from 'react';
import { Box, Button, Card, Divider, Drawer, IconButton, Stack, Typography, Dialog } from '@mui/material';
import stopPropagation from 'src/utils/stopPropagation';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import useQueryParams from 'src//hooks/useQueryParams';
import useResponsive from 'src/hooks/useResponsive';

import DatesFilters from './DatesFilters';
import StoreFilter from './StoreFilter';
import CameraFilter from './CameraFilter';
import TagsFilters from './tags/TagsFilters';
import EstimatedValuesFilters from './EstimatedValuesFilters';

const AdvancedFiltersDrawer = () => {
  const { ACTIONS_HANDLERS, filtersState } = useAlertsFilters();
  const { removeQueryParameters, storeId } = useQueryParams();
  const isDesktop = useResponsive('up', 'sm');

  const AdvancedFiltersWrapper = isDesktop ? Dialog : Drawer;

  useEffect(() => {
    if (storeId) {
      ACTIONS_HANDLERS.setStoreId(storeId);
    }
  }, [ACTIONS_HANDLERS, storeId]);

  const onAdvancedFiltersDrawerClose = () => {
    ACTIONS_HANDLERS.blankAdvancedFilters();
    ACTIONS_HANDLERS.setIsDrawerOpen(false);
  };

  const onSavedFiltersDrawerClose = () => {
    ACTIONS_HANDLERS.setIsDrawerOpen(false);
  };

  const selectedFiltersCount = filtersState.advancedFilters.counter;

  return (
    <AdvancedFiltersWrapper
      anchor="bottom"
      open
      maxWidth="sm"
      fullWidth
      onClose={onAdvancedFiltersDrawerClose}
      sx={{
        zIndex: 1250,
        '.MuiDrawer-paper': {
          height: '90%',
          borderRadius: '16px 16px 0 0',
        },
      }}
    >
      <Scrollbar>
        <Card
          sx={{
            p: (theme) => theme.spacing(4, 2, 6, 2),
          }}
        >
          <Stack alignItems="center" mb={2}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              sx={{
                position: 'absolute',
                left: 16,
              }}
              onClick={onAdvancedFiltersDrawerClose}
            >
              <Iconify
                icon="eva:close-fill"
                sx={{
                  height: 26,
                  width: 26,
                }}
              />
            </IconButton>
            <Typography variant="h3">{`Filtres ${
              selectedFiltersCount > 0 ? ` (${selectedFiltersCount})` : ''
            }`}</Typography>
            <Button
              variant="text"
              sx={{
                textDecoration: 'underline',
                textTransform: 'capitalize',
                fontWeight: 'normal',
                color: 'black',
                position: 'absolute',
                right: 16,
              }}
              onClick={() => {
                stopPropagation(ACTIONS_HANDLERS.blankAdvancedFilters());
                removeQueryParameters(['storeId', 'alertId']);
              }}
            >
              Effacer
            </Button>
          </Stack>
          <Divider />

          <Stack spacing={4} mt={4}>
            <DatesFilters />
            <StoreFilter />
            <EstimatedValuesFilters />
            <CameraFilter />
            <TagsFilters />
          </Stack>
        </Card>
      </Scrollbar>
      <Box sx={{ p: (theme) => theme.spacing(2), backgroundColor: '#FFFFFF' }}>
        <Button
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'uppercase',
          }}
          onClick={onSavedFiltersDrawerClose}
        >
          Enregistrer
        </Button>
      </Box>
    </AdvancedFiltersWrapper>
  );
};

export default AdvancedFiltersDrawer;
