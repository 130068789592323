import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useSnackbar } from 'notistack';
import CopyToClipboard from '../../components/CopyToClipboard';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { useSafeMutation, useSafeQuery } from '../../services/apollo-client/wrappers';
import { GET_STORE } from '../../graphql/stores/queries';
import Label from '../../components/Label';
import useMainPath from '../../hooks/useMainPath';
import useAuth from '../../hooks/useAuth';
import RoleBasedGuard from '../../guards/RoleBasedGuard';
import USER_ROLES from '../../constants/userRoles';
import StoreViewTabs from '../../sections/@dashboard/store/view/StoreViewTabs';
import { GENERATE_INVITATION_LINK } from '../../graphql/stores/mutations';

export default function StoreView() {
  const { id } = useParams();
  const { stores, onChangeStore, selectedStore, setSelectedStore } = useAuth();
  const navigate = useNavigate();
  const mainPath = useMainPath();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useSafeQuery(GET_STORE, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
    onError: () => navigate(mainPath),
  });

  const [generateInvitationCode] = useSafeMutation(GENERATE_INVITATION_LINK, {
    variables: {
      storeId: id,
    },
    refetchQueries: ['getStore'],
    onCompleted: () => {
      enqueueSnackbar('Un nouveau lien a été généré');
    },
  });

  useEffect(() => {
    const store = stores.find(({ id: storeId }) => storeId === id);
    if (id !== selectedStore?.id && Boolean(store)) setSelectedStore(store);
  }, [id, onChangeStore, stores, selectedStore, setSelectedStore]);

  if (!data || loading) return null;

  const store = data.getStore;
  const { name, code, inactive, invitationUrl, id: storeId, hasValidation } = store;

  return (
    <Page title={`${name} | Magasin`}>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading={
            <Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <span>{name}</span>
                <RoleBasedGuard roles={[USER_ROLES.ADMIN]}>
                  <Stack direction="row" spacing={1}>
                    <Label variant="ghost" color={inactive ? 'error' : 'success'}>
                      {inactive ? 'Inactif' : 'Actif'}
                    </Label>
                    <Label variant="ghost" color={hasValidation ? 'warning' : 'success'}>
                      {hasValidation ? 'Soumis à validation' : 'Automatique'}
                    </Label>
                  </Stack>
                </RoleBasedGuard>
              </Stack>
              <RoleBasedGuard roles={[USER_ROLES.ADMIN]}>
                <CopyToClipboard variant="caption" sx={{ fontWeight: 'bold' }} text={id} />
              </RoleBasedGuard>
            </Stack>
          }
          links={[{ name: `${name} - ${code}` }]}
        />
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'center', md: 'flex-start' }} spacing={3}>
          <Card sx={{ p: 1 }}>
            <QRCodeSVG value={invitationUrl} />
          </Card>
          <Stack>
            <Stack spacing={0.5} direction="row" alignItems="center">
              <Typography marginBottom={1} variant="h5">
                QRCode d'invitation
              </Typography>
              <CopyToClipboard disableText text={invitationUrl} />
            </Stack>
            <Typography>
              Servez-vous de ce QRCode pour inviter des collaborateurs à autoriser les notifications.
            </Typography>
            <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.STORE]}>
              <Box mt={2} mb={0.5}>
                <Button onClick={generateInvitationCode} variant="contained">
                  Regénérer
                </Button>
              </Box>
              <Typography variant="caption">La génération d'un nouveau lien invalidera les anciens.</Typography>
            </RoleBasedGuard>
          </Stack>
        </Stack>
        <Stack mt={4}>
          <StoreViewTabs storeId={storeId} />
        </Stack>
      </Container>
    </Page>
  );
}
