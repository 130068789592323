import { useSnackbar } from 'notistack';
import { Card, Stack, Typography, Drawer, IconButton, Box } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useQueryParams from 'src/hooks/useQueryParams';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import Iconify from 'src/components/Iconify';
import stopPropagation from 'src/utils/stopPropagation';

const StoresListDrawer = ({ onClose }) => {
  const { stores } = useAuth();
  const { storeId } = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const { ACTIONS_HANDLERS } = useAlertsFilters();
  const { changeParameterValue, removeQueryParameters } = useQueryParams();

  const DEFAULT_STORE = {
    name: 'Tous les magasins',
    id: null,
  };

  const formattedStores = stores.map((store) => ({
    name: store.name,
    id: store.id,
  }));

  const STORES_LIST_OPTIONS = [DEFAULT_STORE, ...formattedStores];

  const handleStoreSelection = (selectedStore) => {
    const selectedStoreId = selectedStore?.id;
    const selectedStoreName = selectedStore?.name;
    const isDifferentStoreId = selectedStoreId !== storeId;

    if (selectedStoreId && isDifferentStoreId) {
      changeParameterValue('storeId', selectedStoreId);
      enqueueSnackbar(`Magasin sélectionné: ${selectedStoreName}`);
    }
    if (!selectedStoreId && isDifferentStoreId) {
      removeQueryParameters('storeId');
      enqueueSnackbar('Tous les magasins sélectionnés');
    }
    ACTIONS_HANDLERS.setStoreId(selectedStoreId);
    onClose();
  };

  return (
    <Drawer
      anchor="bottom"
      open
      onClose={onClose}
      sx={{
        zIndex: 9999,
        '.MuiDrawer-paper': {
          borderRadius: '16px 16px 0 0',
          height: 'auto',
        },
      }}
    >
      <Card
        sx={{
          p: (theme) => theme.spacing(4, 2, 6, 2),
          maxHeight: '100%',
          overflow: 'auto',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          sx={{
            position: 'absolute',
            left: 16,
          }}
          onClick={stopPropagation(onClose)}
        >
          <Iconify
            icon="eva:close-fill"
            sx={{
              height: 26,
              width: 26,
            }}
          />
        </IconButton>
        <Stack alignItems="center" mb={4}>
          <Typography variant="h3">Magasins</Typography>
        </Stack>

        {STORES_LIST_OPTIONS.map((store) => {
          const hasStoreId = !!storeId;
          const defaultStore = STORES_LIST_OPTIONS.find((store) => store.id === null);
          const isHighlighted = hasStoreId ? store.id === storeId : store.name === defaultStore.name;

          return (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                py: 2,
                borderTop: 1,
                cursor: 'pointer',
                borderColor: '#ECECEC',
                ...(isHighlighted && {
                  color: (theme) => theme.palette.primary.main,
                }),
                '&:hover': {
                  backgroundColor: '#071ac814',
                },
              }}
              key={store.id}
              onClick={() => handleStoreSelection(store)}
            >
              <Typography
                sx={{
                  flex: 1,
                  px: 2,
                }}
              >
                {store.name}
              </Typography>
              {isHighlighted && (
                <Box
                  sx={{
                    height: 24,
                    width: 24,
                    mr: 2,
                  }}
                  component="img"
                  src="/assets/alerts/checked.svg"
                />
              )}
            </Stack>
          );
        })}
      </Card>
    </Drawer>
  );
};

export default StoresListDrawer;
