import {
    Box,
    Typography,
    ToggleButtonGroup,
    ToggleButton,
    FormControl,
    TextField,
    Stack,
    InputLabel,
    Button
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Iconify from "src/components/Iconify";

import CardToggleButton from "src/components/ftp/CardToggleButton";
import TabContainer from "../../../../../components/ftp/TabContainer";
import { formConfig } from "../formConfig";

// Temporary data
const machines = [
    {
        id: "8efc33",
        cameras: 3,
        online: false
    },
    {
        id: "d4cc90",
        cameras: 8,
        online: true
    },
]

// App
export default function Machine({ form }) {
    const theme = useTheme();

    return (
        <TabContainer header="Choose Machine">

            <ToggleButtonGroup
                sx={{ display: "flex", gap: 3, bgcolor: "transparent", border: "none" }}
                value={form.data?.machine}
                exclusive
                onChange={(e) => form.setData((prev) => ({ ...prev, machine: e.currentTarget.value }))}
                fullWidth
            >
                {machines.map(({ id, cameras, online }) => (
                    <CardToggleButton
                        key={id}
                        value={id}
                        icon={(
                            <Iconify icon="bi:server" color={form.data?.machine === id ? theme.palette.primary.main : theme.palette.grey[500]} width={26} height={26} />
                        )}
                        label={id}
                    />
                ))}
            </ToggleButtonGroup>

        </TabContainer>
    );
}