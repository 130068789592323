import { gql } from '@apollo/client';

const CREATE_CAMERA = gql`
  mutation createCamera($storeId: String!) {
    createCamera(storeId: $storeId) {
      id
    }
  }
`;

const UPDATE_CAMERA = gql`
  mutation updateCamera($input: UpdateCameraInput!) {
    updateCamera(input: $input) {
      id
    }
  }

type UpdateCameraInput {
  id: ID!
  name: String!
  ip: String!
  password: String!
}
`;


const DELETE_CAMERA = gql`
  mutation deleteCamera($id: ID!) {
    deleteCamera(id: $id)
  }
`;

export {
  CREATE_CAMERA,
  UPDATE_CAMERA,
  DELETE_CAMERA
};
