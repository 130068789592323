import React, { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton, InputAdornment, Stack, Tooltip } from '@mui/material';
import { RHFTextField } from '../../../../components/hook-form';
import FormDialog from '../../../../components/form/FormDialog';
import USER_ROLES from '../../../../constants/userRoles';
import StoresSelect from '../../store/form/StoresSelect';
import Iconify from '../../../../components/Iconify';
import generateStrongPassword from '../../../../utils/generateStrongPassword';

const ContactForm = ({ user, onClose, onSaveUser, saving }) => {
  const edit = Boolean(user?.id);

  const [showPassword, setShowPassword] = useState(true);

  const UserSchema = Yup.object().shape({
    firstName: Yup.string().required('Ce champ est requis'),
    lastName: Yup.string().required('Ce champ est requis'),
    email: Yup.string().email("L'adresse email n'est pas valide").required('Ce champ est requis'),
    password: edit ? Yup.string().nullable() : Yup.string().required('Ce champ est requis').nullable(),
    stores: Yup.array(),
  });

  const defaultValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    password: undefined,
    stores: user?.stores?.map(({ id }) => id),
  };

  const methods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues,
  });

  const { setValue, watch } = methods;

  const onSubmit = async (data) => {
    try {
      await onSaveUser({
        id: user?.id,
        ...data,
        role: USER_ROLES.STORE,
      });
    } catch (error) {
      //
    }
  };

  const { stores } = watch();

  return (
    <FormDialog
      edit={edit}
      loading={saving}
      open={Boolean(user)}
      methods={methods}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Stack spacing={3}>
        <Stack spacing={2} direction="row">
          <RHFTextField autoFocus name="firstName" placeholder="Prénom" />
          <RHFTextField name="lastName" placeholder="Nom" />
        </Stack>
        <RHFTextField name="email" placeholder="Adresse Email" />
        <RHFTextField
          name="password"
          type={!showPassword ? 'password' : 'text'}
          autoComplete="new-password"
          placeholder="Mot de passe"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Stack direction="row">
                  <Tooltip title="Générer un mot de passe aléatoire">
                    <IconButton onClick={() => setValue('password', generateStrongPassword())}>
                      <Iconify icon="eva:refresh-fill" />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </Stack>
              </InputAdornment>
            ),
          }}
        />
        <StoresSelect value={stores} onChange={(newValue) => setValue('stores', newValue)} />
      </Stack>
    </FormDialog>
  );
};

export default ContactForm;
