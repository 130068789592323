import { Card, Stack, Typography, Drawer } from '@mui/material';
import { useSnackbar } from 'notistack';
import sendActionToApp from 'src/utils/sendActionToApp';
import WEB_APP_ACTIONS from 'src/utils/webAppActions';
import { REQUEST_RESET_PASSWORD } from '../../../graphql/users/mutations';
import useSafeMutation from '../../../services/apollo-client/wrappers/useSafeMutation';
import useAuth from '../../../hooks/useAuth';

const ProfileDrawerAction = ({ onClose }) => {
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [requestResetPassword] = useSafeMutation(REQUEST_RESET_PASSWORD, {
    onCompleted: () => {
      enqueueSnackbar(`Un email vous a été envoyé. Vous allez être déconnecté`);
      onClose();
      setTimeout(() => {
        logout();
        sendActionToApp({ action: WEB_APP_ACTIONS.LOGOUT });
      }, 3000);
    },
  });

  const resetPassword = async () => {
    try {
      requestResetPassword({
        variables: {
          email: user.email,
        },
      });
    } catch {
      // Nothing to do here
    }
  };

  const COLLABORATOR_ACTIONS = [
    {
      label: 'Annuler',
      color: '#E81A1A',
      onClick: () => onClose(),
    },
    {
      label: 'Confirmer',
      onClick: () => resetPassword(),
    },
  ];

  return (
    <Drawer
      anchor="bottom"
      open
      onClose={onClose}
      sx={{
        zIndex: 9999,
        '.MuiDrawer-paper': {
          borderRadius: '16px 16px 0 0',
        },
      }}
    >
      <Card
        sx={{
          p: (theme) => theme.spacing(4, 2, 6, 2),
        }}
      >
        <Stack alignItems="center" mb={4} spacing={1}>
          <Stack alignItems="center">
            <Typography variant="h3" textAlign="center">
              Réinitialiser le mot de passe
            </Typography>

            <Typography
              sx={{
                color: '#9B9B9B',
              }}
              textAlign="center"
            >
              En poursuivant vous allez être déconnecté et vous allez recevoir un email pour réinitialiser votre mot de
              passe
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1} direction="row" justifyContent="space-evenly">
          {COLLABORATOR_ACTIONS.map((action) => (
            <Card
              key={action.label}
              sx={{
                p: 2,
                borderRadius: '8px',
                border: 'none',
                backgroundColor: (theme) => action.backgroundColor || theme.palette.background.paper,
                flex: '1 1 auto',
              }}
              onClick={action.onClick}
            >
              <Stack direction="row" spacing={2} justifyContent="center">
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    color: action.color,
                  }}
                >
                  {action.label}
                </Typography>
              </Stack>
            </Card>
          ))}
        </Stack>
      </Card>
    </Drawer>
  );
};

export default ProfileDrawerAction;
