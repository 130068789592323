import { gql } from '@apollo/client';

const LIST_ALERTS = gql`
  query listAlerts($storeId: ID, $tagId: ID, $tagsTypeId: ID, $pagination: Pagination!, $filters: AlertFiltersInput) {
    listAlerts(storeId: $storeId, tagId: $tagId, tagsTypeId: $tagsTypeId, pagination: $pagination, filters: $filters) {
      total
      hasMore
      alerts {
        id
        alertId
        externalId
        falsePositive
        storeId
        type
        cameraId
        title
        body
        imageUrl
        occurredAt
        receivedAt
        clickedAt
        validated
        isFavorite
        tags {
          id
          label
          tagType {
            id
            name
          }
        }
        store {
          id
          code
          name
        }
      }
    }
  }
`;

const GET_ALERT = gql`
  query getAlert($alertId: ID) {
    getAlert(alertId: $alertId) {
      id
      tags {
        id
        label
        tagType {
          id
          name
        }
      }
      falsePositive
      externalId
      storeId
      type
      cameraId
      title
      body
      videoUrl
      imageUrl
      occurredAt
      receivedAt
      clickedAt
      validated
      isFavorite
      metadata
    }
  }
`;

export { LIST_ALERTS, GET_ALERT };
