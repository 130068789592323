import { useState } from 'react';
import { Card, Stack, Drawer, Dialog } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import useResponsive from 'src/hooks/useResponsive';
import { ALERT_GLOBAL_TYPES } from 'src/constants/alertTypes';
import QualificationDrawerContent from './QualificationDrawerContent';
import ConfirmedDrawerContent from './ConfirmedDrawerContent';
import NonConfirmedDrawerContent from './NonConfirmedDrawerContent';

const AlertDrawer = ({ alert, onClose, selectedAlertType }) => {
  const [alertType, setAlertType] = useState(selectedAlertType);
  const isDesktop = useResponsive('up', 'sm');

  const WrapperDependingOnScreenSize = isDesktop ? Dialog : Drawer;

  const handleSelectAlertType = (alertType) => {
    setAlertType(alertType);
  };

  const getDrawerContent = () => {
    switch (alertType) {
      case ALERT_GLOBAL_TYPES.CONFIRMED:
        return ConfirmedDrawerContent;
      case ALERT_GLOBAL_TYPES.FALSE_POSITIVE:
        return NonConfirmedDrawerContent;
      default:
        return QualificationDrawerContent;
    }
  };

  const DrawerContent = getDrawerContent();

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => onClose(),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  return (
    <WrapperDependingOnScreenSize
      anchor="bottom"
      ModalProps={{ keepMounted: true }}
      open
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      sx={{
        '.MuiDrawer-paper': {
          borderRadius: (theme) => theme.spacing(2, 2, 0, 0),
          backgroundColor: '#FFFFFF !important',
        },
      }}
    >
      <div {...swipeHandlers}>
        <Card
          sx={{
            p: (theme) => theme.spacing(4, 2),
          }}
        >
          <Stack alignItems="center" spacing={2}>
            <DrawerContent onSelectedAlertType={handleSelectAlertType} alert={alert} onClose={onClose} />
          </Stack>
        </Card>
      </div>
    </WrapperDependingOnScreenSize>
  );
};

export default AlertDrawer;
