import { Stack, Typography, Box } from '@mui/material';
import Label from 'src/components/Label';
import { fAlertDate } from 'src/utils/formatTime';
import getAlertConfiguration from 'src/utils/getAlertConfiguration';

const AlertItem = ({ alert, onSelectAlert }) => {
  const {
    label: alertStatusLabel,
    color: alertStatusColor,
    backgroundColor: alertStatusBackgroundColor,
  } = getAlertConfiguration({ onQualificationDrawerOpen: false, alert });

  return (
    <Stack spacing={1} onClick={onSelectAlert} sx={{ cursor: 'pointer', my: 1 }}>
      <Box
        sx={{
          height: 200,
          position: 'relative',
          borderRadius: '8px',
        }}
      >
        <Box
          component="img"
          src={alert.imageUrl}
          alt="Camera thumbnail"
          sx={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '8px',
          }}
        />

        <Label
          color="dark"
          sx={{
            backgroundColor: alertStatusBackgroundColor,
            color: alertStatusColor,
            position: 'absolute',
            bottom: 12,
            right: 12,
          }}
        >
          {alertStatusLabel}
        </Label>
      </Box>
      <Stack>
        <Typography variant="body2" fontWeight="bold">{`Caméra ${alert.cameraId}`}</Typography>
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontWeight: 'normal' }}
        >
          <Box>{fAlertDate(alert.occurredAt)}</Box>
          <Box sx={{ mx: 0.5 }}>•</Box>
          <Box>{alert.store.name.toUpperCase()}</Box>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AlertItem;
