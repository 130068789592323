import { Box, Stack, Typography, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import stopPropagation from '../../../utils/stopPropagation';
import useAlertsFilters from '../../../hooks/useAlertsFilters';
import useSafeQuery from '../../../services/apollo-client/wrappers/useSafeQuery';
import { LIST_ALERT_SEARCH_HISTORIES } from '../../../graphql/alertSearchHistories/queries';
import useSafeMutation from '../../../services/apollo-client/wrappers/useSafeMutation';
import { DELETE_ALERT_SEARCH_HISTORY } from '../../../graphql/alertSearchHistories/mutations';

const AlertSearchHistory = () => {
  const { ACTIONS_HANDLERS } = useAlertsFilters();
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useSafeQuery(LIST_ALERT_SEARCH_HISTORIES, {
    fetchPolicy: 'network-only',
  });
  const [deleteAlertSearchHistory] = useSafeMutation(DELETE_ALERT_SEARCH_HISTORY, {
    refetchQueries: ['listAlertSearchHistories'],
    onCompleted: () => {
      enqueueSnackbar('Recherche supprimée');
    },
  });

  const listAlertSearches = data?.listAlertSearchHistories;
  if (!listAlertSearches) return null;

  const hasHistory = listAlertSearches.length > 0;
  if (!hasHistory) return null;

  return (
    <>
      <Typography variant="h6">Historique</Typography>
      {listAlertSearches.map((alertSearch) => (
        <Stack
          key={`alert-search-${alertSearch.id}`}
          direction="row"
          py={2}
          spacing={1}
          onClick={stopPropagation(() => {
            ACTIONS_HANDLERS.setSearch(alertSearch.search);
          })}
          sx={{
            cursor: 'pointer',
            borderBottom: 1,
            borderColor: '#ECECEC',
          }}
        >
          <Box component="img" src="/assets/replay.svg" />
          <Typography
            variant="body2"
            sx={{
              flex: 1,
            }}
          >
            {alertSearch.search}
          </Typography>
          <IconButton>
            <Box
              component="img"
              src="/assets/remove.svg"
              sx={{
                zIndex: 9999,
              }}
              onClick={stopPropagation(() => {
                deleteAlertSearchHistory({ variables: { id: alertSearch.id } });
              })}
            />
          </IconButton>
        </Stack>
      ))}
    </>
  );
};

export default AlertSearchHistory;
