import React from 'react';
import useAuth from '../../../../hooks/useAuth';
import TagsTypeAlbums from './TagsTypeAlbums';
import StoreAlbums from './StoreAlbums';
import Scrollbar from '../../../../components/Scrollbar';

const Albums = ({ storeId, onClose }) => {
  const { stores } = useAuth();

  const albums = storeId ? (
    <TagsTypeAlbums storeId={storeId} onClose={onClose} />
  ) : (
    <StoreAlbums stores={stores} onClose={onClose} />
  );

  return <Scrollbar sx={{ maxHeight: 400 }}>{albums}</Scrollbar>;
};

export default Albums;
