import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import useResponsive from 'src/hooks/useResponsive';

const AlertsHeader = ({ handleStoresDrawerOpen, storeSelectorLabel }) => {
  const { filtersState } = useAlertsFilters();
  const isDesktop = useResponsive('up', 'sm');

  if (filtersState.isSearchLayoutActive) return null;

  return (
    <HeaderBreadcrumbs
      heading={
        <Stack alignItems="flex-start">
          <Typography
            variant={isDesktop ? 'h3' : 'h2'}
            sx={{
              m: 0,
            }}
          >
            Alertes
          </Typography>

          <Button
            variant="text"
            onClick={handleStoresDrawerOpen}
            endIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} />}
            sx={{
              color: 'primary.main',
              fontWeight: 'normal',
              textTransform: 'none',
              py: 0,
              px: 0,
            }}
          >
            {storeSelectorLabel}
          </Button>
        </Stack>
      }
    />
  );
};

export default AlertsHeader;
