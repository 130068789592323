import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import { useSafeLazyQuery, useSafeMutation } from 'src/services/apollo-client/wrappers';
import { LIST_USERS } from 'src/graphql/users/queries';
import useTabs from 'src/hooks/useTabs';
import useTable from 'src/hooks/useTable';
import { DELETE_USER, SAVE_USER } from 'src/graphql/users/mutations';
import useDialogs from 'src/hooks/useDialogs';
import CollaboratorTableRow from './CollaboratorTableRow';

const STATUS_OPTIONS = [
  {
    value: 'active',
    label: 'Actifs',
  },
  {
    value: 'inactive',
    label: 'Inactifs',
  },
];

const TABLE_HEAD = [
  { id: 'displayName', label: 'Nom et Prénom', align: 'left' },
  { id: 'email', label: 'Adresse Email', align: 'left' },
  { id: 'role', label: 'Role', align: 'center' },
  { id: 'createdAt', label: 'Enregistré le', align: 'left' },
  { id: 'more-menu', label: '', align: 'left' },
];

const CollaboratorsList = ({ storeId }) => {
  const { page, setPage } = useTable();
  const { confirm } = useDialogs();
  const [collabs, setCollabs] = useState([]);
  const [listUsers, { data, loading }] = useSafeLazyQuery(LIST_USERS, {
    onCompleted: ({ listUsers }) => {
      setCollabs((c) => [...c, ...listUsers.users]);
      setPage((p) => p + 1);
    },
  });

  const [saveUser] = useSafeMutation(SAVE_USER, {
    refetchQueries: ['listUsers'],
  });

  const [deleteUser] = useSafeMutation(DELETE_USER, {
    refetchQueries: ['listUsers'],
  });

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('active');

  useEffect(() => {
    listUsers({
      onCompleted: ({ listUsers }) => {
        setCollabs(listUsers.users);
        setPage(2);
      },
      fetchPolicy: 'network-only',
      variables: {
        pagination: {
          page: 1,
        },
        filters: {
          inactive: filterStatus === 'inactive',
          storeId,
        },
      },
    });
  }, [listUsers, filterStatus, storeId, setPage]);

  const handleLoadMore = async () => {
    try {
      await listUsers({
        variables: {
          pagination: {
            page,
          },
          filters: {
            inactive: filterStatus === 'inactive',
            storeId,
          },
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const handleSaveUser = async (data) => {
    try {
      await saveUser({
        variables: {
          input: {
            ...data,
          },
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      if (
        await confirm({
          message: 'Cette action est irréversible. Souhaitez-vous continuer ?',
          title: "Suppression d'un contact",
        })
      )
        await deleteUser({
          variables: {
            id,
          },
        });
    } catch {
      // Nothing to do
    }
  };

  const hasMore = data?.listUsers?.hasMore;
  const isNotFound = !loading && (!collabs.length || (!collabs.length && !!filterStatus));

  return (
    <Container maxWidth={false}>
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterStatus}
          onChange={onChangeFilterStatus}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {STATUS_OPTIONS.map(({ label, value }) => (
            <Tab disableRipple key={value} label={label} value={value} />
          ))}
        </Tabs>

        <Divider />

        {/* <CollaboratorTableToolbar /> */}
        <Box sx={{ height: (th) => th.spacing(2) }} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size="small">
              <TableHeadCustom headLabel={TABLE_HEAD} rowCount={collabs.length} />

              <TableBody>
                {collabs.map((row) => (
                  <CollaboratorTableRow
                    key={row.id}
                    onSaveUser={handleSaveUser}
                    collaborator={row}
                    onDeleteUser={() => handleDeleteUser(row.id)}
                  />
                ))}

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          py={2}
          sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          {hasMore && (
            <Button variant="contained" size="small" onClick={handleLoadMore}>
              Voir plus de résultats ({data?.listUsers?.total - collabs.length})
            </Button>
          )}
          {!hasMore && <Typography variant="caption">Tous les résultats sont affichés</Typography>}
        </Stack>
      </Card>
    </Container>
  );
};

export default CollaboratorsList;
