export default function Tab(theme) {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1, 4),
                    borderRadius: theme.spacing(1),
                    marginRight: theme.spacing(1),
                    backgroundColor: "transparent",
                    color: theme.palette.text.primary,
                    fontWeight: 400,
                    
                    // Background transition
                    transition: "all 0.32s",

                    '&:hover': {
                        backgroundColor: "#071ac814",
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                    },

                    // Selected tab styling
                    '&.Mui-selected': {
                        backgroundColor: "#071ac814",
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                    },
                },
            },
        },
    };
}
