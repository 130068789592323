import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Container } from '@mui/material';
import Page from 'src/components/Page';
import AlertsList from 'src/sections/shared/alerts/AlertsList';
import CamerasList from 'src/sections/shared/CamerasList';
import FavoritesAlertsItem from 'src/sections/shared/favorites-alerts/FavoritesAlertsItem';
import useAuth from 'src/hooks/useAuth'
import { useSafeMutation, useSafeQuery } from 'src/services/apollo-client/wrappers';
import { GET_CAMERAS_OF_STORE } from 'src/graphql/cameras/queries';
import { CREATE_CAMERA } from 'src/graphql/cameras/mutations';
import { useNavigate } from 'react-router';
import useQueryParams from 'src/hooks/useQueryParams';
import StoresListDrawer from 'src/sections/@dashboard/alertsV2/StoresListDrawer';
import AlertsFilters from 'src/sections/@dashboard/alertsV2/filters/AlertsFilters';
import AlertsHeader from 'src/sections/@dashboard/alertsV2/AlertsHeader';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import AlertSearchHistory from 'src/sections/@dashboard/alertsV2/AlertSearchHistory';
import AdvancedFiltersDrawer from 'src/sections/@dashboard/alertsV2/filters/advanced-filters/AdvancedFiltersDrawer';
import useResponsive from 'src/hooks/useResponsive';
import AlertsListDesktop from 'src/sections/shared/alerts/AlertsListDesktop';
import { PATH_DASHBOARD } from 'src/routes/paths';

function useCamerasOfStore({ storeId }) {

  const { data } = useSafeQuery(GET_CAMERAS_OF_STORE, {
    variables: {
      storeId
    },
    fetchPolicy: 'network-only',
  })
  const cameras = useMemo(() => {
    const cameras = data?.camerasOfStore ?? []
    console.log(cameras)
    return cameras
  }, [data])


  // create cameras
  const [createCamera] = useSafeMutation(CREATE_CAMERA, {
    refetchQueries: ['camerasOfStore']
  })

  const addCamera = useCallback(async () => {
    const newCamera = createCamera({
      variables: {
        storeId
      }
    })
    console.log("newCamera: ", newCamera)
  }, [createCamera, storeId])


  return {
    cameras,
    addCamera
  }
}

const Alerts = () => {
  const { stores } = useAuth();
  const { storeId, alert: queryParamAlertId } = useQueryParams();
  const { filtersState, ACTIONS_HANDLERS } = useAlertsFilters();
  const isDesktop = useResponsive('up', 'sm');
  const isMobile = !isDesktop;
  const [isOpenStoresDrawer, setIsOpenStoresDrawer] = useState(false);
  const searchBarRef = useRef(null);
  const navigate = useNavigate();

  const handleStoresDrawerOpen = () => {
    setIsOpenStoresDrawer(true);
  };
  const handleStoresDrawerClose = () => {
    setIsOpenStoresDrawer(false);
  };

  const resetSearch = () => {
    ACTIONS_HANDLERS.resetSearch();
    searchBarRef.current.focus();
  };

  useEffect(() => {
    if (storeId) {
      ACTIONS_HANDLERS.setStoreId(storeId);
    }

    if (queryParamAlertId) {
      navigate(PATH_DASHBOARD.alerts.view(queryParamAlertId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, ACTIONS_HANDLERS, queryParamAlertId]);

  const currentStore = stores.find((store) => store.id === storeId);
  const hasCurrentStore = !!currentStore;
  const storeSelectorLabel = hasCurrentStore ? currentStore.name : 'Voir tous les magasins';
  const displayAlertSearchHistory = filtersState.isSearchLayoutActive && !filtersState.hasTyped;
  const isAdvancedFiltersDrawerOpen = filtersState.advancedFilters.isDrawerOpen;

  // const { cameras, addCamera } = useCamerasOfStore({ storeId })

  return (
    <Page title="Alertes">
      <Container maxWidth={false} sx={{ marginTop: 0 }}>
        {/* <CamerasList cameras={cameras} addCamera={addCamera} /> */}
        <AlertsHeader handleStoresDrawerOpen={handleStoresDrawerOpen} storeSelectorLabel={storeSelectorLabel} />
        <AlertsFilters resetSearch={resetSearch} ref={searchBarRef} />
        {isMobile && (
          <AlertsList
            withDaySeparator
            AlertItemComponent={FavoritesAlertsItem}
            pagination={{ rowsPerPage: 25 }}
            storeId={storeId}
            resetSearch={resetSearch}
            emptyContent={{
              title: 'Aucune alerte',
              withResetAction: true,
              description: filtersState.isSearchLayoutActive
                ? "Veuillez réessayer avec d'autres termes"
                : "Aucune alerte n'a été trouvée",
            }}
          />
        )}
        {isDesktop && (
          <AlertsListDesktop
            withDaySeparator
            pagination={{ rowsPerPage: 25 }}
            storeId={storeId}
            resetSearch={resetSearch}
            emptyContent={{
              title: 'Aucune alerte',
              withResetAction: true,
              description: filtersState.isSearchLayoutActive
                ? "Veuillez réessayer avec d'autres termes"
                : "Aucune alerte n'a été trouvée",
            }}
          />
        )}
        {displayAlertSearchHistory && <AlertSearchHistory />}
        {isOpenStoresDrawer && <StoresListDrawer onClose={handleStoresDrawerClose} />}
        {isAdvancedFiltersDrawerOpen && <AdvancedFiltersDrawer />}
      </Container>
    </Page>
  );
};

export default Alerts;
