import { Chip } from '@mui/material';
import Iconify from 'src/components/Iconify';
import getAlertConfiguration from 'src/utils/getAlertConfiguration';

const AlertQualificationStatus = ({ alert, onQualificationDrawerOpen }) => {
  const { label, color, backgroundColor } = getAlertConfiguration({ onQualificationDrawerOpen, alert });
  const isVirginAlertQualification = alert.falsePositive === null;
  const icon = isVirginAlertQualification ? 'eva:plus-fill' : 'eva:arrow-ios-downward-fill';

  return (
    <Chip
      deleteIcon={
        <Iconify
          icon={icon}
          sx={{
            color: `${color} !important`,
            width: 16,
            height: 16,
          }}
        />
      }
      onClick={onQualificationDrawerOpen}
      onDelete={onQualificationDrawerOpen}
      sx={{
        p: 1,
        maxWidth: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px',
        borderColor: color,
        color,
        backgroundColor,
        outline: '1px solid',
        cursor: onQualificationDrawerOpen ? 'pointer' : 'default',
        '&:hover, &:focus, &:active': {
          backgroundColor: `${backgroundColor} !important`,
          color: `${color} !important`,
        },
        '& .MuiChip-outlined': {
          border: '2px solid',
        },
      }}
      label={label}
    />
  );
};

export default AlertQualificationStatus;
