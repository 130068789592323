import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Stack, Typography, TextField } from '@mui/material';
import useAlertsFilters from 'src/hooks/useAlertsFilters';

const DatesFilter = () => {
  const { ACTIONS_HANDLERS, filtersState } = useAlertsFilters();
  const { start, end } = filtersState.advancedFilters.dates;
  const hasStartDate = !!start;
  const hasEndDate = !!end;

  const todayDate = moment(new Date());
  const startMaxDate = hasEndDate ? moment(new Date(end)) : todayDate;
  const endMinDate = hasStartDate ? moment(new Date(start)) : todayDate;

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Date</Typography>
      <Stack
        spacing={1}
        direction="row"
        sx={{
          '& > *': {
            flex: 1,
          },
        }}
      >
        <DesktopDatePicker
          label={hasStartDate ? 'Début' : ''}
          value={filtersState.advancedFilters.dates.start}
          maxDate={startMaxDate}
          onChange={(newValue) => {
            const isValueValid = moment(newValue).isValid();
            if (isValueValid) {
              const formattedDate = moment(newValue).format('YYYY-MM-DD');
              ACTIONS_HANDLERS.setStartDate(formattedDate);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                placeholder: 'Début',
              }}
            />
          )}
        />
        <DesktopDatePicker
          label={hasEndDate ? 'Fin' : ''}
          value={filtersState.advancedFilters.dates.end}
          minDate={endMinDate}
          maxDate={todayDate}
          onChange={(newValue) => {
            const isValueValid = moment(newValue).isValid();
            if (isValueValid) {
              const formattedDate = moment(newValue).format('YYYY-MM-DD');
              ACTIONS_HANDLERS.setEndDate(formattedDate);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                placeholder: 'Fin',
              }}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};

export default DatesFilter;
