import React from 'react';
import Page from 'src/components/Page';

import AlertDetails from 'src/sections/shared/alerts/AlertDetails';

const Alert = () => (
  <Page title={`Détail d'une alerte`}>
    <AlertDetails />
  </Page>
);

export default Alert;
