import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Container, Stack, Typography } from '@mui/material';
import UserAvatar from 'src/sections/shared/UserAvatar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import { FormProvider } from 'src/components/hook-form';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import { UPDATE_AVATAR } from 'src/graphql/users/mutations';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useAuth from 'src/hooks/useAuth';
import StoresQRCodesDrawer from 'src/sections/shared/StoresQRCodesDrawer';
import ProfileDialog from 'src/sections/profile/profile/ProfileDialog';
import CollaboratorsDialog from 'src/sections/profile/collaborators/CollaboratorsDialog';
import AccordionItem from 'src/sections/profile/AccordionItem';
import QuestionsAnswersDialog from 'src/sections/profile/help-support/QuestionsAnswersDialog';
import InviteCollaborators from 'src/sections/shared/InviteCollaborators';
import useDevice from 'src/hooks/useDevice';
import useResponsive from 'src/hooks/useResponsive';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import { PATH_DASHBOARD } from 'src/routes/paths';

const ProfileStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '99%',
  margin: 'auto',
  flexDirection: 'row',
}));

const TextContainer = styled('div')(() => ({
  textAlign: 'left',
}));

const Profile = () => {
  const { user, stores } = useAuth();
  const { ACTIONS_HANDLERS } = useAlertsFilters();
  const [isStoresQRCodesDrawerOpen, setIsStoresQRCodesDrawerOpen] = useState(false);
  const { device } = useDevice();
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'sm');
  const titleSize = isDesktop ? 'h3' : 'h2';
  const hasOnlyOneStore = stores.length === 1;
  const uniqueStore = stores[0];

  const handleNavigateToMyFavorites = () => {
    ACTIONS_HANDLERS.resetAllFilters();
    ACTIONS_HANDLERS.setFavorite(true);
    navigate(PATH_DASHBOARD.alerts.list);
  };

  const ACCORDION_ITEMS = [
    {
      label: 'Mon profil',
      imageUrl: '/assets/profile/my-profile.svg',
      Component: ProfileDialog,
    },
    {
      label: 'Mes collaborateurs',
      imageUrl: '/assets/profile/my-workers.svg',
      Component: CollaboratorsDialog,
    },
    {
      label: 'Mes favoris',
      imageUrl: '/assets/profile/my-favorites.svg',
      Component: <></>,
      action: handleNavigateToMyFavorites,
    },
    {
      label: 'Aide & support',
      imageUrl: '/assets/profile/help-support.svg',
      Component: QuestionsAnswersDialog,
    },
    ...(device
      ? [
          {
            label: 'Notifications',
            imageUrl: '/assets/profile/my-notifications.svg',
            Component: null,
          },
        ]
      : []),
  ];

  const handleStoresQRCodesDrawerOpen = () => {
    setIsStoresQRCodesDrawerOpen(true);
  };

  const handleStoresQRCodesDrawerClose = () => {
    setIsStoresQRCodesDrawerOpen(false);
  };

  const schema = {
    avatar: Yup.mixed().nullable(),
  };

  const defaultValues = {
    avatar: user.avatarUrl || null,
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(Yup.object().shape(schema)),
    defaultValues,
  });

  const [updateAvatar] = useSafeMutation(UPDATE_AVATAR, {
    onCompleted: () => {},
  });

  return (
    <Page title="Profile">
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading={
            <ProfileStack>
              <TextContainer>
                <Typography variant={titleSize}>{`${user.firstName}`}</Typography>
                <Typography variant={titleSize}>{`${user.lastName}`}</Typography>
              </TextContainer>
              <FormProvider methods={methods}>
                <UserAvatar user={user} updateAvatar={updateAvatar} />
              </FormProvider>
            </ProfileStack>
          }
        />
        <InviteCollaborators onOpen={handleStoresQRCodesDrawerOpen} fullWidth />
        {ACCORDION_ITEMS.map((accordion) => (
          <AccordionItem key={accordion.label} accordion={accordion} />
        ))}
        {isStoresQRCodesDrawerOpen && (
          <StoresQRCodesDrawer onClose={handleStoresQRCodesDrawerClose} store={hasOnlyOneStore ? uniqueStore : null} />
        )}
      </Container>
    </Page>
  );
};

export default Profile;
