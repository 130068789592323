import { useSnackbar } from 'notistack';
import { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { Stack, Typography, TextField, Box, InputAdornment } from '@mui/material';
import useSafeMutation from 'src/services/apollo-client/wrappers/useSafeMutation';
import { EVALUATE_ALERT } from 'src/graphql/alerts/mutations';
import { CONFIRMED_ALERT_TYPES_LABELS } from 'src/constants/alertTypes';
import Iconify from 'src/components/Iconify';

const ConfirmedDrawerContent = ({ alert, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAlertType, setSelectedAlertType] = useState(alert.type);
  const [isUserAction, setIsUserAction] = useState(false);
  const textFieldRefs = useRef({});

  const handleSelectAlertType = (alertType) => {
    setIsUserAction(true);
    setSelectedAlertType(alertType);
  };

  useEffect(() => {
    if (textFieldRefs.current[selectedAlertType]) {
      textFieldRefs.current[selectedAlertType].focus();
      textFieldRefs.current[selectedAlertType].blur();
    }
  }, [selectedAlertType]);

  const setTextFieldRef = (key, el) => {
    textFieldRefs.current[key] = el;
  };

  const [evaluateAlert] = useSafeMutation(
    EVALUATE_ALERT,
    {
      refetchQueries: ['getAlert', 'listUserAlertActions'],
      onCompleted: () => {
        onClose();
        enqueueSnackbar(`Vol avec le statut "${_.capitalize(CONFIRMED_ALERT_TYPES_LABELS[selectedAlertType])}" ajouté`);
      },
    },
    {
      silentError: false,
    }
  );

  const handleEvaluation = async () => {
    try {
      await evaluateAlert({
        variables: {
          alertId: alert.id,
          input: {
            falsePositive: false,
            type: selectedAlertType,
          },
        },
      });
    } catch {
      //
    }
  };

  useEffect(() => {
    if (isUserAction) {
      handleEvaluation();
      setIsUserAction(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAlertType, isUserAction]);

  const getInputStyles = (isCurrentAlertType) => ({
    mb: 2,
    '& .MuiInputBase-input': {
      color: isCurrentAlertType ? 'primary.main' : 'inherit',
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: isCurrentAlertType ? 'primary.main' : 'inherit',
      },
      '&:hover fieldset': {
        borderColor: isCurrentAlertType ? 'primary.main' : 'inherit',
      },
      '&.Mui-focused fieldset': {
        borderColor: isCurrentAlertType ? 'primary.main' : 'inherit',
      },
    },
  });

  return (
    <>
      <Stack alignItems="center" mb={3}>
        <Typography variant="h3">Vol confirmé</Typography>
        <Typography textAlign="center" sx={{ width: '300px' }}>
          Veuillez spécifier l'état du vol
        </Typography>
      </Stack>
      <Box width="100%">
        {Object.keys(CONFIRMED_ALERT_TYPES_LABELS).map((key) => {
          const isCurrentAlertType = selectedAlertType === key;

          return (
            <TextField
              key={key}
              onClick={() => handleSelectAlertType(key)}
              value={CONFIRMED_ALERT_TYPES_LABELS[key]}
              variant="outlined"
              fullWidth
              sx={getInputStyles(isCurrentAlertType)}
              inputRef={(el) => setTextFieldRef(key, el)}
              InputProps={{
                readOnly: true,
                endAdornment: isCurrentAlertType ? (
                  <InputAdornment position="end">
                    <Iconify icon="eva:checkmark-circle-2-fill" color="primary.main" />
                  </InputAdornment>
                ) : null,
              }}
            />
          );
        })}
      </Box>
    </>
  );
};

export default ConfirmedDrawerContent;
