import { gql } from '@apollo/client';

const DELETE_TAG = gql`
  mutation deleteTag($tagId: ID!) {
    deleteTag(tagId: $tagId)
  }
`;

const DELETE_ALERT_TAG = gql`
  mutation deleteAlertTag($tagId: ID!, $alertId: ID!) {
    deleteAlertTag(tagId: $tagId, alertId: $alertId)
  }
`;

export { DELETE_TAG, DELETE_ALERT_TAG };
