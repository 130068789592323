import {
  Dialog,
  styled,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import React from 'react';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../Iconify';
import FormErrors from './FormErrors';
import { FormProvider } from '../hook-form';

const StickyDialog = styled(Dialog)(({ ismobile }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
  '& .MuiPaper-root': ismobile && {
    margin: 0,
  },
}));

const FormDialog = ({ children, open, onClose, methods, expectedErrors, error, loading, edit, onSubmit }) => {
  const isMobile = useResponsive('down', 'sm');
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, reset } = methods;

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmitOverride = async (data) => {
    try {
      await onSubmit(data);
      enqueueSnackbar(edit ? "L'enregistrement à été modifié!" : "L'enregistrement à été créé!");
      handleClose();
    } catch (error) {
      //
    }
  };

  return (
    <StickyDialog
      onClose={handleClose}
      scroll="paper"
      open={open}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
      ismobile={isMobile}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{edit ? 'Édition' : 'Création'}</Typography>
          <IconButton onClick={handleClose}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      {error && (
        <>
          <Divider />
          <FormErrors expectedErrors={expectedErrors} error={error} />
        </>
      )}
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <FormProvider methods={methods} onSubmit={(e) => e.preventDefault()}>
          <Box px={3} py={4}>
            {children}
          </Box>
        </FormProvider>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button onClick={handleClose}>Annuler</Button>
          <LoadingButton variant="contained" loading={loading} onClick={handleSubmit(onSubmitOverride)}>
            Enregistrer
          </LoadingButton>
        </Stack>
      </DialogActions>
    </StickyDialog>
  );
};

export default FormDialog;
