import React from 'react';
import { isNullOrUndefined } from 'util';
import { Box, Stack, styled, Tooltip, Typography } from '@mui/material';
import cssStyles from '../../../utils/cssStyles';
import { fDateTimeToUtc } from '../../../utils/formatTime';
import Iconify from '../../../components/Iconify';
import RoleBasedGuard from '../../../guards/RoleBasedGuard';
import USER_ROLES from '../../../constants/userRoles';
import Label from '../../../components/Label';

const AlertContainer = styled(Stack)(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.common.white,
  cursor: 'pointer',
  transition: theme.transitions.create('all'),
  boxShadow: theme.shadows[1],
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const Alert = ({ alert, onSelectAlert }) => {
  const { title, body, falsePositive, occurredAt, receivedAt, clickedAt, validated } = alert;

  return (
    <AlertContainer onClick={onSelectAlert} spacing={0.5}>
      <Stack>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          {!isNullOrUndefined(falsePositive) && <Iconify icon={`mdi:thumb-${falsePositive ? 'down' : 'up'}`} />}
        </Stack>

        <Typography variant="caption">{fDateTimeToUtc(occurredAt)}</Typography>
      </Stack>
      <Typography variant="caption">app.alfred-eyes.ai</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography noWrap variant="caption">
          {body}
        </Typography>
        <RoleBasedGuard roles={[USER_ROLES.ADMIN]}>
          {!validated && <Label color="warning">À valider</Label>}
        </RoleBasedGuard>
        <RoleBasedGuard roles={[USER_ROLES.STORE, USER_ROLES.EMPLOYEE]}>
          {!(clickedAt || receivedAt) && <Box sx={{ width: 8, height: 8, bgcolor: 'error.main', borderRadius: 8 }} />}
          {receivedAt && !clickedAt && (
            <span>
              <Tooltip title={`Reçue le ${fDateTimeToUtc(receivedAt)}`}>
                <span>
                  <Iconify color="currentColor" icon="eva:checkmark-fill" />
                </span>
              </Tooltip>
            </span>
          )}
          {clickedAt && (
            <span>
              <Tooltip title={`Vue le ${fDateTimeToUtc(clickedAt)}`}>
                <Stack direction="row">
                  <Iconify color="success.dark" icon="eva:checkmark-fill" />
                </Stack>
              </Tooltip>
            </span>
          )}
        </RoleBasedGuard>
      </Stack>
    </AlertContainer>
  );
};

export default Alert;
