import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { merge } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import stopPropagation from '../utils/stopPropagation';
import useAntiSpammingAction from '../hooks/useAntiSpammingAction';
import Iconify from './Iconify';

export default ({ text, displayText, sx, disableText, variant }) => {
  const [_, { canPerformAction: notCopied }] = useAntiSpammingAction('copyInvitationUrl', () => null, {
    period: 2,
    periodUnit: 'seconds',
  });

  return (
    <Stack alignItems="center" direction="row" spacing={0.5} maxWidth="100%">
      <Typography sx={merge(sx)} variant={variant} noWrap>
        {!disableText && (displayText || text)}
      </Typography>
      <Box>
        <Tooltip title={notCopied ? 'Copier dans le presse-papier' : 'Copié!'}>
          <IconButton onClick={stopPropagation(_)} size="small">
            <CopyToClipboard onCopy={_} text={text}>
              <Iconify icon="eva:copy-fill" />
            </CopyToClipboard>
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};
