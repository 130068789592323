import React from 'react';
import {
  Card,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { Icon as Iconify } from '@iconify/react';

const AlertRecipients = ({ metadata }) => {
  const [openRecipientId, setOpenRecipientId] = React.useState(null);

  const handleClick = (id) => {
    setOpenRecipientId(openRecipientId === id ? null : id);
  };

  return (
    <Card sx={{ p: 2, overflowX: 'auto' }}>
      <Stack mb={2}>
        <Typography variant="h6">Destinataires </Typography>
        <Typography variant="caption">Liste de tous les destinataires.</Typography>
      </Stack>
      <List>
        {metadata.recipients.map((recipient) => (
          <div key={recipient.id}>
            <ListItem button onClick={() => handleClick(recipient.id)}>
              <ListItemText primary={`${recipient.firstName} ${recipient.lastName}`} secondary={recipient.email} />
              {openRecipientId === recipient.id ? (
                <Iconify icon={'eva:arrow-circle-up-outline'} />
              ) : (
                <Iconify icon={'eva:arrow-circle-down-outline'} />
              )}
            </ListItem>
            <Collapse in={openRecipientId === recipient.id} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Appareil</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Notifications</TableCell>
                      <TableCell>Actif</TableCell>
                      <TableCell>Statut</TableCell>
                      <TableCell>Erreur</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recipient.devices.map((device, index) => (
                      <TableRow key={index}>
                        <TableCell>{device.name}</TableCell>
                        <TableCell align="center">{device.type === 'desktop' ? '🖥️' : '📱'}</TableCell>
                        <TableCell align="center">
                          {device.isMuted ? (
                            <Iconify icon={'eva:close-circle-outline'} color="red" width={20} />
                          ) : (
                            <Iconify icon={'eva:checkmark-circle-2-outline'} color="green" width={20} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {device.inactive ? (
                            <Iconify icon={'eva:close-circle-outline'} color="red" width={20} />
                          ) : (
                            <Iconify icon={'eva:checkmark-circle-2-outline'} color="green" width={20} />
                          )}
                        </TableCell>
                        <TableCell>{device.status}</TableCell>
                        <TableCell>{device?.error ? device?.error?.message : 'RAS'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </div>
        ))}
      </List>
    </Card>
  );
};

export default AlertRecipients;
