import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';

import { CREATE_USER_ALERT_ACTION } from 'src/graphql/userAlertActions/mutations';
import useResponsive from 'src/hooks/useResponsive';
import useSafeMutation from 'src/services/apollo-client/wrappers/useSafeMutation';
import ALERT_ACTION_TYPES from 'src/constants/alertActionTypes';
import sendActionToApp from 'src/utils/sendActionToApp';
import WEB_APP_ACTIONS from 'src/utils/webAppActions';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Player = ({ alert }) => {
  const isDesktop = useResponsive('up', 'lg');
  const [createUserAlertAction] = useSafeMutation(CREATE_USER_ALERT_ACTION, {
    refetchQueries: ['listUserAlertActions'],
  });

  const [playing, setPlaying] = useState(false);
  const [onPlaySent, setOnPlaySent] = useState(false);

  const handlePlay = () => {
    setPlaying((playing) => !playing);
    if (isMobile) {
      sendActionToApp({
        action: WEB_APP_ACTIONS.PLAY_VIDEO,
        message: {
          videoUrl: alert.videoUrl,
        },
      });
    }
  };

  const handleOnPlay = () => {
    if (onPlaySent) {
      return;
    }
    setOnPlaySent(true);
    createUserAlertAction({
      variables: {
        input: {
          alertId: alert.id,
          actionType: ALERT_ACTION_TYPES.WATCHED,
        },
      },
    });
  };

  const showPlayControl = !playing;

  return (
    <Box
      sx={{
        '& video': {
          ...(isDesktop && { borderRadius: (theme) => theme.spacing(1.5) }),
        },
        position: 'relative',
      }}
    >
      <ReactPlayer
        url={alert.videoUrl}
        playing={playing}
        onPlay={handleOnPlay}
        width="100%"
        height="100%"
        controls={!isMobile}
        loop
        style={{}}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload noremoteplayback noplaybackrate',
              disablePictureInPicture: true,
            },
          },
        }}
        pip={false}
        onClick={handlePlay}
      />
      {isMobile && (
        <Box
          sx={{
            display: showPlayControl ? 'block' : 'none',
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            pointerEvents: 'none'
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              color: 'white',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
              backgroundColor: 'rgba(105, 105, 105, 0.9)',
              pointerEvents: 'none'
            }}
          >
            <PlayArrowIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default Player;
