// ----------------------------------------------------------------------

export default function Tabs(theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "transparent", // Change the indicator color (e.g., red)
        },
      },
    },
  };
}
