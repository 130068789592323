// cspell-checker: disable-next-line
const PASSWORD_ALLOWED_CHARS = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%&*ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const generateStrongPassword = (passwordLength = 12) =>
  Array.from(Array(passwordLength), () => {
    const randomInt = Math.floor(Math.random() * PASSWORD_ALLOWED_CHARS.length);
    return PASSWORD_ALLOWED_CHARS.substring(randomInt, randomInt + 1);
  }).join('');

export default generateStrongPassword;
