import { Suspense, lazy, useEffect, useMemo } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import AuthGuard from 'src/guards/AuthGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import USER_ROLES from 'src/constants/userRoles';
import { PATH_AFTER_LOGIN } from 'src/config';
import { PATH_DASHBOARD } from 'src/routes/paths';
import usePathHistory from 'src/hooks/usePathHistory';
import useAlertsFilters from 'src/hooks/useAlertsFilters';

// Layouts
import DashboardLayout  from 'src/layouts/dashboard';
import LogoOnlyLayout   from 'src/layouts/LogoOnlyLayout';

// Pages
import ResetPassword    from 'src/pages/auth/ResetPassword';
import StoreDetails     from '../pages/dashboard/store-details';
import MyStores         from "../pages/dashboard/my-stores";
import Profile          from "../pages/dashboard/Profile";
import StoresList       from "../pages/dashboard/StoresList";
import StoreView        from "../pages/dashboard/StoreView";
import ContactsList     from "../pages/dashboard/ContactsList";
import Alerts           from "../pages/dashboard/Alerts";
import Alert            from "../pages/dashboard/Alert";
import Page404          from '../pages/Page404';
import Home             from "../pages/Home";
import { NewCamera, NewMachine } from "../pages/dashboard/new";

// App
const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    // eslint-disable-next-line react-hooks/rules-of-hooks

    return (
        <Suspense fallback={<LoadingScreen isDashboard={!pathname.includes('/auth')} />}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    const { prevPath, currentPath } = usePathHistory();
    const { ACTIONS_HANDLERS } = useAlertsFilters();

    const shouldResetAdvancedFilters = useMemo(
        () => [
            {
                currentPath: PATH_DASHBOARD.home,
                prevPath: PATH_DASHBOARD.alerts.list,
            },
            {
                currentPath: PATH_DASHBOARD.profile,
                prevPath: PATH_DASHBOARD.alerts.list,
            },
        ],
        []
    );

    useEffect(() => {
        const shouldReset = shouldResetAdvancedFilters.some(
            (condition) => condition.currentPath === currentPath && condition.prevPath === prevPath
        );

        if (shouldReset) {
            ACTIONS_HANDLERS.resetAllFilters();
        }
    }, [ACTIONS_HANDLERS, currentPath, prevPath, shouldResetAdvancedFilters]);

    return useRoutes([
        {
            path: '',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                {
                    path: 'home',
                    element: <Home />,
                },
                {
                    path: 'profile',
                    element: <Profile />,
                },
                {
                    path: "new-camera",
                    element: (
                        <RoleBasedGuard withRedirection roles={[USER_ROLES.ADMIN]}>
                            <NewCamera />
                        </RoleBasedGuard>
                    )
                },
                {
                    path: "new-machine",
                    element: (
                        <RoleBasedGuard withRedirection roles={[USER_ROLES.ADMIN]}>
                            <NewMachine />
                        </RoleBasedGuard>
                    )
                },
                {
                    path: 'admin',
                    children: [
                        {
                            path: '',
                            element: (
                                <RoleBasedGuard withRedirection roles={[USER_ROLES.ADMIN]}>
                                    <StoresList />
                                </RoleBasedGuard>
                            ),
                        },
                        {
                            path: ':id',
                            element: <StoreView />,
                        },
                    ],
                },
                {
                    path: 'alerts',
                    children: [
                        {
                            path: '',
                            element: <Alerts />,
                        },
                        {
                            path: ':alertId',
                            element: <Alert />,
                        },
                    ],
                },
                {
                    path: "stores",
                    children: [
                        {
                            path: '',
                            element: <MyStores />,
                        },
                        {
                            path: "details",
                            children: [
                                {
                                    path: '',
                                    element: <Navigate to={"/stores"} replace />,
                                },
                                {
                                    path: ":id",
                                    element: <StoreDetails />,
                                }
                            ]
                        }
                    ],
                },
                {
                    path: 'contacts',
                    element: (
                        <RoleBasedGuard withRedirection roles={[USER_ROLES.ADMIN]}>
                            <ContactsList />
                        </RoleBasedGuard>
                    ),
                }
            ],
        },

        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: '404', element: <Page404 /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'reset-password', element: <ResetPassword /> },
    ]);
}
