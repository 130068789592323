import { gql } from '@apollo/client';

const LIST_ALERT_SEARCH_HISTORIES = gql`
  query listAlertSearchHistories {
    listAlertSearchHistories {
      id
      userId
      search
      createdAt
      updatedAt
    }
  }
`;

export { LIST_ALERT_SEARCH_HISTORIES };
