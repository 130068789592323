import { IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import RoleBasedGuard from '../../../../guards/RoleBasedGuard';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { fDateTimeToUtc } from '../../../../utils/formatTime';
import MenuPopover from '../../../../components/MenuPopover';
import Iconify from '../../../../components/Iconify';
import USER_ROLES from '../../../../constants/userRoles';
import useDialogs from '../../../../hooks/useDialogs';
import { DELETE_ALERT } from '../../../../graphql/alerts/mutations';
import { useSafeMutation } from '../../../../services/apollo-client/wrappers';

const AlertInformation = ({ title, body, occurredAt, id, externalId }) => {
  const { confirm } = useDialogs();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const [deleteAlert] = useSafeMutation(DELETE_ALERT, {
    refetchQueries: ['listAlerts'],
  });

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleDeleteAlert = async () => {
    try {
      if (
        await confirm({
          message: 'Si vous poursuivez, cette alerte sera supprimée. Souhaitez-vous poursuivre ?',
        })
      )
        await deleteAlert({
          variables: { id },
        });

      navigate(PATH_DASHBOARD.alerts.list);
    } catch {
      // Nothing todo
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
      <Stack>
        <Typography>{title}</Typography>
        <Typography variant="caption">{body}</Typography>
        <Typography variant="caption">Alerte remontée le {fDateTimeToUtc(occurredAt)}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <IconButton onClick={handleOpen}>
          <Iconify icon={'eva:info-outline'} width={20} height={20} />
        </IconButton>
        <RoleBasedGuard roles={[USER_ROLES.ADMIN]}>
          <IconButton onClick={handleDeleteAlert}>
            <Iconify icon={'eva:trash-2-outline'} width={20} height={20} sx={{ color: 'error.main' }} />
          </IconButton>
        </RoleBasedGuard>
      </Stack>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 'auto',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption">Référence: {id}</Typography>
            <CopyToClipboard disableText text={id} />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption">Référence interne: {externalId}</Typography>
            <CopyToClipboard disableText text={externalId} />
          </Stack>
        </Stack>
      </MenuPopover>
    </Stack>
  );
};

export default AlertInformation;
