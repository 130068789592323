import { Stack, Typography, IconButton, Box, Chip } from '@mui/material';
import { useSnackbar } from 'notistack';
import USER_ROLES from 'src/constants/userRoles';
import { fAlertOnlyTime, fAlertDate } from 'src/utils/formatTime';
import useSafeMutation from 'src/services/apollo-client/wrappers/useSafeMutation';
import { SET_FAVORITE } from 'src/graphql/alerts/mutations';
import stopPropagation from 'src/utils/stopPropagation';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import _ from 'lodash';

const Thumbnail = ({ alert }) => (
  <Box
    sx={{
      width: 84,
      height: 84,
      borderRadius: 4,
      overflow: 'hidden',
    }}
    component={'img'}
    alt="Camera thumbnail"
    src={alert.imageUrl}
  />
);

const FavoritesAlertsItem = ({ alert, onSelectAlert, withDaySeparator, isLastViewed }) => {
  const { filtersState } = useAlertsFilters();
  const { enqueueSnackbar } = useSnackbar();
  const favoriteIcon = alert.isFavorite
    ? '/assets/profile/favorite-filled.svg'
    : '/assets/profile/favorite-outlined.svg';

  const [setFavorite] = useSafeMutation(SET_FAVORITE, {
    refetchQueries: ['listAlerts'],
    onCompleted: ({ setFavorite }) => {
      const { isFavorite } = setFavorite;
      const snackbarMessage = isFavorite ? 'Alerte ajoutée aux favoris' : 'Alerte retirée des favoris';
      enqueueSnackbar(snackbarMessage);
    },
  });

  const handleSetFavorite = () => {
    setFavorite({
      variables: {
        alertId: alert.id,
      },
    });
  };

  const stackStyles = isLastViewed
    ? {
        backgroundColor: '#071ac814 !important',
        padding: '8px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        position: 'relative',
      }
    : {};

  return (
    <Stack
      spacing={1}
      alignItems="center"
      direction="row"
      key={`favorite-alert-${alert.cameraId}-${alert.occurredAt}`}
      onClick={stopPropagation(onSelectAlert)}
    >
      <Thumbnail alert={alert} />
      <Stack flex={1} sx={stackStyles}>
        <Typography variant="subtitle2">Caméra {alert.cameraId}</Typography>
        <Typography variant="body2" sx={{ color: '#8F8F8F', fontWeight: 'bold' }}>
          {!withDaySeparator && fAlertDate(alert.occurredAt)}
          {withDaySeparator && `Alerte déclenchée à ${fAlertOnlyTime(alert.occurredAt)}`}
        </Typography>
        <Typography variant="body2" sx={{ color: '#8F8F8F' }}>
          {_.capitalize(alert.store.name)}
        </Typography>
        {isLastViewed && (
          <Chip
            size="small"
            label="Dernière vue"
            sx={{
              position: 'absolute',
              top: -10,
              right: 0,
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
              fontWeight: 'bold',
            }}
          />
        )}
      </Stack>
      {filtersState.favorite && (
        <RoleBasedGuard roles={[USER_ROLES.EMPLOYEE, USER_ROLES.STORE]}>
          <IconButton onClick={stopPropagation(handleSetFavorite)}>
            <Box
              component="img"
              src={favoriteIcon}
              sx={{
                height: 24,
                width: 24,
              }}
            />
          </IconButton>
        </RoleBasedGuard>
      )}
    </Stack>
  );
};

export default FavoritesAlertsItem;
