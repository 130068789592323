import React from "react";
import {
    ToggleButton,
    Box,
    Typography
} from "@mui/material";

/**
 * A fun card-like component which displays an icon too
 * 
 * @param {Object} props
 * @param {String} props.label - Label text
 * @param {React.ReactNode} props.icon - Icon JSX element
 * 
 * @example
 * <CardWithIcon label="Hello world" icon={<Iconify icon={...} />
 */
export default function CardToggleButton({ icon, label, sx, ...rest }) {
    return (
        <ToggleButton sx={{ width: { xs: "100%", sm: 180 }, display: "flex", justifyContent: "start", alignItems: "start", ...sx }} {...rest}>
            <Box display="flex" flexDirection="column" justifyContent="space-between" p={1} sx={{ width: "100%" }}>

                {/* Icon Box */}
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: 42, height: 42, borderRadius: 4, bgcolor: "#fff" }}>
                    {icon}
                </Box>

                {/* Label */}
                <Box sx={{ mt: 2, pl: "2px", textAlign: "left" }}>
                    {label}
                </Box>
            </Box>
        </ToggleButton>
    );
}
