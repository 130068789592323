const getAlertConfiguration = ({ onQualificationDrawerOpen, alert }) => {
  switch (alert.falsePositive) {
    case false:
      return {
        label: 'Vol confirmé',
        color: '#FFFFFF',
        backgroundColor: '#F31F39',
      };
    case true:
      return {
        label: 'Fausse alerte',
        color: '#FFFFFF',
        backgroundColor: '#071ac8',
      };
    default:
      return {
        label: onQualificationDrawerOpen ? 'Vérifier' : 'À vérifier',
        color: '#071AC8',
        backgroundColor: 'rgba(7, 26, 200, 0.05)',
      };
  }
};
export default getAlertConfiguration;
