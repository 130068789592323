import {
  Typography,
  Button,
  Table,
  Dialog,
  TableHead,
  TableCell,
  Stack,
  Box,
  TableRow,
  TableContainer,
  TableBody,
  Chip,
} from '@mui/material';
import _, { isBoolean } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate } from 'react-router';
import { fAlertDate, fAlertOnlyTime } from 'src/utils/formatTime';
import useQueryParams from 'src/hooks/useQueryParams';
import { LIST_ALERTS } from 'src/graphql/alerts/queries';
import useSafeQuery from 'src/services/apollo-client/wrappers/useSafeQuery';
import EmptyContent from 'src/components/EmptyContent';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import { useEffect, useState, useRef, useCallback } from 'react';
import AlertDetails from 'src/sections/shared/alerts/AlertDetails';
import AlertQualificationStatus from 'src/sections/shared/alerts/AlertQualificationStatus';
import AlertType from 'src/sections/shared/alerts/AlertType';
import { TAG_TYPES, TAG_TYPES_LABEL } from 'src/constants/tagTypes';
import { CREATE_ALERT_SEARCH_HISTORY } from 'src/graphql/alertSearchHistories/mutations';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import { PATH_DASHBOARD } from 'src/routes/paths';
import AlertLoading from './AlertLoading';

const Thumbnail = ({ alert }) => (
  <Box
    sx={{
      width: 24,
      height: 24,
      borderRadius: 4,
    }}
    component={'img'}
    alt="Camera thumbnail"
    src={alert.imageUrl}
  />
);

const OutlinedTableCell = ({ children, colSpan = 1, sx }) => (
  <TableCell
    colSpan={colSpan}
    sx={{
      border: '1px solid #F6F6F6',
      backgroundColor: 'transparent',
      boxShadow: 'none !important',
      minWidth: 125,
      ...sx,
    }}
  >
    {children}
  </TableCell>
);

const ALERT_TABLE_ROW_CONFIGURATION = [
  {
    label: 'Caméra',
    icon: '/assets/alerts/desktop-list/camera.svg',
  },
  {
    label: 'Magasin',
    icon: '/assets/alerts/desktop-list/store.svg',
  },
  {
    label: 'Vidéo',
    icon: '/assets/alerts/desktop-list/video.svg',
  },
  {
    label: 'Heure',
    icon: '/assets/alerts/desktop-list/hour.svg',
  },
  {
    label: 'Statut',
    icon: '/assets/alerts/desktop-list/status-state.svg',
  },
  {
    label: 'État',
    icon: '/assets/alerts/desktop-list/status-state.svg',
  },
  {
    label: 'Valeur estimée',
    icon: '/assets/alerts/desktop-list/value.svg',
  },
];

const AlertTableHeadDesktopComponent = () => (
  <TableHead>
    <TableRow>
      {ALERT_TABLE_ROW_CONFIGURATION.map((column) => (
        <OutlinedTableCell key={column.label} align="center" colSpan={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box component="img" src={column.icon} alt={column.label} />
            <Typography variant="body2" sx={{ color: '#8F8F8F' }}>
              {column.label}
            </Typography>
          </Stack>
        </OutlinedTableCell>
      ))}
    </TableRow>
  </TableHead>
);

const AlertTableRowDesktopComponent = ({ alert, onSelectAlert, alertRefs, isLastViewed }) => {
  const alertTagWithEstimatedValueTags = alert.tags.filter((tag) => tag.tagType.name === TAG_TYPES.ESTIMATED_VALUE);
  const isAlertQualified = isBoolean(alert.falsePositive);
  const nonQualifiedAlert = !isAlertQualified;

  const stackStyles = isLastViewed
    ? {
        backgroundColor: '#071ac814 !important',
        padding: '8px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        position: 'relative',
      }
    : {};

  return (
    <TableRow
      key={alert.key}
      onClick={onSelectAlert}
      sx={{
        cursor: 'pointer',
        ...stackStyles,
      }}
      hover
      ref={(el) => {
        alertRefs.current[alert.id] = el;
      }}
    >
      <OutlinedTableCell>
        <Stack
          spacing={1}
          alignItems="center"
          direction="row"
          key={`favorite-alert-${alert.cameraId}-${alert.occurredAt}`}
          sx={{ position: 'relative' }}
        >
          <Thumbnail alert={alert} />
          <Typography
            variant="body2"
            sx={{
              textDecoration: 'underline',
              overflow: 'hidden',
            }}
          >
            {`Caméra ${alert.cameraId}`}
          </Typography>
          {nonQualifiedAlert && (
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: '#071ac8',
                borderRadius: '50px',
                width: 8,
                height: 8,
                right: 0,
              }}
            />
          )}
        </Stack>
      </OutlinedTableCell>
      <OutlinedTableCell>
        <Typography variant="body2">{_.capitalize(alert.store.name)}</Typography>
      </OutlinedTableCell>
      <OutlinedTableCell>
        <Chip
          icon={<Box component="img" src={'/assets/alerts/desktop-list/play.svg'} alt="Voir" />}
          sx={{
            p: 1,
            maxWidth: 'fit-content',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50px',
            borderColor: '#071ac8',
            color: '#071ac8 !important',
            cursor: 'pointer',

            '& .MuiChip-outlined': {
              border: '2px solid',
            },
          }}
          variant="outlined"
          label="Voir"
        />
      </OutlinedTableCell>
      <OutlinedTableCell>
        <Typography variant="body2">{fAlertOnlyTime(alert.occurredAt)}</Typography>
      </OutlinedTableCell>
      <OutlinedTableCell>
        <AlertQualificationStatus alert={alert} />
      </OutlinedTableCell>
      <OutlinedTableCell>
        <AlertType alert={alert} />
      </OutlinedTableCell>
      <OutlinedTableCell>
        <Stack spacing={1} direction="row" alignContent="center" flexWrap="wrap" rowGap={1}>
          {alertTagWithEstimatedValueTags.map((tag) => {
            const tagValue = `${tag.label} €`;
            const tagTypeNormalized = TAG_TYPES_LABEL[tag.tagType.name];
            const tagLabel = `${tagTypeNormalized} | ${tagValue}`;

            return (
              <Chip
                key={tag.id}
                sx={{
                  p: 1,
                  maxWidth: 'fit-content',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50px',
                  borderColor: '#E8E8E8',
                  color: '#000000',
                  backgroundColor: '#F3F3F3',
                  '& .MuiChip-outlined': {
                    border: '2px solid',
                  },
                }}
                variant="outlined"
                label={tagLabel}
              />
            );
          })}
        </Stack>
      </OutlinedTableCell>
    </TableRow>
  );
};

const AlertsListDesktop = ({ pagination, storeId, emptyContent, favorite, resetSearch }) => {
  const { alertId, removeQueryParameters } = useQueryParams();
  const { filtersState, ACTIONS_HANDLERS } = useAlertsFilters();
  const [lastAlertIdSeen, setLastAlertIdSeen] = useState();
  const location = useLocation();
  const [selectedAlertId, setSelectedAlertId] = useState(null);
  const isAlertDetailDialogOpen = !!selectedAlertId;
  const shouldFilterFavorite = favorite || filtersState.favorite;
  const navigate = useNavigate();
  const INITIAL_PAGE = 1;
  const [page, setPage] = useState(INITIAL_PAGE);
  const alertRefs = useRef({});

  const [createAlertSearchHistory] = useSafeMutation(CREATE_ALERT_SEARCH_HISTORY, {
    variables: { input: { search: filtersState.search } },
    refetchQueries: ['listAlertSearchHistories'],
  });

  const handleSelectAlert = (alertId) => {
    navigate(PATH_DASHBOARD.alerts.view(alertId), {
      state: { storeId },
    });
    if (filtersState.search.length > 0) createAlertSearchHistory();
  };

  const handleResetSearch = () => {
    setSelectedAlertId(null);
  };

  useEffect(() => {
    const { forceAlertId } = location.state || {};
    if (forceAlertId) {
      setSelectedAlertId(forceAlertId);
    }
  }, [location.state]);

  const {
    data: alertsList,
    fetchMore,
    loading,
  } = useSafeQuery(
    LIST_ALERTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        pagination: {
          ...pagination,
          page: INITIAL_PAGE,
        },
        storeId,
        filters: {
          ...(isBoolean(filtersState.falsePositive) && { falsePositive: filtersState.falsePositive }),
          ...(isBoolean(filtersState.qualified) && { qualified: filtersState.qualified }),
          ...(filtersState.search.length > 0 && { search: filtersState.search }),
          ...(shouldFilterFavorite && { favorite: shouldFilterFavorite }),
          dates: {
            ...(filtersState.advancedFilters.dates.start && { start: filtersState.advancedFilters.dates.start }),
            ...(filtersState.advancedFilters.dates.end && { end: filtersState.advancedFilters.dates.end }),
          },
          estimatedValues: {
            ...(filtersState.advancedFilters.estimatedValues.minimum && {
              minimum: filtersState.advancedFilters.estimatedValues.minimum,
            }),
            ...(filtersState.advancedFilters.estimatedValues.maximum && {
              maximum: filtersState.advancedFilters.estimatedValues.maximum,
            }),
          },
          cameraIds: filtersState.advancedFilters.cameraIds,
          tags: {
            ...(filtersState.advancedFilters.tags.locationId && {
              locationId: filtersState.advancedFilters.tags.locationId,
            }),
            ...(filtersState.advancedFilters.tags.thiefProfileId && {
              thiefProfileId: filtersState.advancedFilters.tags.thiefProfileId,
            }),
            ...(filtersState.advancedFilters.tags.theftTypeId && {
              theftTypeId: filtersState.advancedFilters.tags.theftTypeId,
            }),
          },
        },
      },
    },
    {
      silentError: true,
    }
  );

  const fetchMoreAlerts = useCallback(() => {
    const nextPage = page + 1;
    fetchMore({
      variables: {
        pagination: {
          ...pagination,
          page: nextPage,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        const newAlerts = fetchMoreResult.listAlerts.alerts;
        const mergedAlerts = [...prev.listAlerts.alerts, ...newAlerts];

        return {
          listAlerts: {
            ...prev.listAlerts,
            alerts: mergedAlerts,
            hasMore: fetchMoreResult.listAlerts.hasMore,
          },
        };
      },
    }).then(() => {
      setPage(nextPage);
    });
  }, [fetchMore, page, pagination]);

  const scrollToAlert = (alertId) => {
    const offset = 40;
    if (alertRefs.current[alertId]) {
      const topPos = alertRefs.current[alertId].getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: topPos, behavior: 'smooth' });
      removeQueryParameters('alertId');
    }
  };

  useEffect(() => {
    if (alertId) {
      const checkAndScrollToAlert = () => {
        const alertFound = alertsList?.listAlerts.alerts.some((alert) => alert.id === alertId);
        if (alertFound) {
          setLastAlertIdSeen(alertId);
          scrollToAlert(alertId);
        } else if (alertsList?.listAlerts.hasMore) {
          fetchMoreAlerts();
        }
      };

      checkAndScrollToAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertId, alertsList, fetchMoreAlerts]);

  if (loading) {
    return <AlertLoading />;
  }

  const { hasMore, alerts } = alertsList?.listAlerts;
  const hasAlerts = alerts.length > 0;

  if (filtersState.isSearchLayoutActive && !filtersState.hasTyped) {
    return null;
  }

  if (!hasAlerts) {
    const { isSearchLayoutActive } = filtersState;
    const buttonLabel = isSearchLayoutActive ? 'Réinitialiser la recherche' : 'Réinitialiser les filtres';
    const displayResetAction = emptyContent.withResetAction;

    return (
      <EmptyContent title={emptyContent.title} description={emptyContent.description}>
        {displayResetAction && (
          <Button
            variant="text"
            sx={{
              color: 'primary.main',
              fontWeight: 'normal',
              fontSize: '0.75rem',
              textTransform: 'none',
              minWidth: 'fit-content',
              px: 0,
            }}
            onClick={() => {
              if (isSearchLayoutActive) resetSearch();
              else {
                ACTIONS_HANDLERS.resetAllFilters();
              }
            }}
          >
            {buttonLabel}
          </Button>
        )}
      </EmptyContent>
    );
  }

  const groupedAlerts = alerts.reduce((accumulator, alert) => {
    const alertDate = fAlertDate(alert.occurredAt, false);
    if (!accumulator[alertDate]) {
      accumulator[alertDate] = [];
    }
    accumulator[alertDate].push(alert);
    return accumulator;
  }, {});

  return (
    <>
      <InfiniteScroll
        dataLength={alerts.length}
        next={fetchMoreAlerts}
        hasMore={hasMore}
        loader={<AlertLoading />}
        scrollThreshold={0.8}
      >
        <TableContainer>
          <Table size="small">
            <AlertTableHeadDesktopComponent />
              {Object.entries(groupedAlerts).map(([alertDate, alerts], alertGroupIndex) => (
                <TableBody key={`alert-group-${alertGroupIndex}`}>
                  <TableRow key={`alert-date-${alertDate}`}>
                    <OutlinedTableCell
                      colSpan={ALERT_TABLE_ROW_CONFIGURATION.length}
                      sx={{ backgroundColor: '#F6F6F6 !important', p: 0 }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          p: 1,
                          color: '#70706E',
                        }}
                      >
                        {alertDate}
                      </Typography>
                    </OutlinedTableCell>
                  </TableRow>
                  {alerts.map((alert) => (
                    <AlertTableRowDesktopComponent
                      key={alert.id}
                      alert={alert}
                      onSelectAlert={() => handleSelectAlert(alert.id)}
                      alertRefs={alertRefs}
                      isLastViewed={lastAlertIdSeen === alert.id}
                    />
                  ))}
                </TableBody>
              ))}
          </Table>
        </TableContainer>
      </InfiniteScroll>
      {isAlertDetailDialogOpen && (
        <Dialog
          open
          onClose={handleResetSearch}
          maxWidth="md"
          sx={{
            '.MuiDialog-paper': {
              zIndex: 1100,
              padding: 0,
              margin: 0,
              px: 2,
              backgroundColor: 'white',
            },
          }}
        >
          <AlertDetails alertId={selectedAlertId} onClose={handleResetSearch} />
        </Dialog>
      )}
    </>
  );
};

export default AlertsListDesktop;
