import { Stack, Typography, Card } from '@mui/material';
import AlertQualificationStatus from 'src/sections/shared/alerts/AlertQualificationStatus';
import AlertType from 'src/sections/shared/alerts/AlertType';

const AlertStatus = ({ alert, onQualificationDrawerOpen, onSelectAlertType }) => (
  <Card
    sx={{
      border: 'none',
      borderRadius: 0,
      borderBottom: '1px solid #E5E5E5',
    }}
  >
    <Typography variant="h6">Statut</Typography>
    <Typography variant="caption">Retrouvez ici le statut du vol</Typography>
    <Stack spacing={2} sx={{ flex: 1, justifyContent: 'space-between' }}>
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          mt: 3,
        }}
      >
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          <AlertQualificationStatus alert={alert} onQualificationDrawerOpen={onQualificationDrawerOpen} />
          <AlertType
            alert={alert}
            onQualificationDrawerOpen={onQualificationDrawerOpen}
            onSelectAlertType={onSelectAlertType}
          />
        </Stack>
      </Stack>
    </Stack>
  </Card>
);

export default AlertStatus;
