import { gql } from '@apollo/client';

const CREATE_ALERT_SEARCH_HISTORY = gql`
  mutation createAlertSearchHistory($input: CreateAlertSearchHistoryInput!) {
    createAlertSearchHistory(input: $input) {
      id
      userId
      search
      createdAt
      updatedAt
    }
  }
`;

const DELETE_ALERT_SEARCH_HISTORY = gql`
  mutation deleteAlertSearchHistory($id: ID!) {
    deleteAlertSearchHistory(id: $id)
  }
`;

export { CREATE_ALERT_SEARCH_HISTORY, DELETE_ALERT_SEARCH_HISTORY };
