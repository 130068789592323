import { gql } from '@apollo/client';

const CREATE_USER_ALERT_ACTION = gql`
  mutation createUserAlertAction($input: CreateUserAlertActionInput!) {
    createUserAlertAction(input: $input) {
      id
      actionType
      metadata
      createdAt
      updatedAt
    }
  }
`;

export { CREATE_USER_ALERT_ACTION };
