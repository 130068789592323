import { Stack, Typography, TextField, InputAdornment } from '@mui/material';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import Iconify from 'src/components/Iconify';

const EstimatedValuesFilters = () => {
  const { ACTIONS_HANDLERS, filtersState } = useAlertsFilters();

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Valeur estimée</Typography>
      <Stack
        spacing={1}
        direction="row"
        sx={{
          '& > *': {
            flex: 1,
          },
        }}
      >
        <TextField
          label="Minimum"
          type="tel"
          value={filtersState.advancedFilters.estimatedValues.minimum}
          onChange={(event) => {
            ACTIONS_HANDLERS.setEstimatedValueMinimum(Number(event.target.value));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Iconify icon="ic:baseline-euro" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Maximum"
          type="tel"
          value={filtersState.advancedFilters.estimatedValues.maximum}
          onChange={(event) => {
            ACTIONS_HANDLERS.setEstimatedValueMaximum(Number(event.target.value));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Iconify icon="ic:baseline-euro" />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};

export default EstimatedValuesFilters;
