import { Chip } from '@mui/material';
import Iconify from 'src/components/Iconify';
import {
  ALERT_GLOBAL_TYPES,
  CONFIRMED_ALERT_TYPES_LABELS,
  NON_CONFIRMED_ALERT_TYPES_LABELS,
} from 'src/constants/alertTypes';

const AlertType = ({ alert, onQualificationDrawerOpen, onSelectAlertType }) => {
  const hasAlertType = alert.type !== null;
  const ALERT_TYPE_DEPENDING_ON_FALSE_POSITIVE = alert.falsePositive
    ? NON_CONFIRMED_ALERT_TYPES_LABELS
    : CONFIRMED_ALERT_TYPES_LABELS;

  if (!hasAlertType) return null;

  return (
    <Chip
      deleteIcon={
        <Iconify
          icon="eva:arrow-ios-downward-fill"
          sx={{
            color: '#000000',
            width: 16,
            height: 16,
          }}
        />
      }
      onClick={
        onQualificationDrawerOpen
          ? () => {
              onSelectAlertType(alert.falsePositive ? ALERT_GLOBAL_TYPES.FALSE_POSITIVE : ALERT_GLOBAL_TYPES.CONFIRMED);
              onQualificationDrawerOpen();
            }
          : null
      }
      onDelete={
        onQualificationDrawerOpen
          ? () => {
              onSelectAlertType(alert.falsePositive ? ALERT_GLOBAL_TYPES.FALSE_POSITIVE : ALERT_GLOBAL_TYPES.CONFIRMED);
              onQualificationDrawerOpen();
            }
          : null
      }
      sx={{
        p: 1,
        maxWidth: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px',
        borderColor: '#E8E8E8',
        color: '#000000',
        backgroundColor: '#F3F3F3',
        cursor: onQualificationDrawerOpen ? 'pointer' : 'default',
        '&:hover, &:focus, &:active': {
          backgroundColor: 'rgba(248, 132, 47, 0.04)',
        },
        '& .MuiChip-outlined': {
          border: '2px solid',
        },
      }}
      variant="outlined"
      label={ALERT_TYPE_DEPENDING_ON_FALSE_POSITIVE[alert.type]}
    />
  );
};

export default AlertType;
