import {
    Box,
    Typography,
    ToggleButtonGroup,
    ToggleButton,
    FormControl,
    TextField,
    Stack,
    InputLabel,
    Button,
    Select,
    MenuItem
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Iconify from "src/components/Iconify";

import TabContainer from "../../../../../components/ftp/TabContainer";
import { formConfig } from "../formConfig";

// Utility function
export function randomInArray(array) {
    return array[Math.floor(Math.random() * array.length)];
}

// App
export default function Details({ form }) {
    const theme = useTheme();

    return (
        <TabContainer header="Personalization">

            <Stack direction="column" spacing={{ xs: 2, sm: 3 }}>
                {/* Row 1 - Name and Brand */}
                <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 3 }}>
                    <FormControl fullWidth>
                        <TextField
                            label={formConfig.name.label}
                            type="text"
                            placeholder={randomInArray(formConfig.name.placeholders)}
                            value={form.data?.[formConfig.name.id]}
                            onChange={(e) => form.setData((prev) => ({ ...prev, [formConfig.name.id]: e.target.value }))}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{formConfig.brand.label}</InputLabel>
                        <Select
                            value={form.data?.[formConfig.brand.id]}
                            label={formConfig.brand.label}
                            onChange={(e) => form.setData((prev) => ({ ...prev, [formConfig.brand.id]: e.target.value }))}
                        >
                            {formConfig.brand.list.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Stack>

                {/* Row 2 - Location */}
                <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 3 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{formConfig.location.label}</InputLabel>
                        <Select
                            value={form.data?.[formConfig.location.id]}
                            label={formConfig.location.label}
                            onChange={(e) => form.setData((prev) => ({ ...prev, [formConfig.location.id]: e.target.value }))}
                        >
                            {formConfig.location.list.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                        </Select>
                    </FormControl>


                </Stack>
            </Stack>

        </TabContainer>
    );
}