// Config
import configs from "src/config/index";

// Icons
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

import StoreIcon from '@mui/icons-material/Store';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

// Path & Roles imports
import { PATH_DASHBOARD } from '../../../routes/paths';
import USER_ROLES from '../../../constants/userRoles';

// Navigation Bar Configuration
const useNavConfig = () => {
  const navConfig = [
    {
      items: [
        {
          title: "Admin",
          value: "admin",
          path: PATH_DASHBOARD.admin,
          Icon: AdminPanelSettingsIcon,
          IconOutlined: AdminPanelSettingsOutlinedIcon,
          roles: [USER_ROLES.ADMIN],
        },
        {
          title: 'Accueil',
          value: 'home',
          Icon: HomeIcon,
          IconOutlined: HomeOutlinedIcon,
          path: PATH_DASHBOARD.home,
          roles: [USER_ROLES.EMPLOYEE, USER_ROLES.STORE],
        },
        configs.features.onboarding && {
          title: 'Mes Magasins',
          value: 'myStores',
          path: PATH_DASHBOARD.stores.list,
          Icon: StoreIcon,
          IconOutlined: StoreOutlinedIcon,
          roles: [USER_ROLES.ADMIN, USER_ROLES.STORE],
        },
        {
          title: 'Alertes',
          value: 'alerts',
          Icon: NotificationsIcon,
          IconOutlined: NotificationsNoneOutlinedIcon,
          path: PATH_DASHBOARD.alerts.list,
          roles: [USER_ROLES.EMPLOYEE, USER_ROLES.STORE, USER_ROLES.ADMIN],
        },
        {
          title: 'Contacts',
          value: 'contacts',
          path: PATH_DASHBOARD.contacts,
          Icon: PeopleAltIcon,
          IconOutlined: PeopleAltOutlinedIcon,
          roles: [USER_ROLES.ADMIN],
        },
        {
          title: 'Profil',
          value: 'profile',
          Icon: AccountCircleIcon,
          IconOutlined: AccountCircleOutlinedIcon,
          path: PATH_DASHBOARD.profile,
          roles: [USER_ROLES.EMPLOYEE, USER_ROLES.STORE],
        }
      ].filter(Boolean),
    },
  ];

  return navConfig;
};

export default useNavConfig;
