import { useNavigate, useParams } from "react-router";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
    Box,
    Container,
    Tabs,
    Tab,
    TextField,
    InputAdornment,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Card,
    Grid2,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Skeleton,
    Stack,
    Link,
    useTheme,
    Tooltip,
    IconButton,
    ToggleButtonGroup,
    Chip,
    AppBar,
} from "@mui/material";

import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';

import ApiService from "src/services/api";

// Table
import Scrollbar from "src/components/Scrollbar";
import { TableHeadCustom } from "src/components/table";
import { StoreTableRow } from "src/sections/@dashboard/store/list";

// Icons
import { ArrowBack } from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import BackArrowIcon from "src/components/BackArrow";

// Custom
import CardToggleButton from "src/components/ftp/CardToggleButton";

import Page from "src/components/Page";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import EditIcon from '@mui/icons-material/Edit';
import Iconify from "src/components/Iconify";
import Label from '../../../components/Label';

//
import Overview from "./overview";
import Settings from "./settings";

export default function StoreDetails() {
    // Get the store ID from the URL parameters
    const { id } = useParams();
    const [storeDetails, setStoreDetails] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);

    // Fetch API data
    useEffect(() => {
        const getStoreDetails = async () => {
            try {
                const storeData = await ApiService.fetchStore(id);
                console.log(storeData);
                setStoreDetails(storeData);
            } catch {
                console.error("Failed to fetch store data!");
            }
        }
        getStoreDetails();
    }, []);

    // Handle when user clicks on a "change view" tab
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    }


    return (
        <>
            {/* App bar */}
            <ProminentAppBar />

            <Page title={`${storeDetails.name || "Loading"}`}>
                <Container maxWidth={false}>


                    <Typography variant="body2" sx={{ color: 'text.disabled' }}>Vue d'ensemble de</Typography>
                    <HeaderBreadcrumbs heading={storeDetails.name} />

                    {/* Page Overview */}
                    <Overview />

                </Container>
            </Page>
        </>
    );
}

export function ProminentAppBar() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        News
                    </Typography>
                    <Button color="inherit">Login</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}