import { useEffect, useState } from 'react';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Divider,
  TableBody,
  Container,
  TableContainer,
  Stack,
  Button,
  Typography,
} from '@mui/material';
import useTabs from '../../hooks/useTabs';
import useTable from '../../hooks/useTable';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableHeadCustom, TableNoData } from '../../components/table';
import { useSafeLazyQuery, useSafeMutation } from '../../services/apollo-client/wrappers';
import { GET_USER, LIST_USERS } from '../../graphql/users/queries';
import { ContactTableRow, ContactTableToolbar } from '../../sections/@dashboard/contact/list';
import ContactForm from '../../sections/@dashboard/contact/form/ContactForm';
import { DELETE_USER, SAVE_USER } from '../../graphql/users/mutations';
import useDialogs from '../../hooks/useDialogs';

const STATUS_OPTIONS = [
  {
    value: 'active',
    label: 'Actifs',
  },
  {
    value: 'inactive',
    label: 'Inactifs',
  },
];

const TABLE_HEAD = [
  { id: 'displayName', label: 'Nom et Prénom', align: 'left' },
  { id: 'email', label: 'Adresse Email', align: 'left' },
  { id: 'createdAt', label: 'Enregistré le', align: 'left' },
  { id: '' },
];

export default function ContactsList() {
  const { page, setPage } = useTable();
  const { confirm } = useDialogs();

  const [selectedContact, setSelectedContact] = useState(null);
  const [search, setSearch] = useState('');
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('active');

  const [contacts, setContacts] = useState([]);

  const [listUsers, { data, loading }] = useSafeLazyQuery(LIST_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ listUsers }) => {
      setContacts((users) => [...users, ...listUsers.users]);
      setPage((p) => p + 1);
    },
  });

  const [saveUser, { loading: saving }] = useSafeMutation(SAVE_USER, {
    refetchQueries: ['listUsers'],
  });

  const [deleteUser] = useSafeMutation(DELETE_USER, {
    refetchQueries: ['listUsers'],
  });
  //
  const [getUser] = useSafeLazyQuery(GET_USER, {
    onCompleted: ({ getUser }) => setSelectedContact(getUser),
    fetchPolicy: 'network-only',
  });

  const hasMore = data?.listUsers?.hasMore;

  useEffect(() => {
    listUsers({
      onCompleted: ({ listUsers }) => {
        setContacts(listUsers.users);
        setPage((p) => p + 1);
      },
      variables: {
        pagination: {
          page: 1,
        },
        filters: {
          inactive: filterStatus === 'inactive',
          search,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus, search, listUsers]);

  const handleLoadMore = async () => {
    try {
      await listUsers({
        variables: {
          pagination: {
            page,
          },
          filters: {
            inactive: filterStatus === 'inactive',
            search,
          },
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const handleSaveUser = async (data) => {
    try {
      await saveUser({
        variables: {
          input: {
            ...data,
          },
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      if (
        await confirm({
          message: 'Cette action est irréversible. Souhaitez-vous continuer ?',
          title: "Suppression d'un contact",
        })
      )
        await deleteUser({
          variables: {
            id,
          },
        });
    } catch {
      // Nothing to do
    }
  };

  const isNotFound = !loading && ((!contacts.length && !!search) || (!contacts.length && !!filterStatus));

  const onAddContact = () => setSelectedContact({});
  const onEditContact = (user) =>
    getUser({
      variables: { id: user.id },
    });
  const onCloseDialog = () => setSelectedContact(null);

  return (
    <Page title="Mes contacts">
      {selectedContact && (
        <ContactForm saving={saving} onSaveUser={handleSaveUser} user={selectedContact} onClose={onCloseDialog} />
      )}
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Mes contacts" links={[{ name: 'Mes contacts' }]} />

        <Card sx={{ p: 4 }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{
              mb: 2,
            }}
          >
            {STATUS_OPTIONS.map(({ label, value }) => (
              <Tab disableRipple key={value} label={label} value={value} />
            ))}
          </Tabs>

          <Divider />

          <ContactTableToolbar onFilterName={setSearch} onAddContact={onAddContact} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size="small">
                <TableHeadCustom headLabel={TABLE_HEAD} rowCount={contacts.length} />
                <TableBody>
                  {contacts.map((row) => (
                    <ContactTableRow
                      key={row.id}
                      contact={row}
                      onSaveUser={handleSaveUser}
                      onEditContact={() => {
                        onEditContact(row);
                      }}
                      onDeleteUser={() => handleDeleteUser(row.id)}
                    />
                  ))}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            py={2}
            sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}` }}
          >
            {hasMore && (
              <Button variant="contained" size="small" onClick={handleLoadMore}>
                Voir plus de résultats ({data?.listUsers?.total - contacts.length})
              </Button>
            )}
            {!hasMore && <Typography variant="caption">Tous les résultats sont affichés</Typography>}
          </Stack>
        </Card>
      </Container>
    </Page>
  );
}
